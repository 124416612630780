import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../contexts/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import "./SubjectList.css";
import Modal from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios";
import { useTranslation, withTranslation } from "react-i18next";
import cs from "../../const";

const getDomainListUrl = cs.BaseURL + "/api/module/domain-list";
const getModuleListUrl = cs.BaseURL + "/api/module/list";
const moduleCreateUrl = cs.BaseURL + "/api/module/create";
const moduleUpdateUrl = cs.BaseURL + "/api/module/update";
const moduleDeleteUrl = cs.BaseURL + "/api/module/delete?id=";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70vw",
    maxHeight: "95vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #888",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SubjectList = ({ t, i18n }) => {
  //init ModalForm
  const [modalForm, setModalForm] = useState({
    subjectName: "",
    description: "",
    domainID: "",
  });
  // let { subjectName, description, domainID } = modalForm;

  const onChangeModal = (event) => {
    setModalForm({ ...modalForm, [event.target.name]: event.target.value });
  };

  //control modal
  const [open, setOpen] = useState(false);
  const [reqType, setReqType] = useState("");
  const handleOpenModal = (reqType) => {
    setReqType(reqType);
    setOpen(true);
  };
  const handleCloseModal = () => {
    setModalForm({ subjectName: "", description: "", domainID: "" });
    setOpen(false);
  };
  const handleConfirmData = () => {
    console.log(modalForm);
  };

  const classes = useStyles();
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDelModal = () => {
    setOpenDel(true);
  };
  const handleCloseDelModal = () => {
    setOpenDel(false);
  };

  const [domainList, setDomainList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  // resp message control
  const [responseMessages, setResponseMessages] = useState({
    type: "",
    content: "",
  });
  const [openMessage, setOpenMessage] = useState(false);

  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const getDomainData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getDomainListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("data", response.data);
      setDomainList(response.data.data);
      // console.log(response.data.data)
    } catch (error) {
      console.log(error);
    }
  };

  const getSubjectList = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getModuleListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      //console.log(response.data);
      setSubjectList(response.data.data);
      //console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  //handle data
  const handleAddClick = () => {
    handleOpenModal("add");
  };

  const handleConfirmAddClick = async () => {
    if (
      !modalForm.subjectName ||
      !modalForm.description ||
      !modalForm.domainID
    ) {
      setResponseMessages({
        type: "warning",
        content: t("module.response-message.empty-fields"),
      });
      setOpenMessage(true);
      return;
    }
    try {
      const response = await axios({
        method: "post",
        url: `${moduleCreateUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: {
          module_name: modalForm.subjectName,
          module_description: modalForm.description,
          domain_id: modalForm.domainID,
        },
      });
      //console.log(response.data);
      if (response.data.error_desc === "Success") {
        setResponseMessages({
          type: "success",
          content: t("module.response-message.add-success"),
        });
        setOpenMessage(true);
        getSubjectList();
        handleCloseModal();
      } else {
        // setResponseMessages({ type: "error", content: "Vui lòng chọn chuyên ngành!" });
        // setOpenMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [modifyId, setModifyId] = useState("");
  const handleModClick = (moduleName, moduleDescription, domainId, itemId) => {
    setModalForm({
      subjectName: moduleName,
      description: moduleDescription,
      domainID: domainId,
    });
    setModifyId(itemId);
    // console.log(moduleName);
    handleOpenModal("modify");
    // console.log(modalForm)
  };

  const handleConfirmModClick = async () => {
    if (
      !modalForm.subjectName ||
      !modalForm.description ||
      !modalForm.domainID
    ) {
      setResponseMessages({
        type: "warning",
        content: t("module.response-message.empty-fields"),
      });
      setOpenMessage(true);
      return;
    }
    try {
      const response = await axios({
        method: "post",
        url: `${moduleUpdateUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: {
          id: modifyId,
          module_name: modalForm.subjectName,
          module_description: modalForm.description,
          domain_id: modalForm.domainID,
        },
      });
      if (response.data.error_desc === "Success") {
        setResponseMessages({
          type: "success",
          content: t("module.response-message.modify-success"),
        });
        setOpenMessage(true);
        getSubjectList();
        handleCloseModal();
      } else {
        // setResponseMessages({ type: "error", content: "Vui lòng chọn chuyên ngành!" });
        // setOpenMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [deleteId, setDeleteId] = useState("");
  const handleDelClick = (itemId) => {
    setDeleteId(itemId);
    handleOpenDelModal();
  };

  const handleConfirmDelClick = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${moduleDeleteUrl}${deleteId}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response.data.error_desc === "Success") {
        setResponseMessages({
          type: "success",
          content: t("module.response-message.delete-success"),
        });
        setOpenMessage(true);
        getSubjectList();
        handleCloseDelModal();
      } else {
        setResponseMessages({
          type: "error",
          content: response.data.error_desc,
        });
        setOpenMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //control domainlist
  const getDomainEngName = (domainID) => {
    let vieName = "";
    domainList.forEach((item) => {
      if (item.domainId === domainID) {
        vieName = item.domainEngName;
        return false;
      }
    });
    return vieName;
  };
  const getId = (domainID) => {
    let id = "";
    domainList.forEach((item) => {
      if (item.domainId === domainID) {
        id = item.id;
        return false;
      }
    });
    return id;
  };

  useEffect(() => {
    getDomainData();
    getSubjectList();
  }, []);

  return (
    <ThemeContext.Consumer>
      {({ isDark }) => {
        return (
          <div className={isDark ? "card dark-background-1" : "card"}>
            <div className={"card-body shadow"}>
              <div className="d-flex align-items-baseline">
                <h4 className="page-title mb-4">
                  {t("module.module-list")}
                </h4>
                <button className="btn btn-sm ms-auto" onClick={handleAddClick}>
                  {isDark ? (
                    <img src="https://img.icons8.com/ios-glyphs/30/ffffff/add--v1.png" />
                  ) : (
                    <img src="https://img.icons8.com/ios-glyphs/30/000000/add--v1.png" />
                  )}
                </button>
              </div>

              {subjectList.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={
                      isDark
                        ? "card card-body mb-2 shadow-sm dark-background-2"
                        : "card card-body mb-2 shadow-sm"
                    }
                  >
                    <h5 className="card-title">
                      <Link className="text-reset text-decoration-none">
                        {item.moduleName}
                      </Link>
                    </h5>
                    <p className="text-align-justify">
                      {item.moduleDescription}
                    </p>
                    <div>
                      {t("domain.domain")}:{" "}
                      {t(`domain.name.${getDomainEngName(item.domainId)}`)}
                    </div>
                    <div className="d-flex justify-content-end">
                      {/* <Link
                        to={"/module-scores/" + item.moduleId}
                        className="btn btn-sm stretched-link"
                        style={{ position: "relative" }}
                      >
                        {isDark ? (
                          <img src="https://img.icons8.com/ios-glyphs/30/ffffff/test-failed.png" />
                        ) : (
                          <img src="https://img.icons8.com/ios-glyphs/30/000000/test-failed.png" />
                        )}
                      </Link> */}
                      <button
                        className="btn btn-sm stretched-link"
                        style={{ position: "relative" }}
                        onClick={() => {
                          handleModClick(
                            item.moduleName,
                            item.moduleDescription,
                            getId(item.domainId),
                            item.id
                          );
                        }}
                      >
                        {isDark ? (
                          <img src="https://img.icons8.com/fluency-systems-regular/30/ffffff/edit--v2.png" />
                        ) : (
                          <img src="https://img.icons8.com/fluency-systems-regular/30/000000/edit--v2.png" />
                        )}
                      </button>
                      <button
                        className="btn btn-sm stretched-link"
                        style={{ position: "relative" }}
                        onClick={() => {
                          handleDelClick(item.id);
                        }}
                      >
                        {isDark ? (
                          <img src="https://img.icons8.com/material-outlined/30/ffffff/delete-trash.png" />
                        ) : (
                          <img src="https://img.icons8.com/material-outlined/30/000000/delete-trash.png" />
                        )}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <Modal
              style={{ top: 0 }}
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <ModalForm
                reqType={reqType}
                modalForm={modalForm}
                domainList={domainList}
                handleCloseModal={handleCloseModal}
                onChangeModal={onChangeModal}
                handleConfirmData={handleConfirmData}
                handleConfirmAddClick={handleConfirmAddClick}
                handleConfirmModClick={handleConfirmModClick}
                t={t}
                i18n={i18n}
              />
            </Modal>

            <Modal
              style={{ top: 0 }}
              open={openDel}
              onClose={handleCloseDelModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.paper}>
                <div className="container-fluid p-0">
                  <p className="mt-3 mb-4">
                    {t(
                      "module.response-message.Are you sure you want to delete"
                    )}
                  </p>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary me-1"
                      style={{ width: "60px" }}
                      onClick={() => handleConfirmDelClick()}
                    >
                      {t("delete")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      style={{ width: "60px" }}
                      onClick={handleCloseDelModal}
                    >
                      {t("cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>

            <Snackbar
              open={openMessage}
              autoHideDuration={1500}
              onClose={handleCloseMessage}
            >
              <Alert
                onClose={handleCloseMessage}
                severity={responseMessages.type}
              >
                {responseMessages.content}
              </Alert>
            </Snackbar>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const ModalForm = ({
  reqType,
  modalForm,
  domainList,
  handleCloseModal,
  onChangeModal,
  handleConfirmAddClick,
  handleConfirmModClick,
  t,
  i18n,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <div className="container-fluid p-0">
        <h4 className="text-uppercase">
          {reqType === "add" && t("module.add-module")}
          {reqType === "modify" && t("module.modify-module")}
        </h4>
        <div className="row mb-2">
          <label className="col-md-2" for="subject-name">
            {t("module.module")}:{" "}
          </label>
          <div className="col-md-10">
            <input
              className="form-control form-control-sm"
              type="text"
              id="subject-name"
              name="subjectName"
              value={modalForm.subjectName}
              onChange={onChangeModal}
            />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-md-2" for="description">
            {t("description")}:{" "}
          </label>
          <div className="col-md-10">
            <textarea
              className="form-control form-control-sm"
              id="description"
              name="description"
              rows="3"
              value={modalForm.description}
              onChange={onChangeModal}
            />
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="domain-id">
            {t("domain.domain")}:{" "}
          </label>
          <div className="col-md-10">
            <select
              className="form-select form-select-sm"
              id="domain-id"
              name="domainID"
              value={modalForm.domainID}
              onChange={(event) => onChangeModal(event)}
            >
              <option value="">{t("domain.domain-list")}</option>
              {domainList.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {t(`domain.name.${item.domainEngName}`)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          {reqType === "add" && (
            <button
              type="button"
              className="btn btn-sm btn-primary me-1"
              style={{ width: "60px" }}
              onClick={handleConfirmAddClick}
            >
              {t("add")}
            </button>
          )}
          {reqType === "modify" && (
            <button
              type="button"
              className="btn btn-sm btn-primary me-1"
              style={{ width: "60px" }}
              onClick={() => handleConfirmModClick()}
            >
              {t("modify")}
            </button>
          )}
          <button
            type="button"
            className="btn btn-sm btn-danger"
            style={{ width: "60px" }}
            onClick={handleCloseModal}
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SubjectList);
