import React from "react";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import "./Course.css";
import { Link } from "react-router-dom";
function Course({ data }) {
  const list = ["Enroll", "Complete", "Participating"];
  const Statement = list[Math.floor(Math.random() * 3)];
  const isDark = localStorage.getItem("aidriven-general-theme");
  return (
    <div className="col-12 col-md-6 col-lg-3">
      <div
        className="card-course"
        style={
          isDark == "true"
            ? { backgroundColor: "#5E5959" }
            : { backgroundColor: "white" }
        }
      >
        <img
          src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80"
          className="card-img-top"
          alt="..."
        />
        <div className="card-body">
          <div className="card-content">
            <div className="card-top">
              <h4 className="card-title card_course_name">{data.courseName}</h4>
              <div className="card-user">
                <img
                  src="https://images.unsplash.com/photo-1554151228-14d9def656e4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=633&q=80"
                  alt=""
                  className="card-user-avatar"
                />
                <div className="card-user-info">
                  <div className="card-user-top">
                    <h4 className="card-user-name">{data.trainerFullname}</h4>
                    <ion-icon name="checkmark-circle"></ion-icon>
                  </div>
                </div>
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Header className="popover-header" as="h3">
                        More Information
                      </Popover.Header>
                      <Popover.Body>
                        <p>
                          <b>Description: </b>
                          {data.moduleDescription}
                        </p>
                        <p>
                          <b>Schedule: </b>
                          {data.scheduleInfo}
                        </p>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button variant="outline-none" id="popovers-icon">
                    <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="card-time">
                <div className="row row__title">
                  <b>Interval:</b> {data.startDate} - {data.endDate}
                </div>
                <div className="row row__title">
                  <b>Subject: </b>
                  {data.moduleName}
                </div>
                <div className="row row__title">
                  <b>Domain:</b>
                  {data.domainName}
                </div>
              </div>
            </div>
            <div className="card-bottom">
              {Statement === "Complete" && (
                <button
                  className="btn card-live"
                  style={{ backgroundColor: "red" }}
                >
                  <span>Completed!</span>
                </button>
              )}
              {Statement === "Participating" && (
                <button
                  className="btn card-live"
                  style={{ backgroundColor: "#0abd" }}
                >
                  <span>Participating</span>
                </button>
              )}
              {Statement === "Enroll" && (
                <button
                  className="btn card-live"
                  style={{ backgroundColor: "green" }}
                >
                  <span>Enroll</span>
                </button>
              )}
              <button className="btn card-live">
                <span>
                  <Link
                    className="text-decoration-none link__to"
                    to={"/course_list/member"}
                  >
                    4.2k Member
                  </Link>
                </span>
              </button>
              {/* <div className="card-watching">4.2k members</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Course;
