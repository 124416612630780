import React, { useEffect, useState } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import EmailForm from "../shared/EmailForm";
import { makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Snackbar";
import cs from "../../const";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation, withTranslation } from "react-i18next";
import axios from "axios";
import "./Courses.css";

const getCourseDataUrl = cs.BaseURL + "/api/trainer/trainee/final-score/list?";
const getTraineesUrl = cs.BaseURL + "/api/trainee/list";
const getTraineeScoreDetailUrl = cs.BaseURL + "/api/trainer/trainee/exam-score/list?";
const sendEmailUrl = cs.BaseURL + "/api/trainer/email/send?";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70vw",
    maxHeight: "90vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #888",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CourseDetail = ({ match, t, i18n }) => {
  const { courseId } = match.params;
  const classes = useStyles();

  const [responseMessage, setResponseMessage] = useState({ type: "", content: "" });
  const [openMessage, setOpenMessage] = useState(false);
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setResponseMessage({ type: "", content: "" });
    setOpenMessage(false);
  };

  const [emailAddress, setEmailAddress] = useState("");
  const [openDetailScore, setOpenDetailScore] = useState(false);
  const handleOpenDetail = (trainee_code) => {
    loadDetailScore(trainee_code);
    setOpenDetailScore(true);
  }
  const handleCloseDetail = () => {
    setDetailScore([])
    setOpenDetailScore(false);
  }

  const [scoreList, setScoreList] = useState([]);
  const [traineeList, setTraineeList] = useState([]);
  const [detailScore, setDetailScore] = useState([]);

  const getScoresData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getCourseDataUrl}course_id=${courseId}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      console.log("scorelist", response.data);
      setScoreList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSendEmailClick = async (traineeCode) => {
    try {
      const response = await axios({
        method: "get",
        url: `${sendEmailUrl}course_id=${courseId}&trainee_code=${traineeCode}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      })
      // console.log(response.data);
      if (response.data.error_desc === "Success") {
        setResponseMessage({ type: "success", content: "Gửi email thành công!" });
        setOpenMessage(true);
      } else {
        setResponseMessage({ type: "error", content: response.data.error_desc });
        setOpenMessage(true);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getTraineesData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getTraineesUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      // console.log("trainee", response.data);
      setTraineeList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getTrainee = (traineeCode) => {
    let trainee = {};
    traineeList.forEach(item => {
      if (item.traineeCode == traineeCode) {
        trainee = item;
        // console.log("item", item);
        return false;
      }
    })
    return trainee;
  }

  useEffect(() => {
    getScoresData();
    getTraineesData();
  }, []);

  const loadDetailScore = async (trainee_code) => {
    try {
      const response = await axios({
        method: "get",
        url: `${getTraineeScoreDetailUrl}course_id=${courseId}&trainee_code=${trainee_code}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      console.log("detail", response.data);
      setDetailScore(response.data.data);
    } catch (error) {
      console.log(error)
    }
  }

  return <ThemeContext.Consumer>
    {({ isDark }) =>
      <div className="card-body">
        <div className="page-title mb-3">{t("students-list")}</div>
        <div className="table-responsive-lg">
          <table className={isDark ? "table table-sm table-striped table-hover table-bordered table-dark" : "table table-sm table-striped table-hover table-bordered"}>
            <thead>
              <tr className="align-top">
                <th className="bg-primary text-white text-center index-col" scope="col">{t("index")}</th>
                <th className="bg-primary text-white text-center fullname-col" scope="col">{t("fullname")}</th>
                <th className="bg-primary text-white text-center birthday-col" scope="col">{t("birthday")}</th>
                <th className="bg-primary text-white text-center gender-col" scope="col">{t("gender")}</th>
                <th className="bg-primary text-white text-center phone-col" scope="col">{t("phone")}</th>
                <th className="bg-primary text-white text-center phone-col" scope="col">{t("email")}</th>
                {/* <th className="bg-primary text-white text-end" scope="col">Đầu kỳ</th>
                <th className="bg-primary text-white text-end" scope="col">Giữa kỳ</th>
                <th className="bg-primary text-white text-end" scope="col">Cuối kỳ</th> */}
                <th className="bg-primary text-white text-center score-col" scope="col">{t("final-score")}</th>
                {/* <th className="bg-primary text-white col-1 text-end" scope="col"></th> */}
                <th className="bg-primary text-white text-center sendEmail-col"></th>
              </tr>
            </thead>
            <tbody>
              {scoreList.map((item, index) => <tr key={index + 1}>
                <td className="text-center">{index + 1}</td>
                <td className="text-capitalize">{item.traineeFullname.toLowerCase()}</td>
                <td className="text-end">---</td>
                <td className="text-end">---</td>
                <td className="text-end">{item.traineeTelephone || "---"}</td>
                <td className="text-end">{item.traineeEmail || "---"}</td>
                {/* <td className="text-end">9.0</td>
                <td className="text-end">0.9</td>
                <td className="text-end">0.9</td> */}
                <td className="text-end">{item.finalScore}</td>
                <td className="text-nowrap text-center p-0">
                  <button
                    className="btn btn-sm p-0 me-1"
                    onClick={() => handleOpenDetail(item.traineeCode)}
                  >
                    {
                      isDark
                        ? <img src="https://img.icons8.com/fluency-systems-regular/20/ffffff/details-pane.png" />
                        : <img src="https://img.icons8.com/fluency-systems-regular/20/000000/details-pane.png" />
                    }
                  </button>
                  <button
                    className="btn btn-sm p-0"
                    onClick={() => handleSendEmailClick(item.traineeCode)}
                  >
                    {
                      isDark
                        ? <img src="https://img.icons8.com/material-outlined/20/ffffff/send-mass-email.png" />
                        : <img src="https://img.icons8.com/material-outlined/20/000000/send-mass-email.png" />
                    }
                  </button>
                </td>
              </tr>)}
              {!scoreList.length && <tr><td colspan={8}>{t("no-data")}</td></tr>}
            </tbody>
          </table>
        </div>
        {/* <EmailForm open={openFormEmail} emailAddr={emailAddress} handleCloseModal={handleCloseForm} /> */}

        <Modal
          style={{ top: 0 }}
          open={openDetailScore}
          onClose={handleCloseDetail}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <div className="container-fluid p-0">
              <table className={isDark ? "table table-sm table-striped table-hover table-bordered table-dark" : "table table-sm table-striped table-hover table-bordered"}>
                <thead>
                  <tr className="align-top">
                    <th className="bg-primary text-white text-center" scope="col">{t("index")}</th>
                    <th className="bg-primary text-white text-center" scope="col">{t("test-name")}</th>
                    <th className="bg-primary text-white text-center" scope="col">{t("score")}</th>
                  </tr>
                </thead>
                <tbody>
                  {detailScore.map((item, index) => <tr key={index + 1}>
                    <td className="text-center" style={{ width: "10%" }}>{index + 1}</td>
                    <td className="text-capitalize text-center" style={{ width: "50%" }}>{item.testName}</td>
                    <td className="text-capitalize text-end" style={{ width: "40%" }}>{item.traineeScore}</td>
                  </tr>)}
                  {!detailScore.length && <tr><td colspan={8}>No data</td></tr>}
                </tbody>
              </table>

            </div>
          </div>
        </Modal>

        <Snackbar open={openMessage} autoHideDuration={1500} onClose={handleCloseMessage}>
          <Alert onClose={handleCloseMessage} severity={responseMessage.type}>
            {responseMessage.content}
          </Alert>
        </Snackbar>
      </div>
    }
  </ThemeContext.Consumer>
}

export default withTranslation()(CourseDetail);