import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "./AprroveList.css";
import cs from "../../const";
import Member from "./Member";
import Pagin from "../shared/Pagin";
import { useTranslation, withTranslation } from "react-i18next";
const URL = cs.BaseURL + "/api/trainee/list";
const getCoursesListUrl = cs.BaseURL + "/api/course/list";

function MemberList({ t, i18n }) {
  const [Data, setData] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [displayApprove, setDisplayApprove] = useState({
    courseId: 0,
    status: "",
  });
  const onChangeDisplayApprove = (event) => {
    setDisplayApprove({
      ...displayApprove,
      [event.target.name]: event.target.value,
    });
  };
  const List = [...Data].reverse();
  // paginations
  const [postsPerPage, setpostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = List.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // console.log(currentPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // Read Function
  const readData = () => {
    axios({
      method: "get",
      url: `${URL}?course_id=${displayApprove.courseId}&registration_approval=${displayApprove.status}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [show, setShow] = useState(true);
  const Shown = (state) => {
    setShow(state);
  };
  const getCoursesData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getCoursesListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log("course", response.data);
      setCoursesList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readData();
    getCoursesData();
  }, [displayApprove, show]);

  return (
    <div className="card card-body shadow">
      <div className="page-title mb-3">{t("APPROVE LIST")}</div>
      <div className="container">
        <select
          className="select-form"
          name="courseId"
          value={displayApprove.courseId}
          onChange={onChangeDisplayApprove}
          style={{ marginRight: "10px" }}
        >
          <option className="option-form" value="0">
            {t("course.courses")}
          </option>
          {coursesList.map((item) => (
            <option value={item.courseId}>
              {item.courseId + " - " + item.courseName}
            </option>
          ))}
        </select>
        <select
          className="select-form"
          name="status"
          value={displayApprove.status}
          onChange={onChangeDisplayApprove}
        >
          <option className="option-form" value="">
            {t("course-member.waiting")}
          </option>
          <option className="option-form" value="1">
            {t("course-member.approved")}
          </option>
          <option className="option-form" value="2">
            {t("course-member.rejected")}
          </option>
        </select>
        <h4 className="component-top-title" style={{ textAlign: "start" }}>
          {" "}
        </h4>
        {currentPosts.map((data) => (
          <Member
            traineeFullname={data.traineeFullname}
            traineeTelephone={data.traineeTelephone}
            traineeBranch={data.traineeBranch}
            learningStatus={data.learningStatus}
            traineeEmail={data.traineeEmail}
            traineeDepartment={data.traineeDepartment}
            traineeJobPosition={data.traineeJobPosition}
            id={data.id}
            registrationApproval={data.registrationApproval}
            Shown={Shown}
            show={show}
            readData={readData}
            displayApprove={displayApprove}
            t={t}
            i18n={i18n}
          />
        ))}
        <div className="d-flex justify-content-center">
          <Pagin
            ItemsPerPage={postsPerPage}
            paginate={paginate}
            totalItems={Data.length}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(MemberList);
