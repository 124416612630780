import React, { useState } from "react";
import "./Exam.css";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const Question = ({
  posts,
  currentPost,
  loading,
  length,
  currentPage,
  handleClose,
  open,
  handleChange,
  answers,
  submitAnswer,
  course_id,
  type,
  openAlert,
  handleCloseAlert,
  t,
  i18n,
}) => {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [question, setQuestion] = useState();
  // const [answers, setAnswers] = useState({});
  const isDark = localStorage.getItem("aidriven-general-theme");
  let q = question;

  if (loading) {
    return <h2>Loading...</h2>;
  }
  const Style = makeStyles((theme) => ({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));
  const classes = Style();

  return (
    <div>
      {currentPost.map((post) => (
        <div
          className="card card__ques mb-3"
          style={
            isDark == "true"
              ? { backgroundColor: "#5E5959" }
              : { backgroundColor: "white" }
          }
        >
          <div className="card-body card__question_body">
            <h5 className="card-title card__index__question">
              {t("question")} {currentPage} {t("of")} {length}
            </h5>
            <h5 className="card-subtitle card__question__question">
              {post.questionContent}
            </h5>
            <div className="card__answer">
              <button
                name={post.questionId}
                value="choiceA"
                onClick={(e) => {
                  setQuestion(post.questionId);
                  handleChange(e.target.name, e.target.value);
                }}
                className={
                  answers[post.questionId] === "choiceA"
                    ? "btn btn-outline-info btn__choice active"
                    : "btn btn-outline-info btn__choice"
                }
              >
                <b style={{ marginRight: "10px" }}>A: </b>
                {post.choiceA}
              </button>
              <button
                name={post.questionId}
                value="choiceB"
                onClick={(e) => {
                  setQuestion(post.questionId);
                  handleChange(e.target.name, e.target.value);
                }}
                className={
                  answers[post.questionId] === "choiceB"
                    ? "btn btn-outline-info btn__choice active"
                    : "btn btn-outline-info btn__choice"
                }
              >
                <b style={{ marginRight: "10px" }}>B: </b>
                {post.choiceB}
              </button>
              <button
                name={post.questionId}
                value="choiceC"
                onClick={(e) => {
                  setQuestion(post.questionId);
                  handleChange(e.target.name, e.target.value);
                }}
                className={
                  answers[post.questionId] === "choiceC"
                    ? "btn btn-outline-info btn__choice active"
                    : "btn btn-outline-info btn__choice"
                }
              >
                <b style={{ marginRight: "10px" }}>C: </b>
                {post.choiceC}
              </button>
              <button
                name={post.questionId}
                value="choiceD"
                onClick={(e) => {
                  setQuestion(post.questionId);
                  handleChange(e.target.name, e.target.value);
                }}
                className={
                  answers[post.questionId] === "choiceD"
                    ? "btn btn-outline-info btn__choice active"
                    : "btn btn-outline-info btn__choice"
                }
              >
                <b style={{ marginRight: "10px" }}>D: </b>
                {post.choiceD}
              </button>
            </div>
          </div>
        </div>
      ))}
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("review")}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                submitAnswer();
                handleClose();
              }}
            >
              <Link
                className="text-decoration-none link__to"
                style={{ color: "white" }}
                to={
                  type == "exam" ? "/courses_view/" + course_id : "/my-courses/"
                }
              >
                {t("finish-attempt")}
              </Link>
            </Button>
          </Toolbar>
        </AppBar>
        {/* <List>
                <ListItem button>
                  <ListItemText primary="Phone ringtone" secondary="Titania" />
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText
                    primary="Default notification ringtone"
                    secondary="Tethys"
                  />
                </ListItem>
              </List> */}
        <table
          className="table table-bordered table-hover table-striped"
          style={
            isDark == "true"
              ? { backgroundColor: "#837C7C" }
              : { backgroundColor: "white" }
          }
        >
          <thead style={{ backgroundColor: "#447EEC", fontSize: "15px" }}>
            <tr>
              <th scope="col">{t("index")}</th>
              <th scope="col">{t("question")}</th>
              <th scope="col">{t("your-answer")}</th>
            </tr>
          </thead>
          <tbody>
            {posts.map((post, index) => (
              <tr>
                <th style={{ width: "60px", backgroundColor: "#eee" }}>
                  {index + 1}
                </th>
                <td>{post.questionContent}</td>
                <td>
                  {Object.keys(answers).map((key) => (
                    <p>
                      {key === post.questionId
                        ? answers[post.questionId].substring(
                            answers[post.questionId].length - 1,
                            answers[post.questionId].length
                          ) +
                          ": " +
                          post[answers[post.questionId]]
                        : null}
                    </p>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Dialog>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity="success">
          Submitted
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Question;
