import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import cs from "../../const";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation, withTranslation } from "react-i18next";
const startExamURL = cs.BaseURL + "/api/trainee/test-exam/start";
function StartExam({ t, i18n }) {
  const { course_id, testOrderNumber } = useParams();

  const [open, setOpen] = useState(true);
  const [testCode, setTestCode] = useState({
    test_code: "",
  });
  console.log("testOder", testCode);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    startExam();
  }, []);
  const startExam = async (conditions) => {
    if (window.confirm("Are you want to start this exam?")) {
      const response = await axios({
        method: "get",
        url: `${startExamURL}?courseId=${course_id}&testOrderNumber=${testOrderNumber}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response.data.error_desc === "Success") {
        setTestCode(response.data.data);
      } else if (
        response.data.error_desc ===
        "Trainee do not have permission to start exam"
      ) {
        if (window.alert(t("not-have-permission"))) {
          window.history.back();
        } else {
          window.history.back();
        }
      } else if (response.data.error_desc === "Exam has no question") {
        if (window.alert(t("no-question"))) {
          window.history.back();
        } else {
          window.history.back();
        }
      }
      console.log("testCode", testCode);
    } else {
      window.history.back();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("confirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("start-exam-confirm")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => window.history.back()} color="secondary">
            Disagree
          </Button> */}
          <Button
            // onClick={startExam}
            component={Link}
            to={testOrderNumber + "/exam/" + testCode.test_code}
            color="primary"
            autoFocus
          >
            {t("start-attempt")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(StartExam);
