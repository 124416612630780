import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const';
import moment from 'moment-timezone';
import DialogWrapper from '../shared/DialogWrapper';
// import { DropzoneArea } from 'material-ui-dropzone';

const courseURL = cs.BaseURL + '/api/trainer/courses/list';
const createLectureURL = cs.BaseURL + '/api/trainer/courses/lecture/create';

const d = new Date();
const minDate = `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`;
const maxDate = `${d.getFullYear() + 1}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`;

const hubName = ["Đồng bằng Bắc Bộ", "Đông Bắc Bộ", "Tây Bắc Bộ", "Bắc Trung Bộ", "Nam Trung Bộ", "Tây Nguyên", "Đông Nam Bộ", "Đồng bằng Sông Cửu Long", "Hà Nội", "TP.HCM"];

const styles = theme => ({});

function validatePhoneInput(phone) {
    const validHeadNums = /((086|096|097|098|032|033|034|035|036|037|038|039|088|091|094|083|084|085|081|082|092|056|058|089|090|093|070|079|077|076|078|099|059)+([0-9]{7})\b)/g;
    let validPhoneNumber = (String(phone).length === 10) && (validHeadNums.test(String(phone)));
    return (validPhoneNumber);
};

class CreateLecture extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            courseList: [],
            courseId: this.props.match.params.course_id,
            courseName: "",
            moduleName: "",
            moduleDescription: "",
            lectureOrderNumber: null,
            lectureTitle: "",
            lectureDate: ""
        };
        // if (!isTelesaleOP()) {
        //     window.location.href="/";
        //     return;
        // }

        this.loadCourses = this.loadCourses.bind(this);
        this.handleDialogAgree = this.handleDialogAgree.bind(this);

        this.loadCourses();
    }

    loadCourses() {
        console.log(this.state);
        let queryString = `${courseURL}?page=0&size=0`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    // data.data.unshift({ courseName: '--SELECT COURSE--', courseId: null });
                    this.setState({
                        courseList: data.data
                    });
                } else {
                    this.setState({
                        courseList: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    types: []
                });
            });
    }

    handleDialogAgree = () => {
        //tmpData = data.data.filter((el) => el.is_deleted_by_merchant === 0);
        if (!this.state.courseId ||
            !this.state.lectureOrderNumber ||
            !this.state.lectureTitle ||
            !this.state.lectureDate
        ) {
            window.alert('Please input information !');
            return;
        }

        // if (this.state.agent_phone != '') {
        //     if (!validatePhoneInput(this.state.agent_phone)) {
        //         window.alert("Incorrect phone number !");
        //         return;
        //     }
        // }
        let tmp = parseInt(this.state.lectureOrderNumber);
        console.log(tmp);
        if (tmp < 0 || tmp > 127) {
            window.alert('Incorrect amount !');
            return;
        }

        let queryString = `${createLectureURL}`;
        const { courseId, courseName,
            moduleName, moduleDescription,
            lectureOrderNumber,
            lectureTitle, lectureDate
        } = this.state;
        let requestBody = {
            courseId, 
            // courseName,
            // moduleName, moduleDescription,
            lectureOrderNumber,
            lectureTitle, lectureDate
        };

        console.log(requestBody)
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                // if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                //     localStorage.clear();
                //     sessionStorage.clear();
                //     window.location.reload();
                // }
                console.log(data.error_code != 0, data.error_desc != "Success");
                if (data && data.error_code != 0 && data.error_desc != "Success") {
                    this.props.toastManager.add(JSON.stringify(data.error_desc), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Lecture is created !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: `/course_lectures/${this.state.courseId}`,
                        state: { reload: true }
                    });
                    //                    window.location.href="/package-info;

                }
            })
            .catch(() => { });
    };

    // handleChange = name => event => {
    //     this.setState({
    //         [name]: event.target.value
    //     });

    // };

    handleChange = name => event => {
        if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    if (name === "courseId") {
                        let course = this.state.courseList.filter((el) => el.courseId === event.target.value);
                        if (course) {
                            this.setState({
                                courseName: course[0].courseName,
                                moduleName: course[0].moduleName,
                                moduleDescription: course[0].moduleDescription
                            })
                        }
                    }
                }
            );
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <DialogWrapper title={'Lecture - Thêm mới'} {...this.props}>
                    <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="merchantId">Course (*)</InputLabel>
                            <Select
                                value={this.state.courseId}
                                onChange={this.handleChange('courseId')}
                                inputProps={{
                                    name: 'package_type',
                                    id: 'package_type'
                                }}
                            >
                                {/* <MenuItem value={null} disabled>--SELECT--</MenuItem> */}
                                {this.state.courseList.map(item => (
                                    <MenuItem value={item.courseId}>{item.courseId}-{item.courseName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* <TextField
                            disabled
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="moduleName"
                            required={true}
                            value={this.state.moduleName}
                            onChange={this.handleChange('moduleName')}
                            label="moduleName"
                            type="name"
                        />

                        <TextField
                            disabled
                            fullWidth
                            multiline
                            autoFocus
                            margin="dense"
                            id="moduleDescription"
                            required={true}
                            value={this.state.moduleDescription}
                            onChange={this.handleChange('moduleDescription')}
                            label="moduleDescription"
                            type="name"
                        /> */}

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="lectureOrderNumber"
                            required={true}
                            value={this.state.lectureOrderNumber}
                            onChange={this.handleChange('lectureOrderNumber')}
                            label="lectureOrderNumber"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 128 } }}
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="lectureTitle"
                            required={true}
                            value={this.state.lectureTitle}
                            onChange={this.handleChange('lectureTitle')}
                            label="lectureTitle"
                            type="text"
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="lectureDate"
                            required={true}
                            value={this.state.lectureDate}
                            onChange={this.handleChange('lectureDate')}
                            label="lectureDate"
                            type="date"
                            InputLabelProps={{ shrink: true }}  
                            InputProps={{inputProps: { min: minDate, max: maxDate } }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Hủy
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Tạo mới
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

CreateLecture.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(CreateLecture));
