import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { withToastManager } from "react-toast-notifications";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import cs from "../../const";
import moment from "moment-timezone";
import DialogWrapper from "../shared/DialogWrapper";
import {
  Icon,
  List,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import { DropzoneArea } from 'material-ui-dropzone';
import VideoPlayer from "../shared/VideoPlayer/VideoPlayer";

const courseURL = cs.BaseURL + "/api/trainer/courses/list";
const uploadMaterialsURL = cs.BaseURL + "/api/trainer/courses/materials/upload";
const mediaURL = cs.MediaURL + "/material";
const hubName = [
  "Đồng bằng Bắc Bộ",
  "Đông Bắc Bộ",
  "Tây Bắc Bộ",
  "Bắc Trung Bộ",
  "Nam Trung Bộ",
  "Tây Nguyên",
  "Đông Nam Bộ",
  "Đồng bằng Sông Cửu Long",
  "Hà Nội",
  "TP.HCM",
];

const styles = (theme) => ({
  gridContainer: {
    paddingTop: 10,
  },
  downloadContainer: {
    paddingTop: 10,
  },
  dropZone: {
    paddingTop: 10,
    minHeight: 200,
  },
  erItem: {
    secondary: "pink",
  },
  statusItem: {
    marginTop: 5,
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
});

class ViewMaterial extends React.Component {
  constructor(props) {
    super(props);
    let data = this.props.location.state.data;
    let {
      courseId,
      lectureOrderNumber,
      materialDisplayedName,
      materialDownloadLink,
      materialId,
      materialTypeId,
      materialTypeName,
      videoDuration
    } = data;
    console.log("data", data);
    this.state = {
      courseId: this.props.match.params.course_id,
      lectureOrderNumber: this.props.match.params.lectureOrderNumber,
      materialId: this.props.match.params.materialId,
      materialDisplayedName: materialDisplayedName,
      materialTypeId: materialTypeId,
      materialTypeName: materialTypeName,
      materialDownloadLink: encodeURI(`${mediaURL}/${materialDownloadLink}`),
      videoDuration: videoDuration
      // data: this.props.location.state.data
    };
    console.log(this.state);
    if (data === null) {
      window.location.href = "/";
      return;
    }
  }

  handleClose = () => {
    // this.props.onClose();
    this.props.history.push({
      pathname: `/course_materials/${this.state.courseId}/${this.state.lectureOrderNumber}`,
      state: { reload: true },
    });
  };

  endsWithAny = (suffixes, string) => {
    return suffixes.some(function (suffix) {
        return string.endsWith(suffix);
    });
  }

  render() {
    const { classes } = this.props;
    console.log("VIEWMATERIAL",this.state);
    return (
      <div>
        <DialogWrapper
          title={`View materials ${this.state.materialDisplayedName}`}
          {...this.props}
          width={"xl"}
        >
          <DialogContent>
            {this.state.materialTypeName === "video"}
            {this.state.materialTypeName === "video" && (
              <VideoPlayer
                src={this.state.materialDownloadLink}
                // checkpoints={[5, 10, 15, 20, 25, 30]}
                duration={this.state.videoDuration}
                stops={4}
                // stops={10}
              />
            )}

            {/* {this.state.materialTypeName === "document" &&
              !this.state.materialDownloadLink.endsWith(".pdf") && (
                <DocViewer
                  // documents={docs}
                  documents={[{ uri: this.state.materialDownloadLink }]}
                  pluginRenderers={DocViewerRenderers}
                  style={{ minHeight: "800px" }}
                />
              )} */}

            {this.state.materialTypeName === "document" && 
            this.state.materialDownloadLink.endsWith(".pdf") &&
              (
                <iframe
                  src={this.state.materialDownloadLink}
                  width="100%"
                  height="800"
                >
                  {" "}
                </iframe>
              )}

            {this.state.materialTypeName === "document" && 
            this.endsWithAny([".jng",".jpg",".png",".jpeg",".gif"],this.state.materialDownloadLink) &&
              (
                <iframe
                  src={this.state.materialDownloadLink}
                  width="100%"
                  height="800"
                >
                  {" "}
                </iframe>
              )}

            {this.state.materialTypeName === "document" && 
            this.endsWithAny([".doc",".docx",".xls", ".xlsx",".ppt",".pptx"],this.state.materialDownloadLink) &&
              (
                <div style={{height:"800px"}}>
                  <a href={this.state.materialDownloadLink}>Download file</a>
                </div>
              )}

            {this.state.materialTypeName === "document" && 
            this.endsWithAny([".txt"],this.state.materialDownloadLink) &&
              (
                <div style={{height:"800px"}}>
                  <a href={this.state.materialDownloadLink} target="_blank">Download file</a>
                </div>
              )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Hủy
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Finish
            </Button>
          </DialogActions>
        </DialogWrapper>
      </div>
    );
  }
}

const docs = [
  {
    uri: "https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf",
  },
  { uri: "https://www.physixfan.com/wp-content/files/GEBen.pdf" },
  {
    uri: "https://www.barnstableacademy.com/app/uploads/2021/05/Hamlet-Quiz-Acts-1-2.doc",
  },
  {
    uri: "https://spxkc.org/documents/Murph/AP%20English%20III/Hamlet%20HRW%20PPt.ppt",
  },
  // { uri: require("./Hofstadter-1979 Gödel Escher Bach.pdf") }, // Local File
];

ViewMaterial.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withToastManager(withStyles(styles)(ViewMaterial));
