import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const';
import moment from 'moment-timezone';
import DialogWrapper from '../shared/DialogWrapper';
// import { DropzoneArea } from 'material-ui-dropzone';

const createUserURL = cs.BaseURL + '/api/user/create';


const hubName = ["Đồng bằng Bắc Bộ", "Đông Bắc Bộ", "Tây Bắc Bộ", "Bắc Trung Bộ", "Nam Trung Bộ", "Tây Nguyên", "Đông Nam Bộ", "Đồng bằng Sông Cửu Long", "Hà Nội", "TP.HCM"];

const styles = theme => ({});

function validatePhoneInput(phone) {
    const validHeadNums = /((086|096|097|098|032|033|034|035|036|037|038|039|088|091|094|083|084|085|081|082|092|056|058|089|090|093|070|079|077|076|078|099|059)+([0-9]{7})\b)/g;
    let validPhoneNumber = (String(phone).length === 10) && (validHeadNums.test(String(phone)));
    return (validPhoneNumber);
};

class CreateUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // package_name: '',
            // package_type: '',
            // package_types: ["analysis", "marketing", "marketing"],
            // amount: 0

            agent_id: '',
            agent_name: '',
            hub: '',
            agent_phone: '',
            adr_state: '',
            adr_state_code: '',
            adr_district: '',
            adr_district_code: '',
            adr_ward: '',
            adr_ward_code: '',
            states: [],
            districts: [],
            wards: [],
            agents: [],
            hubs: Array.from(new Array(10), (x, i) => i + 1)

        };
        // if (!isTelesaleOP()) {
        //     window.location.href="/";
        //     return;
        // }
        this.handleDialogAgree = this.handleDialogAgree.bind(this);

        //this.loadMerchants();
    }

    handleDialogAgree = () => {
        //tmpData = data.data.filter((el) => el.is_deleted_by_merchant === 0);
        if (!this.state.agent_id ||
            !this.state.agent_name ||
            !this.state.agent_phone
        ) {
            window.alert('Please input information !');
            return;
        }

        if (this.state.agent_phone != '') {
            if (!validatePhoneInput(this.state.agent_phone)) {
                window.alert("Incorrect phone number !");
                return;
            }
        }
        //let agent = this.state.agents.filter((el) => el.id === this.state.agent_id)
        let state = this.state.states.filter((el) => el.state_code === this.state.adr_state_code)
        let district = this.state.districts.filter((el) => el.district_code === this.state.adr_district_code)
        let ward = this.state.wards.filter((el) => el.ward_code === this.state.adr_ward_code)
        console.log(state);
        console.log(district);
        console.log(ward);
        // if (this.state.amount && isNaN(this.state.amount)) {
        //     window.alert('Incorrect amount !');
        //     return;
        // }

        let queryString = `${createUserURL}`;
        let requestBody = {
            agent_id: this.state.agent_id,
            agent_name: this.state.agent_name,
            agent_phone: this.state.agent_phone,
            hub: this.state.hub,
            adr_state_code: this.state.adr_state_code,
            adr_district_code: this.state.adr_district_code,
            adr_ward_code: this.state.adr_ward_code,

            adr_state: state.length ? state[0].state_name : "",
            adr_district: district.length ? district[0].district_name : "",
            adr_ward: ward.length ? ward[0].ward_name : ""
        };

        console.log(requestBody)
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('AgentLocation is created !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: '/agent-location',
                        state: { reload: true }
                    });
                    //                    window.location.href="/package-info;

                }
            })
            .catch(() => { });
    };

    // handleChange = name => event => {
    //     this.setState({
    //         [name]: event.target.value
    //     });

    // };

    handleChange = name => event => {
        if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    
                }
            );
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <DialogWrapper title={'User - Thêm mới'} {...this.props}>
                    <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="merchantId">Agent (*)</InputLabel>
                            <Select
                                value={this.state.agent_id}
                                onChange={this.handleChange('agent_id')}
                                inputProps={{
                                    name: 'package_type',
                                    id: 'package_type'
                                }}
                            >
                                {this.state.agents.map(item => (
                                    <MenuItem value={item.id}>{item.code}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            disabled
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="agent_name"
                            required={true}
                            value={this.state.agent_name}
                            onChange={this.handleChange('agent_name')}
                            label="Tên"
                            type="name"
                        />

                        <TextField
                            disabled
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="agent_phone"
                            required={true}
                            value={this.state.agent_phone}
                            onChange={this.handleChange('agent_phone')}
                            label="SĐT"
                            type="name"
                        />

                        <FormControl fullWidth>
                            <InputLabel htmlFor="adr_ward_id">Hub</InputLabel>
                            <Select
                                value={this.state.hub}
                                onChange={this.handleChange('hub')}
                                inputProps={{
                                    name: 'adr_ward_code',
                                    id: 'adr_ward_id'
                                }}
                            >
                                {this.state.hubs.map(item => (
                                    <MenuItem value={item}>
                                        {hubName[item - 1] + " (" + item + ")"}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="cus_adr_state_id">
                                Tỉnh/thành phố (*)
                                    </InputLabel>
                            <Select
                                value={this.state.adr_state_code}
                                onChange={this.handleChange('adr_state_code')}
                                inputProps={{
                                    name: 'adr_state_code',
                                    id: 'adr_state_id'
                                }}
                            >
                                {this.state.states.map(pro => (
                                    <MenuItem value={pro.state_code}>
                                        {pro.state_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="adr_district_id">
                                Quận / huyện (*)
                                    </InputLabel>
                            <Select
                                value={this.state.adr_district_code}
                                onChange={this.handleChange('adr_district_code')}
                                inputProps={{
                                    name: 'adr_district_code',
                                    id: 'adr_district_id'
                                }}
                            >
                                {this.state.districts.map(pro => (
                                    <MenuItem value={pro.district_code}>
                                        {pro.district_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="adr_ward_id">Xã/Phường</InputLabel>
                            <Select
                                value={this.state.adr_ward_code}
                                onChange={this.handleChange('adr_ward_code')}
                                inputProps={{
                                    name: 'adr_ward_code',
                                    id: 'adr_ward_id'
                                }}
                            >
                                {this.state.wards.map(pro => (
                                    <MenuItem value={pro.ward_code}>
                                        {pro.ward_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Hủy
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Tạo mới
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

CreateUser.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(CreateUser));
