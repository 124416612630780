import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const';
import moment from 'moment-timezone';
import DialogWrapper from '../shared/DialogWrapper';
// import { DropzoneArea } from 'material-ui-dropzone';

const courseURL = cs.BaseURL + '/api/trainer/courses/list';
const examTypeURL = cs.BaseURL + '/api/common/exam-type/list';
const examURL = cs.BaseURL + '/api/trainer/test-exam/detail';
const editExamURL = cs.BaseURL + '/api/trainer/test-exam/edit';

const d = new Date();
const minDate = `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`;
const maxDate = `${d.getFullYear() + 1}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`;


const hubName = ["Đồng bằng Bắc Bộ", "Đông Bắc Bộ", "Tây Bắc Bộ", "Bắc Trung Bộ", "Nam Trung Bộ", "Tây Nguyên", "Đông Nam Bộ", "Đồng bằng Sông Cửu Long", "Hà Nội", "TP.HCM"];

const styles = theme => ({});

function validatePhoneInput(phone) {
    const validHeadNums = /((086|096|097|098|032|033|034|035|036|037|038|039|088|091|094|083|084|085|081|082|092|056|058|089|090|093|070|079|077|076|078|099|059)+([0-9]{7})\b)/g;
    let validPhoneNumber = (String(phone).length === 10) && (validHeadNums.test(String(phone)));
    return (validPhoneNumber);
};

class EditExam extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            courseList: [],
            examTypeList: [],
            courseId: this.props.match.params.course_id,
            testOrderNumber: this.props.match.params.testOrderNumber,
            testName: '',
            testDate: '',
            percentageOfFinalMark: 0,
            numberOfVeryEasyQuestions: 0,
            numberOfEasyQuestions: 0,
            numberOfAverageQuestions: 0,
            numberOfHardQuestions: 0,
            numberOfVeryHardQuestions: 0,
            totalNumberOfQuestions: 0,
            totalTime: 0,
            examType: ''
        };
        // if (!isTelesaleOP()) {
        //     window.location.href="/";
        //     return;
        // }

        this.loadCourses = this.loadCourses.bind(this);
        this.loadExamTypes = this.loadExamTypes.bind(this);
        this.handleDialogAgree = this.handleDialogAgree.bind(this);

        this.loadCourses();
        this.loadExamTypes();
        this.loadData();
    }

    loadCourses() {
        console.log(this.state);
        let queryString = `${courseURL}?page=0&size=0`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    // data.data.unshift({ courseName: '--SELECT COURSE--', courseId: null });
                    this.setState({
                        courseList: data.data
                    });
                } else {
                    this.setState({
                        courseList: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    types: []
                });
            });
    }

    loadExamTypes() {
        console.log(this.state);
        let queryString = `${examTypeURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    // data.data.unshift({ courseName: '--SELECT COURSE--', courseId: null });
                    this.setState({
                        examTypeList: data.data
                    });
                } else {
                    this.setState({
                        examTypeList: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    examTypeList: []
                });
            });
    }

    loadData() {
        console.log(this.state);
        let queryString = `${examURL}?courseId=${this.state.courseId}&testOrderNumber=${this.state.testOrderNumber}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {

                if (data && data.data) {
                    // data.data.unshift({ courseName: '--SELECT COURSE--', courseId: null });
                    this.setState({
                        courseId: data.data.courseId,
                        testOrderNumber: data.data.testOrderNumber,
                        testName: data.data.testName,
                        testDate: data.data.testDate,
                        percentageOfFinalMark: data.data.percentageOfFinalMark,
                        numberOfVeryEasyQuestions: data.data.numberOfVeryEasyQuestions,
                        numberOfEasyQuestions: data.data.numberOfEasyQuestions,
                        numberOfAverageQuestions: data.data.numberOfAverageQuestions,
                        numberOfHardQuestions: data.data.numberOfHardQuestions,
                        numberOfVeryHardQuestions: data.data.numberOfVeryHardQuestions,
                        totalNumberOfQuestions: data.data.totalNumberOfQuestions,
                        totalTime: data.data.totalTime,
                        examType: data.data.examType,
                    });
                } else {
                    // this.setState({
                    //     courseList: []
                    // });
                    // if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    //     sessionStorage.clear();
                    //     localStorage.clear();
                    //     window.location.reload();
                    // }
                }
            })
            .catch(() => {
                this.setState({
                    types: []
                });
            });
    }

    handleDialogAgree = () => {
        console.log(this.state);
        //tmpData = data.data.filter((el) => el.is_deleted_by_merchant === 0);
        if (!this.state.courseId ||
            this.state.testOrderNumber == null ||
            !this.state.testName ||
            !this.state.testDate ||

            // this.state.percentageOfFinalMark != null ||
            // this.state.numberOfVeryEasyQuestions !=null ||
            // !this.state.numberOfEasyQuestions ||
            // !this.state.numberOfAverageQuestions ||
            // !this.state.numberOfHardQuestions ||
            // !this.state.numberOfVeryHardQuestions ||
            // !this.state.totalNumberOfQuestions ||
            // !this.state.totalTime ||
            !this.state.examType
        ) {
            window.alert('Please input information !');
            return;
        }

        // if (this.state.agent_phone != '') {
        //     if (!validatePhoneInput(this.state.agent_phone)) {
        //         window.alert("Incorrect phone number !");
        //         return;
        //     }
        // }
        let tmp = parseInt(this.state.percentageOfFinalMark);
        // console.log(tmp);
        if (tmp < 0 || tmp > 100) {
            window.alert('Incorrect amount !');
            return;
        }

        let queryString = `${editExamURL}?courseId=${this.state.courseId}&testOrderNumber=${this.props.match.params.testOrderNumber}`;
        const {
            courseId,
            testOrderNumber,
            testName,
            testDate,
            percentageOfFinalMark,
            numberOfVeryEasyQuestions,
            numberOfEasyQuestions,
            numberOfAverageQuestions,
            numberOfHardQuestions,
            numberOfVeryHardQuestions,
            totalNumberOfQuestions,
            totalTime,
            examType
        } = this.state;

        if (parseInt(numberOfVeryEasyQuestions) < 0 ||
            parseInt(numberOfEasyQuestions) < 0 ||
            parseInt(numberOfAverageQuestions) < 0 ||
            parseInt(numberOfHardQuestions) < 0 ||
            parseInt(numberOfVeryHardQuestions) < 0 ||
            parseInt(totalNumberOfQuestions) <= 0
        ) {
            window.alert('Incorrect amount !');
            return;
        }

        let requestBody = {
            // courseId: parseInt(courseId),
            testOrderNumber: parseInt(testOrderNumber),
            testName,
            testDate,
            percentageOfFinalMark: parseInt(percentageOfFinalMark),
            numberOfVeryEasyQuestions: parseInt(numberOfVeryEasyQuestions),
            numberOfEasyQuestions: parseInt(numberOfEasyQuestions),
            numberOfAverageQuestions: parseInt(numberOfAverageQuestions),
            numberOfHardQuestions: parseInt(numberOfHardQuestions),
            numberOfVeryHardQuestions: parseInt(numberOfVeryHardQuestions),
            totalNumberOfQuestions: parseInt(totalNumberOfQuestions),
            totalTime: parseInt(totalTime),
            examType
        };

        console.log(requestBody)
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                // if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                //     localStorage.clear();
                //     sessionStorage.clear();
                //     window.location.reload();
                // }
                console.log(data.error_code != 0, data.error_desc != "Success");
                if (data && data.error_code != 0 && data.error_desc != "Success") {
                    this.props.toastManager.add(JSON.stringify(data.error_desc), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Exam is updated !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: `/course_exams/${this.state.courseId}`,
                        state: { reload: true }
                    });
                    //                    window.location.href="/package-info;

                }
            })
            .catch(() => { });
    };

    // handleChange = name => event => {
    //     this.setState({
    //         [name]: event.target.value
    //     });

    // };

    handleChange = name => event => {
        if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    // console.log("HERE");
                    if (name === "numberOfVeryEasyQuestions" ||
                        name === "numberOfEasyQuestions" ||
                        name === "numberOfAverageQuestions" ||
                        name === "numberOfHardQuestions" ||
                        name === "numberOfVeryHardQuestions"
                    ) {
                        console.log(this.state.numberOfVeryEasyQuestions,
                            this.state.numberOfEasyQuestions,
                            this.state.numberOfAverageQuestions,
                            this.state.numberOfHardQuestions,
                            this.state.numberOfVeryHardQuestions
                        );

                        let numberOfVeryEasyQuestions = parseInt(this.state.numberOfVeryEasyQuestions);
                        let numberOfEasyQuestions = parseInt(this.state.numberOfEasyQuestions);
                        let numberOfAverageQuestions = parseInt(this.state.numberOfAverageQuestions);
                        let numberOfHardQuestions = parseInt(this.state.numberOfHardQuestions);
                        let numberOfVeryHardQuestions = parseInt(this.state.numberOfVeryHardQuestions);

                        let total = numberOfVeryEasyQuestions + numberOfEasyQuestions
                            + numberOfAverageQuestions
                            + numberOfHardQuestions + numberOfVeryHardQuestions;
                        // console.log("HERE", total, event.target.value, this.state[name]);
                        // console.log(event.target.value - this.state[name]);
                        this.setState({
                            totalNumberOfQuestions: total
                        })
                    }
                }
            );
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <DialogWrapper title={'Exam - Sửa'} {...this.props}>
                    <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="merchantId">Khóa học (*)</InputLabel>
                            <Select
                                value={this.state.courseId}
                                onChange={this.handleChange('courseId')}
                                inputProps={{
                                    name: 'package_type',
                                    id: 'package_type'
                                }}
                            >
                                {/* <MenuItem value={null} disabled>--SELECT--</MenuItem> */}
                                {this.state.courseList.map(item => (
                                    <MenuItem value={item.courseId}>{item.courseId}-{item.courseName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* <TextField
                            disabled
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="moduleName"
                            required={true}
                            value={this.state.moduleName}
                            onChange={this.handleChange('moduleName')}
                            label="moduleName"
                            type="name"
                        />

                        <TextField
                            disabled
                            fullWidth
                            multiline
                            autoFocus
                            margin="dense"
                            id="moduleDescription"
                            required={true}
                            value={this.state.moduleDescription}
                            onChange={this.handleChange('moduleDescription')}
                            label="moduleDescription"
                            type="name"
                        /> */}

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="testOrderNumber"
                            required={true}
                            value={this.state.testOrderNumber}
                            onChange={this.handleChange('testOrderNumber')}
                            label="testOrderNumber"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 128 } }}
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="testName"
                            required={true}
                            value={this.state.testName}
                            onChange={this.handleChange('testName')}
                            label="Tên"
                            type="text"
                        />

                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="testDate"
                            required={true}
                            value={this.state.testDate}
                            onChange={this.handleChange('testDate')}
                            label="Ngày"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{inputProps: { min: minDate, max: maxDate } }}
                        />

                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="percentageOfFinalMark"
                                    required={true}
                                    value={this.state.percentageOfFinalMark}
                                    onChange={this.handleChange('percentageOfFinalMark')}
                                    label="percentageOfFinalMark"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="numberOfVeryEasyQuestions"
                                    required={true}
                                    value={this.state.numberOfVeryEasyQuestions}
                                    onChange={this.handleChange('numberOfVeryEasyQuestions')}
                                    label="Rất dễ"
                                    type="number"
                                // InputProps={{ inputProps: { min: 0, max: 128 } }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="numberOfEasyQuestions"
                                    required={true}
                                    value={this.state.numberOfEasyQuestions}
                                    onChange={this.handleChange('numberOfEasyQuestions')}
                                    label="Dễ"
                                    type="number"
                                // InputProps={{ inputProps: { min: 0, max: 128 } }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="numberOfAverageQuestions"
                                    required={true}
                                    value={this.state.numberOfAverageQuestions}
                                    onChange={this.handleChange('numberOfAverageQuestions')}
                                    label="Trung bình"
                                    type="number"
                                // InputProps={{ inputProps: { min: 0, max: 128 } }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="numberOfHardQuestions"
                                    required={true}
                                    value={this.state.numberOfHardQuestions}
                                    onChange={this.handleChange('numberOfHardQuestions')}
                                    label="Khó"
                                    type="number"
                                // InputProps={{ inputProps: { min: 0, max: 128 } }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="numberOfVeryHardQuestions"
                                    required={true}
                                    value={this.state.numberOfVeryHardQuestions}
                                    onChange={this.handleChange('numberOfVeryHardQuestions')}
                                    label="Rất khó"
                                    type="number"
                                // InputProps={{ inputProps: { min: 0, max: 128 } }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    disabled
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="totalNumberOfQuestions"
                                    required={true}
                                    value={this.state.totalNumberOfQuestions}
                                    onChange={this.handleChange('totalNumberOfQuestions')}
                                    label="Tổng số câu hỏi"
                                    type="number"
                                    InputLabelProps={{ shrink: true }}
                                // InputProps={{ inputProps: { min: 0, max: 128 } }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="totalTime"
                                    required={true}
                                    value={this.state.totalTime}
                                    onChange={this.handleChange('totalTime')}
                                    label="Thời gian làm bài (phút)"
                                    type="number"
                                // InputProps={{ inputProps: { min: 0, max: 128 } }}
                                />
                            </Grid>
                        </Grid>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="merchantId">Exam type (*)</InputLabel>
                            <Select
                                value={this.state.examType}
                                onChange={this.handleChange('examType')}
                            // inputProps={{
                            //     name: 'package_type',
                            //     id: 'package_type'
                            // }}
                            >
                                {/* <MenuItem value={null} disabled>--SELECT--</MenuItem> */}
                                {this.state.examTypeList.map(item => (
                                    <MenuItem value={item.examTypeId}>{item.examTypeId}-{item.examTypeVieName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Hủy
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Sửa
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

EditExam.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(EditExam));
