import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
// import Navbar from "../shares/Navbar";
import Employee from "./Employee";
import cs from "../../const";
function ExpertList() {
  const List = [];
  for (let i = 0; i < 10; ++i) List.push(i);
  const [Data, setData] = useState([]);
  const [Create, setCreate] = useState([]);
  // Read Function
  const loadData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${cs.BaseURL}/api/employee/list`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log(response);
      setData(response.data.data);
    } catch (err) {
      console.log("err");
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  function createExpert() {
    console.log("Create", Create);
    axios({
      method: "post",
      url: `${cs.BaseURL}/api/trainer/create`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        id: Create.id,
        title: Create.title,
        expertise_domain: Create.expertise_domain,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("miss Data Create Edu");
      });
    window.location.reload();
  }
  const onChange = (e) => {
    setCreate({
      ...Create,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div className="card card-body shadow">
      {/* <div className="mb-3">
        <Navbar />
      </div> */}
      <div className="page-title mb-3">
        EMPLOYEE LIST
      </div>
      <div className="container">
        <div>
          <button
            className="btn btn-outline-none component-top-button icon"
            data-bs-toggle="modal"
            data-bs-target="#createModal"
          >
            <img
              className="button-icon"
              src="https://cdn-icons-png.flaticon.com/512/3032/3032276.png"
              alt="add-icon"
            />
          </button>
          <div
            className="modal fade"
            id="createModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    CREATE FORM
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="form-field">
                    <input
                      type="text"
                      className="form-control form-input"
                      placeholder=" "
                      name="id"
                      onSubmit={(e) => handleSubmit(e)}
                      onChange={(e) => onChange(e)}
                    />
                    <label for="name" className="form-label">
                      ID
                    </label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      className="form-control form-input"
                      placeholder=" "
                      name="title"
                      onSubmit={(e) => handleSubmit(e)}
                      onChange={(e) => onChange(e)}
                    />
                    <label for="name" className="form-label">
                      Title
                    </label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      className="form-control form-input"
                      placeholder=" "
                      name="expertise_domain"
                      onSubmit={(e) => handleSubmit(e)}
                      onChange={(e) => onChange(e)}
                    />
                    <label for="name" className="form-label">
                      Expertise Domain
                    </label>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      createExpert();
                    }}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {Data.map((data) => (
            <Employee
              trainerFullname={data.fullname}
              trainerTelephone={data.phone}
              expertiseDomain={data.expertiseDomain}
              title={data.title}
              id={data.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ExpertList;
