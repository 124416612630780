import React, { useState } from "react";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import "./Expert.css";

function Expert({
  trainerFullname,
  trainerTelephone,
  expertiseDomain,
  title,
  id,
  data,
  modalForm,
  onChangeModal,
  handleCloseModal,
  handleModClick,
  handleConfirmModClick,
  handleDelClick,
  t,
  i18n,
}) {
  const isDark = localStorage.getItem("aidriven-general-theme");
  const list = [
    "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1524504388940-b1c1722653e1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    "https://images.unsplash.com/photo-1533227268428-f9ed0900fb3b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=879&q=80",
    "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  ];
  const Statement = useState(list[Math.floor(Math.random() * 9)]);
  return (
    <div className="card-main-expert">
      <div
        className="card-expert"
        style={
          isDark == "true"
            ? { backgroundColor: "#5E5959" }
            : { backgroundColor: "white" }
        }
      >
        <div className="card-body">
          <div className="card-content">
            <div className="row">
              <div className="col-2 column-start d-none d-md-block">
                <img
                  className="expert-avatar"
                  src={Statement}
                  alt="expert-avatar"
                />
                <div className="column-start-item"> ID: {id}</div>
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Header className="popover-header" as="h3">
                        {t("expert-list.description-expert")}
                      </Popover.Header>
                      <Popover.Body>{title}</Popover.Body>
                    </Popover>
                  }
                >
                  <Button variant="outline-none" id="popovers-icon">
                    <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="col-5 col-md-4 column-content">
                <div className="column-item">
                  <b>{t("fullname")}: </b>
                  {trainerFullname}
                </div>
                <div className="column-item">
                  <b>{t("expert-list.expertise-domain")}: </b>
                  {expertiseDomain}
                </div>
              </div>
              <div className="col-5 col-md-4 column-content">
                <div className="column-item">
                  <b>{t("phone")}: </b>
                  {trainerTelephone}
                </div>
                {/* <div className="column-item">
                  <b>{t("email")}: </b>
                  Vuthanh.uet@gmail.com
                </div> */}
              </div>
              <div className="col-2 col-md-2 column-edit">
                <button
                  className="btn btn-outline-none icon"
                  onClick={() => handleDelClick(id)}
                >
                  <img
                    className="button-icon"
                    src="https://cdn-icons-png.flaticon.com/512/812/812853.png"
                    alt="delete-icon"
                  />
                </button>
                <button
                  onClick={() => handleModClick(data)}
                  className="btn btn-outline-none icon"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <img
                    className="button-icon"
                    src="https://cdn-icons-png.flaticon.com/512/1160/1160758.png"
                    alt="edit-icon"
                  />
                </button>

                {/* Modal */}

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          {t("edit-expert")}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="form-field">
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder=" "
                            name="title"
                            value={modalForm.title}
                            onChange={(e) => onChangeModal(e)}
                          />
                          <label for="name" className="form-label">
                            {t("expert-list.description-expert")}
                          </label>
                        </div>
                        <div className="form-field">
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder=" "
                            name="domain"
                            value={modalForm.domain}
                            onChange={(e) => onChangeModal(e)}
                          />
                          <label for="name" className="form-label">
                            {t("expert-list.expertise-domain")}
                          </label>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                          onClick={handleCloseModal}
                        >
                          {t("cancel")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          onClick={() => handleConfirmModClick()}
                        >
                          {t("modify")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Header className="popover-header" as="h3">
                        {t("expert-list.description-expert")}
                      </Popover.Header>
                      <Popover.Body>{title}</Popover.Body>
                    </Popover>
                  }
                >
                  <Button
                    className="d-block d-md-none"
                    variant="outline-none"
                    id="popovers-icon"
                  >
                    <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "20px" }}>
        <img
          className="card-thumnail d-block d-md-none"
          src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
          alt="expert-avatar"
        />
      </div>
    </div>
  );
}

export default Expert;
