import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../contexts/ThemeContext";
import { useTranslation, withTranslation } from "react-i18next";
import cs from "../../const";
import "./Courses.css";

const getDomainDataUrl = cs.BaseURL + "/api/module/domain-list";
const getCourseDataUrl = cs.BaseURL + "/api/trainee/register-course/list?";

const STATUS_DESC = [
  { code: 0, status: "registered" },
  { code: 1, status: "register-success" },
  { code: 2, status: "learning" },
  { code: 3, status: "completed" },
  { code: 4, status: "not-completed" },
];

const MyCourses = ({ t, i18n }) => {
  const [courseList, setCourseList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [statusCourse, setStatusCourse] = useState(
    STATUS_DESC.find((element) => element.status === "register-success").code ||
      ""
  );

  const getCourseData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getCourseDataUrl}learning_status=${statusCourse}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log(statusCourse)
      // console.log("status", response.data);
      setCourseList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDomainData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getDomainDataUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log("data", response.data);
      setDomainList(response.data.data);
      // console.log(response.data.data)
    } catch (error) {
      console.log(error);
    }
  };

  const getDomainVieName = (domainId) => {
    let vieName = "";
    domainList.forEach((item) => {
      if (item.domainId === domainId) {
        vieName = item.domainVieName;
        return false;
      }
    });
    return vieName;
  };

  useEffect(() => {
    getCourseData();
    getDomainData();
  }, [statusCourse]);

  return (
    <ThemeContext.Consumer>
      {({ isDark }) => (
        <div className={isDark ? "card dark-background-1" : "card"}>
          <div className="card-body shadow">
            <div className="d-flex justify-content-between align-items-baseline">
              <div className="page-title mb-3">
                {t("course.my-course")}
              </div>
              <select
                className="form-select form-select-sm"
                style={{ width: "165px" }}
                value={statusCourse}
                onChange={(event) => setStatusCourse(event.target.value)}
              >
                {STATUS_DESC.map((element) => (
                  <option value={element.code}>
                    {t(`course.${element.status}`)}
                  </option>
                ))}
              </select>
            </div>
            {courseList.map((item) => {
              return (
                <div
                  className={
                    isDark
                      ? "card card-body mb-2 shadow-sm dark-background-2"
                      : "card card-body mb-2 shadow-sm"
                  }
                >
                  <h5 className="card-title">
                    <Link
                      to={"/courses_view/" + item.courseId}
                      className="stretched-link text-decoration-none text-black"
                    >
                      {item.courseName}
                    </Link>
                  </h5>
                  <div>
                    {t("domain.domain")}:{" "}
                    <Link
                      to="#"
                      className="stretched-link"
                      style={{ position: "relative" }}
                    >
                      {t(`domain.name.${item.domainName}`)}
                    </Link>
                  </div>
                  <div className="row row-cols-1 row-cols-sm-2">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      {t("expert")}:{" "}
                      <Link
                        to="#"
                        className="stretched-link"
                        style={{
                          position: "relative",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.trainerFullname.toLowerCase()}
                      </Link>
                    </div>
                    <div className="col">
                      {t("phone")}: {item.trainerTelephone}
                    </div>
                  </div>
                  <div>
                    {t("module.module")}:{" "}
                    <Link
                      to="#"
                      className="stretched-link"
                      style={{
                        position: "relative",
                        textTransform: "capitalize",
                      }}
                    >
                      {item.moduleName}
                    </Link>
                  </div>
                  <div>
                    {t("average-score")}:{" "}
                    {item.averageTraineeFinalExamScore
                      ? item.averageTraineeFinalExamScore
                      : t("no-points-yet")}
                  </div>
                </div>
              );
            })}
            {!courseList.length && <div>{t("no-data")}</div>}
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default withTranslation()(MyCourses);
