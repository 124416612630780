import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation, withTranslation } from "react-i18next";

import "./CoursesView.css";
import cs from "../../const";
import axios from "axios";
import { useParams, Link, Route } from "react-router-dom";
const URL = cs.BaseURL + "/api/trainee/course/lecture/list";
const mediaURL = cs.MediaURL + "/material";
const expertURL = cs.BaseURL + "/api/course/trainer/detail";
const examURL = cs.BaseURL + "/api/trainee/test-exam/list";

function CoursesView({ t, i18n }) {
  const { courseId } = useParams();
  const list = [
    "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1524504388940-b1c1722653e1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    "https://images.unsplash.com/photo-1533227268428-f9ed0900fb3b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=879&q=80",
    "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  ];
  const Statement = useState(list[Math.floor(Math.random() * 9)]);
  const [lectureList, setLectureList] = useState([
    {
      courseName: "",
      trainerFullname: "",
      moduleDescription: "",
      lectureMaterials: [],
    },
  ]);

  const [expertDetail, setExpertDetail] = useState({
    mcCode: "",
    expertiseDomain: "",
    trainerTelephone: "",
    trainerFullname: "",
    id: 0,
    title: "",
    trainerCode: "",
  });
  const [examList, setExamList] = useState([
    {
      testOrderNumber: 0,
      moduleDescription: "",
      courseName: "",
      totalTime: 0,
      moduleName: "",
      totalNumberOfQuestions: 1,
      testDate: "",
      testName: "",
    },
  ]);
  const [courseDetail, setCourseDetail] = useState({
    courseName: "",
  });
  // console.log("courseDetail", courseDetail);
  const [trainerFullName, setTrainerFullName] = useState(" ");
  const [moduleDescription, setModuleDescription] = useState(" ");
  const [tab, setTab] = useState("overview");
  const isDark = localStorage.getItem("aidriven-general-theme");

  const endsWithAny = (suffixes, string) => {
    return suffixes.some(function (suffix) {
      return string.endsWith(suffix);
    });
  };

  const queryString = () => {
    let queryString = `${URL}?course_id=${courseId}`;
    return queryString;
  };
  const loadData = async (conditions) => {
    const querystring = queryString();

    const response = await axios({
      method: "get",
      url: `${querystring}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    setLectureList(response.data.data);
    // console.log(lectureList);
    if (
      response.data.error_desc === "Success" &&
      response.data.data.length != 0
    ) {
      // setCourseName(response.data.data[0].courseName);
      setTrainerFullName(response.data.data[0].trainerFullname);
      setModuleDescription(response.data.data[0].moduleDescription);
    }
  };
  const loadCourseDetail = async (conditions) => {
    const response = await axios({
      method: "get",
      url: `${cs.BaseURL}/api/trainer/course/detail?course_id=${courseId}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    if (
      response.data.error_desc === "Success" &&
      response.data.data.length != 0
    ) {
      setCourseDetail(response.data.data);
      // console.log("res", response.data.data);
    }
  };
  // console.log("courseDetail", courseDetail);
  const loadExamList = async (conditions) => {
    const response = await axios({
      method: "get",
      url: `${examURL}?course_id=${courseId}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    setExamList(response.data.data);
    // console.log("ExamList", examList);
  };

  const loadDataExpert = async (conditions) => {
    const response = await axios({
      method: "get",
      url: `${expertURL}?course_id=${courseId}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    if (Object.keys(response.data.data.length != 0)) {
      setExpertDetail(response.data.data);
    }

    // console.log("expert", expertDetail);
  };
  useEffect(() => {
    loadCourseDetail();
    loadDataExpert();
    loadData();
    loadExamList();
  }, []);

  const [data, setData] = useState({
    courseId: "",
    lectureOrderNumber: "",
    materialId: "",
    materialDisplayedName: "",
    materialTypeId: "",
    materialTypeName: "",
    materialDownloadLink: "",
  });

  return (
    <div className="container">
      <div className="row course-name-row card-title text-center">
        {t("course.course")}: {courseDetail.courseName}
      </div>
      <div className="back-div mb-3">
        <button className="btn btn-outline-primary">
          <Link className="text-decoration-none text-black" to="/my-courselist">
            <img
              style={{ width: "30px", marginRight: "4px" }}
              src="https://cdn-icons-png.flaticon.com/512/2099/2099190.png"
            />
            {t("back-to-course")}
          </Link>
        </button>
      </div>
      <div
        style={{ marginBottom: "10px", fontWeight: "bold", fontSize: "15px" }}
      >
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              className={tab === "overview" ? "nav-link active" : "nav-link"}
              onClick={() => setTab("overview")}
            >
              {t("overview")}
            </a>
          </li>
          <li className="nav-item">
            <a
              className={tab === "exam" ? "nav-link active" : "nav-link"}
              onClick={() => setTab("exam")}
            >
              {t("exam")}
            </a>
          </li>
        </ul>
      </div>
      {lectureList.map((lecture) => (
        <div
          className={
            tab == "exam"
              ? "container lecture-card d-none"
              : "container lecture-card"
          }
        >
          <div className="row date-row">
            <div className="lecture-title">
              {t("lecture")}: {lecture.lectureTitle}
            </div>
            <div className="lecture-date">{lecture.lectureDateStr}</div>
          </div>
          <div className="col body-col">
            {lecture.lectureMaterials.map((item) => (
              <div className="row document-row">
                <div className="row document-title-row">
                  <b>{item.materialTypeName}</b>
                </div>
                <Link
                  // to={`/course_materials/${lecture.courseId}/${lecture.lectureOrderNumber}/view/${item.materialId}`}
                  to={{
                    pathname: `/courses_view/${lecture.courseId}/${lecture.lectureOrderNumber}/view/${item.materialId}`,
                    state: {
                      previous: `/courses_view/${lecture.courseId}`,
                      data: {
                        courseId: lecture.courseId,
                        lectureOrderNumber: lecture.lectureOrderNumber,
                        materialId: item.materialId,
                        materialDisplayedName: item.materialDisplayedName,
                        materialTypeId: item.materialTypeId,
                        materialTypeName: item.materialTypeName,
                        materialDownloadLink: item.materialDownloadLink,
                        videoDuration: item.videoDuration
                      },
                    },
                  }}
                  className="btn btn-outline-none document-detail-row"
                >
                  {item.materialDownloadLink.endsWith(".pdf") === true && (
                    <div className="d-flex space-between">
                      <img
                        style={{ width: "30px", marginRight: "10px" }}
                        src="https://cdn-icons-png.flaticon.com/512/337/337946.png"
                        alt="pdficon"
                      />
                      <div
                        className="file-name pt-1 pl-5"
                        style={
                          isDark == "true"
                            ? { color: "white" }
                            : { color: "black" }
                        }
                      >
                        {item.materialDisplayedName}
                      </div>
                    </div>
                  )}
                  {item.materialDownloadLink.endsWith(".xlsx") === true && (
                    <div onClick="" className="d-flex space-between">
                      <img
                        style={{ width: "30px", marginRight: "10px" }}
                        src="https://cdn-icons-png.flaticon.com/512/732/732220.png"
                        alt="excel-icon"
                      />
                      <div
                        className="file-name pt-1 pl-5"
                        style={
                          isDark == "true"
                            ? { color: "white" }
                            : { color: "black" }
                        }
                      >
                        {item.materialDisplayedName}
                      </div>
                    </div>
                  )}
                  {item.materialDownloadLink.endsWith(".txt") === true && (
                    <div onClick="" className="d-flex space-between">
                      <img
                        style={{ width: "30px", marginRight: "10px" }}
                        src="https://cdn-icons-png.flaticon.com/512/337/337956.png"
                        alt="txt-icon"
                      />
                      <div
                        className="file-name pt-1 pl-5"
                        style={
                          isDark == "true"
                            ? { color: "white" }
                            : { color: "black" }
                        }
                      >
                        {item.materialDisplayedName}
                      </div>
                    </div>
                  )}
                  {item.materialDownloadLink.endsWith(".doc") === true ||
                    (item.materialDownloadLink.endsWith(".docx") === true && (
                      <div onClick="" className="d-flex space-between">
                        <img
                          style={{ width: "30px", marginRight: "10px" }}
                          src="https://cdn-icons-png.flaticon.com/512/888/888883.png"
                          alt="word-icon"
                        />
                        <div
                          className="file-name pt-1 pl-5"
                          style={
                            isDark == "true"
                              ? { color: "white" }
                              : { color: "black" }
                          }
                        >
                          {item.materialDisplayedName}
                        </div>
                      </div>
                    ))}
                  {item.materialDownloadLink.endsWith(".pptx") === true && (
                    <div onClick="" className="d-flex space-between">
                      <img
                        style={{ width: "30px", marginRight: "10px" }}
                        src="https://cdn-icons-png.flaticon.com/512/888/888874.png"
                        alt="powerpoint-icon"
                      />
                      <div
                        className="file-name pt-1 pl-5"
                        style={
                          isDark == "true"
                            ? { color: "white" }
                            : { color: "black" }
                        }
                      >
                        {item.materialDisplayedName}
                      </div>
                    </div>
                  )}
                  {item.materialDownloadLink.endsWith(".mp4") === true && (
                    <div onClick="" className="d-flex space-between">
                      <img
                        style={{ width: "30px", marginRight: "10px" }}
                        src="https://cdn-icons-png.flaticon.com/512/3074/3074767.png"
                        alt="video-icon"
                      />
                      <div
                        className="file-name pt-1 pl-5"
                        style={
                          isDark == "true"
                            ? { color: "white" }
                            : { color: "black" }
                        }
                      >
                        {item.materialDisplayedName}
                      </div>
                    </div>
                  )}
                  {endsWithAny(
                    [".jng", ".jpg", ".png", ".jpeg", ".gif"],
                    item.materialDownloadLink
                  ) === true && (
                    <div onClick="" className="d-flex space-between">
                      <img
                        style={{ width: "30px", marginRight: "10px" }}
                        src="https://cdn-icons-png.flaticon.com/512/1160/1160157.png"
                        alt="image-icon"
                      />
                      <div
                        className="file-name pt-1 pl-5"
                        style={
                          isDark == "true"
                            ? { color: "white" }
                            : { color: "black" }
                        }
                      >
                        {item.materialDisplayedName}
                      </div>
                    </div>
                  )}
                  {item.materialDownloadLink.endsWith(".zip") === true ||
                    (item.materialDownloadLink.endsWith(".rar") && (
                      <div onClick="" className="d-flex space-between">
                        <img
                          style={{ width: "30px", marginRight: "10px" }}
                          src="https://cdn-icons-png.flaticon.com/512/1242/1242452.png"
                          alt="word-icon"
                        />
                        <div
                          className="file-name pt-1 pl-5"
                          style={
                            isDark == "true"
                              ? { color: "white" }
                              : { color: "black" }
                          }
                        >
                          {item.materialDisplayedName}
                        </div>
                      </div>
                    ))}
                </Link>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div
        className={
          tab == "overview" ? "container exam-tab d-none" : "container exam-tab"
        }
      >
        {examList.map((exam) => (
          <div className="exam-item">
            <div className="exam-title">
              <b>{exam.testDate}</b>
            </div>
            <Button
              component={Link}
              to={courseId + "/" + exam.testOrderNumber}
              className="btn btn-outline-none exam-link"
              style={{ textDecoration: "none" }}
            >
              <div onClick="" className="d-flex space-between">
                <img
                  style={{ width: "30px", marginRight: "10px" }}
                  src="https://cdn-icons-png.flaticon.com/512/327/327010.png"
                  alt="excel-icon"
                />
                <div
                  className="file-name pt-1 pl-5"
                  style={
                    isDark == "true" ? { color: "white" } : { color: "black" }
                  }
                >
                  {exam.testName}
                </div>
              </div>
            </Button>
          </div>
        ))}
      </div>
      <div className="row expert-info">
        <div className="col-4 image-col">
          <div className="row image-row d-flex justify-content-center">
            <img className="expert-img" src={Statement} alt="expert-avatar" />
            <div className="row expert-name ">
              <div style={{ marginTop: "2px" }}>
                <b
                  className="row title-row"
                  style={
                    isDark == "true"
                      ? { color: "rgb(219, 155, 15)" }
                      : { color: "blue" }
                  }
                >
                  {t("expert")}:
                </b>
                {expertDetail.trainerFullname}
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 infor-col expert-col">
          <div className="expert-description">
            <div
              className="row title-row"
              style={
                isDark == "true"
                  ? { color: "rgb(219, 155, 15)" }
                  : { color: "blue" }
              }
            >
              <b>{t("expert-infor")}:</b>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <b>{t("expert-list.expertise-domain")}:</b>{" "}
              {expertDetail.expertiseDomain}
            </div>
            <div style={{ marginLeft: "10px" }}>
              <b>{t("expert-list.description-expert")}:</b> {expertDetail.title}
            </div>
            <div style={{ marginLeft: "10px" }}>
              <b>{t("phone")}: </b> {expertDetail.trainerTelephonen}
            </div>
          </div>
          <div className="module-description">
            <div
              className="row title-row"
              style={
                isDark == "true"
                  ? { color: "rgb(219, 155, 15)" }
                  : { color: "blue" }
              }
            >
              <b>{t("module.description")}:</b>
            </div>
            <div style={{ marginLeft: "10px" }}>{moduleDescription}</div>
          </div>
        </div>
        <div className="col-4 infor-col course-col">
          <div className="course-description">
            <div
              className="row title-row"
              style={
                isDark == "true"
                  ? { color: "rgb(219, 155, 15)" }
                  : { color: "blue" }
              }
            >
              <b>{t("course.description")}:</b>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <b>{t("number-lecture")}:</b> {lectureList.length}
            </div>
            <div style={{ marginLeft: "10px" }}>
              <b>{t("schedule")}:</b> {courseDetail.scheduleInfo}
            </div>
            <div style={{ marginLeft: "10px" }}>
              <b>{t("location")}:</b> {courseDetail.location}
            </div>
            <div style={{ marginLeft: "10px" }}>
              <b>{t("start-date")}:</b> {courseDetail.startDate}
            </div>
            <div style={{ marginLeft: "10px" }}>
              <b>{t("end-date")}:</b> {courseDetail.endDate}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(CoursesView);
