import React from "react";
import { useState, useEffect } from "react";
import { OverlayTrigger, Popover, Button, ProgressBar } from "react-bootstrap";
import axios from "axios";
import cs from "../../const";
import "../Expert/Expert.css";
const URL = cs.BaseURL + "/api/trainee/course/approve";
function Member({
  traineeFullname,
  traineeTelephone,
  traineeBranch,
  learningStatus,
  traineeEmail,
  traineeDepartment,
  traineeJobPosition,
  id,
  registrationApproval,
  Shown,
  show,
  readData,
  displayApprove,
  t,
  i18n,
}) {
  const isDark = localStorage.getItem("aidriven-general-theme");
  // console.log("Shown", Shown);
  // console.log("S", show);
  const list = [
    "https://images.unsplash.com/photo-1504593811423-6dd665756598?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
    "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1524504388940-b1c1722653e1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    "https://images.unsplash.com/photo-1533227268428-f9ed0900fb3b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=879&q=80",
    "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
    "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  ];
  const Statement = useState(list[Math.floor(Math.random() * 10)]);

  let accept = 0;
  const Approve = () => {
    axios({
      method: "post",
      url: `${URL}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        trainee_id: id,
        registration_approval: accept,
      },
    })
      .then((res) => {
        // if (res.data.error_desc === "Success")
        readData();
      })
      .catch((err) => {
        console.log(err);
      });
    // window.location.reload();
  };
  return (
    <div className="card-main-expert">
      <div
        className="card-expert"
        style={
          isDark == "true"
            ? { backgroundColor: "#5E5959" }
            : { backgroundColor: "white" }
        }
      >
        <div className="card-body">
          <div className="card-content">
            <div className="row">
              <div className="col-2 column-start d-none d-md-block">
                <img
                  className="expert-avatar"
                  src={Statement}
                  alt="expert-avatar"
                />
                <div className="column-start-item"> ID: {id}</div>
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Header className="popover-header" as="h3">
                        {t("course-member.learning-status")}
                      </Popover.Header>
                      <Popover.Body>
                        {/* <ProgressBar
                          variant="warning"
                          animated
                          now={learningStatus + 65}
                        /> */}
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button variant="outline-none" id="popovers-icon">
                    <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col-6 column-content">
                    <div className="column-item">
                      <b>{t("fullname")}: </b>
                      {traineeFullname}
                    </div>
                  </div>
                  <div className="col-6 column-content">
                    <div className="column-item">
                      <b>{t("course-member.trainee-job-position")}: </b>
                      {traineeJobPosition}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 column-content">
                    <div className="column-item">
                      <b>{t("phone")}: </b>
                      {traineeTelephone}
                    </div>
                  </div>
                  <div className="col-6 column-content">
                    <div className="column-item">
                      <b>{t("course-member.trainee-email")}: </b>
                      {traineeEmail}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 column-content">
                    <div className="column-item">
                      <b>{t("course-member.trainee-branch")}: </b>
                      {traineeBranch}
                    </div>
                  </div>
                  <div className="col-6 column-content">
                    <div className="column-item">
                      <b>{t("course-member.trainee-department")}: </b>
                      {traineeDepartment}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 column-content">
                    <div className="column-item">
                      <b>{t("course-member.registration-course")}: </b>
                    </div>
                  </div>
                  <div className="col-6 column-content">
                    <div className="column-item">
                      <b>{t("state")}: </b>
                      {registrationApproval === 1 && (
                        <div className="badge bg-success">
                          {t("course-member.approved")}
                        </div>
                      )}
                      {registrationApproval === 2 && (
                        <div className="badge bg-danger">
                          {t("course-member.rejected")}
                        </div>
                      )}
                      {registrationApproval !== 1 &&
                        registrationApproval !== 2 && (
                          <div className="badge bg-secondary">
                            {t("course-member.waiting")}
                          </div>
                        )}
                    </div>
                  </div>
                  <div
                    className="col-6 column-content"
                    style={{ justifyContent: "flex-end" }}
                  >
                    {show == true && registrationApproval === 0 ? (
                      <button
                        onClick={() => {
                          accept = 1;
                          Shown(false);
                          Approve();
                        }}
                        className="btn btn-success"
                        style={{
                          width: "fit-content",
                          marginRight: "10px",
                          marginBottom: "5px",
                        }}
                      >
                        {t("approve")}{" "}
                        <ion-icon name="checkmark-outline"></ion-icon>
                      </button>
                    ) : null}
                    {show == true && registrationApproval === 0 ? (
                      <button
                        onClick={() => {
                          accept = 2;
                          Shown(false);
                          Approve();
                        }}
                        className="btn btn-danger"
                        style={{ width: "fit-content" }}
                      >
                        {t("reject")} <ion-icon name="close-outline"></ion-icon>
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Member;
