import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { withToastManager } from "react-toast-notifications";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import cs from "../../const";
import moment from "moment-timezone";
import DialogWrapper from "../shared/DialogWrapper";

const deleteUserURL = cs.BaseURL + "/api/user/delete";
const styles = theme => ({});


class DeleteUser extends React.Component {
    state = {
        confirm_code: ""
    };
    handleDialogAgree = () => {
        if (!this.props.match.params.id) {
            window.alert("Wrong information !");
            this.props.history.push({
                pathname: '/user',
                state: { reload: true }
            });
            return;
        }
        // if (this.state.confirm_code != "goldengate.biz.vn") {
        //     window.alert("Không có quyền truy cập !");
        //     this.props.history.push({
        //         pathname: "/campaign/all"
        //     });
        //     return;
        // }

        var id = this.props.match.params.id;
        let queryString = `${deleteUserURL}`;

        fetch(queryString, {
            method: "POST",
            body: JSON.stringify({
                id: id
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code) ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: "error",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add("AgentLocation is deleted !", {
                        appearance: "success",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/user',
                    state: { reload: true }
                });
            })
            .catch(() => { });
    };

    handleFieldChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => { }
        );
    };
    render() {
        const { classes } = this.props;

        return (
            <div>
                <DialogWrapper title={'Bạn có muốn xóa thông tin agent theo địa bàn này không?'} {...this.props}>
                    {/* <DialogContent>
                        <TextField
                            fullWidth
                            id="confirmation_code"
                            label="Confirmation"
                            type="search"
                            value={this.state.confirm_code}
                            onChange={this.handleFieldChange("confirm_code")}
                            margin="normal"
                            required={true}
                            type="password"
                            onKeyPress={ev => {
                                if (ev.key === "Enter") {
                                    this.handleDialogAgree();
                                    ev.preventDefault();
                                }
                            }}
                        />
                    </DialogContent> */}
                    <DialogActions>
                        <Button
                            onClick={this.props.history.goBack}
                            color="primary"
                        >
                            Hủy
                        </Button>
                        <Button
                            onClick={this.handleDialogAgree}
                            color="primary"
                            autoFocus
                        >
                            Xóa
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

DeleteUser.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(DeleteUser));
