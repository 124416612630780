import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import cs from "../../const";
import CourseMember from "./CourseMember";
import { useParams } from "react-router-dom";
import Pagination from "../shared/Pagination";
import { useTranslation, withTranslation } from "react-i18next";
import Pagin from "../shared/Pagin";
const URL = cs.BaseURL + "/api/course/trainee/list";
function CourseMemberList({ t, i18n }) {
  const { id } = useParams();
  const [Data, setData] = useState([]);

  const [postsPerPage, setpostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = Data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // console.log(currentPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const [currentPage, setCurrentPage] = useState(1);
  // const [postsPerPage, setPostsPerPage] = useState(20);

  // // Get current posts
  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = Data.slice(indexOfFirstPost, indexOfLastPost);

  // // Change page
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Read Function
  const readData = () => {
    axios({
      method: "get",
      url: `${URL}?course_id=${id}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    readData();
  }, []);

  return (
    <div className="card card-body shadow">
      <div className="page-title mb-3">
        {t("member-list")}
      </div>
      <div className="container">
        <h4 className="component-top-title" style={{ textAlign: "start" }}>
          {" "}
          {t("course.course-name")}:{" "}
          {Data[0] !== undefined ? Data[0].courseName : null}
        </h4>
        {currentPosts.map((data) => (
          <CourseMember
            traineeFullname={data.traineeFullname}
            traineeTelephone={data.traineeTelephone}
            traineeBranch={data.traineeBranch}
            learningStatus={data.learningStatus}
            traineeEmail={data.traineeEmail}
            traineeDepartment={data.traineeDepartment}
            traineeJobPosition={data.traineeJobPosition}
            id={data.id}
            registrationApproval={data.registrationApproval}
            t={t}
            i18n={i18n}
          />
        ))}
        <div className="d-flex justify-content-center">
          <Pagin
            ItemsPerPage={postsPerPage}
            paginate={paginate}
            totalItems={Data.length}
            currentPage={currentPage}
          />
        </div>
        {/* <Pagination
          currentPage={currentPage}
          postsPerPage={postsPerPage}
          totalPosts={Data.length}
          paginate={paginate}
        /> */}
      </div>
    </div>
  );
}

export default withTranslation()(CourseMemberList);
