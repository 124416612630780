import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const';
import moment from 'moment-timezone';
import DialogWrapper from '../shared/DialogWrapper';
import { Icon, List, ListItem, Typography, ListItemIcon, ListItemText, CircularProgress } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
// import { DropzoneArea } from 'material-ui-dropzone';

const courseURL = cs.BaseURL + '/api/trainer/courses/list';
const uploadMaterialsURL = cs.BaseURL + '/api/trainer/courses/materials/upload';

const hubName = ["Đồng bằng Bắc Bộ", "Đông Bắc Bộ", "Tây Bắc Bộ", "Bắc Trung Bộ", "Nam Trung Bộ", "Tây Nguyên", "Đông Nam Bộ", "Đồng bằng Sông Cửu Long", "Hà Nội", "TP.HCM"];

const endsWithAny = (suffixes, string) => {
    return suffixes.some(function (suffix) {
        return string.endsWith(suffix);
    });
}

const styles = theme => ({
    gridContainer: {
        paddingTop: 10
    },
    downloadContainer: {
        paddingTop: 10
    },
    dropZone: {
        paddingTop: 10,
        minHeight: 200
    },
    erItem: {
        secondary: 'pink'
    },
    statusItem: {
        marginTop: 5
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
});

function validatePhoneInput(phone) {
    const validHeadNums = /((086|096|097|098|032|033|034|035|036|037|038|039|088|091|094|083|084|085|081|082|092|056|058|089|090|093|070|079|077|076|078|099|059)+([0-9]{7})\b)/g;
    let validPhoneNumber = (String(phone).length === 10) && (validHeadNums.test(String(phone)));
    return (validPhoneNumber);
};

class CreateMaterial extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            courseList: [],
            courseId: this.props.match.params.course_id,
            lectureOrderNumber: this.props.match.params.lectureOrderNumber,
            materialDisplayedName: '',
            youtubeUrl: '',
            file: null,
            errors: [],
            isProcessing: false,
            isFileValid: false,
            isFileImportProcessed: false,
            isFileImported: false,
        }
        // if (!isTelesaleOP()) {
        //     window.location.href="/";
        //     return;
        // }

        this.loadCourses = this.loadCourses.bind(this);
        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
        this.loadCourses();
    }

    loadCourses() {
        console.log(this.state);
        let queryString = `${courseURL}?page=0&size=0`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    // data.data.unshift({ courseName: '--SELECT COURSE--', courseId: null });
                    this.setState({
                        courseList: data.data
                    });
                } else {
                    this.setState({
                        courseList: []
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    types: []
                });
            });
    }

    handleDropZoneChange(files) {
        const acceptedTypes = [
            ".jng",".jpg",".png",
            ".jpeg",".gif",
            '.mp4',
            '.pdf',
            '.doc', '.docx',
            ".xls", ".xlsx",
            ".csv", ".tsv",
            ".ppt",
            ".pptx", ".pages",
            ".odt", ".rtf"
        ];

        console.log('add file');
        if (files && files instanceof Array && files.length > 0) {
            console.log(files);
            this.setState({
                file: files[0],
                isProcessing: true,
                isFileValid: false,
                isFileImportProcessed: false,
                isFileImported: false,
                errors: []
            });
            // if (acceptedTypes.includes(files[0].type)) {
            if (endsWithAny(acceptedTypes,files[0].name)) {
                console.log(files[0].type, true);
                this.setState({
                    isProcessing: false,
                    isFileValid: true,
                    errors: []
                });
            } else {
                console.log(files[0].type, false);
                this.setState({
                    isProcessing: false,
                    isFileValid: false,
                    errors: []
                });
            }
        } else this.resetState();
    }

    resetState() {
        this.setState({
            file: null,
            errors: [],
            isProcessing: false,
            isFileValid: false,
            isFileImportProcessed: false,
            isFileImported: false
        });
    }

    handleChange = name => event => {
        if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {

                }
            );
    };

    handleDialogAgree = () => {
        console.log(this.state);

        const { file, youtubeUrl } = this.state
        if (!file && !youtubeUrl) {
            return alert("Invalid Request")
        }

        if (youtubeUrl) {
            if (!youtubeUrl.includes('https://www.youtube.com') && !youtubeUrl.includes('http://www.youtube.com')) {
                return alert("Invalid Request")
            }
        }

        if (file) {
            this.setState({
                isProcessing: true,
                isFileImportProcessed: false,
                isFileImported: false,
                errors: []
            });
        }

        let queryString = `${uploadMaterialsURL}`;

        const formData = new FormData();
        formData.append('courseId', this.state.courseId);
        formData.append('lectureOrderNumber', this.state.lectureOrderNumber);
        formData.append('materialDisplayedName', this.state.materialDisplayedName);
        if (file)
            formData.append('file', this.state.file);
        if (youtubeUrl)
            formData.append('url', this.state.youtubeUrl);

        fetch(queryString, {
            method: 'POST',
            body: formData,
            headers: { Authorization: localStorage.getItem("token") }
        })
            .then(response => response.json())
            .then(data => {
                // if (
                //     data &&
                //     (data.code == cs.erAuthenticationFailed.code ||
                //         data.code == cs.erAccountIsLocked.code)
                //  ) {
                //     localStorage.clear();
                //     sessionStorage.clear();
                //     window.location.reload();
                // }

                // var data = data.data;
                console.log(data);
                if (data && data.error_code != 0 && data.error_desc != "Success") {
                    if (file) {
                        this.setState({
                            isProcessing: false,
                            isFileImported: false,
                            // errors: formatErrors(data.errors)
                        });
                    }

                    if (youtubeUrl) {
                        this.props.toastManager.add('Error !', {
                            appearance: 'error',
                            autoDismiss: true,
                            pauseOnHover: true
                        });
                        this.handleClose();
                    }

                }
                else {
                    this.props.toastManager.add('Upload thành công !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });

                    this.handleClose();
                }
            })
            .catch(() => {
                this.resetState();
            });
    };

    handleClose = () => {
        this.resetState();
        // this.props.onClose();
        this.props.history.push({
            pathname: `/course_materials/${this.state.courseId}/${this.state.lectureOrderNumber}`,
            state: { reload: true }
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <DialogWrapper title={'Upload materials'} {...this.props}>
                    <DialogContent>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="merchantId">Course (*)</InputLabel>
                                    <Select
                                        value={this.state.courseId}
                                        onChange={this.handleChange('courseId')}
                                        inputProps={{
                                            name: 'package_type',
                                            id: 'package_type'
                                        }}
                                    >
                                        {/* <MenuItem value={null} disabled>--SELECT--</MenuItem> */}
                                        {this.state.courseList.map(item => (
                                            <MenuItem value={item.courseId}>{item.courseId}-{item.courseName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="lectureOrderNumber"
                                    required={true}
                                    value={this.state.lectureOrderNumber}
                                    onChange={this.handleChange('lectureOrderNumber')}
                                    label="lectureOrderNumber"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0, max: 128 } }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="materialDisplayedName"
                                    required={true}
                                    value={this.state.materialDisplayedName}
                                    onChange={this.handleChange('materialDisplayedName')}
                                    label="materialDisplayedName"
                                    type="text"
                                    InputProps={{ inputProps: { min: 0, max: 128 } }}
                                />
                                {!this.state.file && <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="youtubeUrl"
                                    required={true}
                                    value={this.state.youtubeUrl}
                                    onChange={this.handleChange('youtubeUrl')}
                                    label="Youtube URL"
                                    type="text"
                                    InputProps={{ inputProps: { min: 0, max: 128 } }}
                                />}
                            </Grid>
                        </Grid>

                        {/* <Grid
                            container
                            direction="row"
                            justify="fixed-start"
                            alignItems="stretch"
                            spacing={20}
                            className={classes.downloadContainer}
                            gutterBottom
                        >
                            <Grid item xs={7}>
                                <Typography variant="body1">Tải file mẫu :</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <a
                                    download="import-agents-template.zip"
                                    target="_blank"
                                    href={'/'}
                                    style={{ fontSize: 13, marginTop: 2 }}
                                >
                                    File mẫu
                                </a>
                            </Grid>
                        </Grid> */}


                        <Grid item>
                            <div>
                                <List>
                                    {this.state.errors.map((er, i) => {
                                        return (
                                            <ListItem
                                                style={{ paddingTop: 5, paddingBottom: 5 }}
                                            >
                                                <ListItemIcon
                                                    style={{ marginLeft: 0, marginRight: 0 }}
                                                >
                                                    <Icon
                                                        color="error"
                                                        style={{
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                            fontSize: 26
                                                        }}
                                                    >
                                                        error_outline
                                                    </Icon>
                                                </ListItemIcon>
                                                <ListItemText
                                                    classes={{
                                                        primary: { color: 'primary' },
                                                        secondary: { color: 'primary' }
                                                    }}
                                                    secondary={er.error}
                                                />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </div>
                        </Grid>

                        <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="stretch"
                            spacing={1}
                            className={classes.gridContainer}
                        >
                            {this.state.isProcessing && (
                                <div style={{ height: 150 }}>
                                    <CircularProgress
                                        style={{
                                            position: 'relative',
                                            left: '45%',
                                            top: '70px'
                                        }}
                                    />
                                </div>
                            )}
                            {!this.state.isProcessing && !this.state.youtubeUrl && (
                                <Grid item gutterBottom>
                                    <DropzoneArea
                                        // acceptedFiles={[
                                        //     'application/vnd.ms-excel',
                                        //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                        // ]}
                                        // acceptedFiles={"image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"}
                                        acceptedFiles={[
                                            'video/*',
                                            'image/*',
                                            'application/pdf',
                                            '.doc', '.docx',
                                            ".xls", ".xlsx",
                                            ".csv", ".tsv",
                                            ".ppt",
                                            ".pptx", ".pages",
                                            ".odt", ".rtf",
                                            ".txt"
                                        ]}
                                        dropzoneText={
                                            this.state.file && this.state.file.name
                                                ? this.state.file.name
                                                : 'Upload file'
                                        }
                                        maxFileSize={50000000}
                                        filesLimit={1}
                                        showAlerts={false}

                                        onChange={this.handleDropZoneChange}
                                        dropZoneClass={classes.dropZone}

                                        showPreviews={true}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        // previewChipProps={{ classes: { root: classes.previewChip } }}
                                        previewText="Selected files"
                                        
                                    />
                                </Grid>
                            )}


                            <Grid item gutterBottom className={classes.statusItem}>
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    this.state.isFileValid && (
                                        <Typography variant="body1">File hợp lệ !</Typography>
                                    )}{' '}
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    this.state.isFileImportProcessed &&
                                    !this.state.isFileValid && (
                                        <Typography variant="body1">File không hợp lệ !</Typography>
                                    )}
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    this.state.isFileImportProcessed &&
                                    this.state.isFileImported && (
                                        <Typography variant="body1">
                                            File import thành công !
                                        </Typography>
                                    )}
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    this.state.isFileImportProcessed &&
                                    !this.state.isFileImported && (
                                        <Typography variant="body1">
                                            File import thất bại !
                                        </Typography>
                                    )}
                            </Grid>

                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Hủy
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Tạo mới
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

CreateMaterial.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(CreateMaterial));
