import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Pagination from "../shared/Pagination";
import Question from "./Question";
import { useTranslation, withTranslation } from "react-i18next";
import "./Exam.css";
import cs from "../../const";
import axios from "axios";
const URL = cs.BaseURL + "/api/trainer/test-exam/start-trial";

function MockExam({ t, i18n }) {
  let { course_id, testOrderNumber } = useParams();

  const [posts, setPosts] = useState([]);
  const [answers, setAnswers] = useState({});

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(1);
  const isDark = localStorage.getItem("aidriven-general-theme");

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const queryString = () => {
    let queryString = `${URL}?courseId=${course_id}&testOrderNumber=${testOrderNumber}`;
    return queryString;
  };

  const loadData = async (conditions) => {
    const querystring = queryString();

    const response = await axios({
      method: "get",
      url: `${querystring}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    setPosts(
      response.data.data.sort(function () {
        return 0.5 - Math.random();
      })
    );
    if (response.data.data.length != 0) {
      let a = response.data.data[0].totalTime;
      const initialHour = Math.floor(a / 60);
      const initialMinute = Math.floor(a % 60);
      const initialSecond = 0;
      setTime([initialSecond, initialMinute, initialHour]);
    }
  };

  const [testInfor, setTestInfor] = useState({});
  const readInfor = () => {
    axios({
      method: "GET",
      url: `${cs.BaseURL}/api/trainer/test-exam/detail?courseId=${course_id}&testOrderNumber=${testOrderNumber}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: null,
    })
      .then((response) => {
        if (response.data.error_desc == "Success")
          setTestInfor(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Count Down Clock

  const [[seconds, minutes, hours], setTime] = useState([]);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setTime([seconds - 1, minutes, hours]);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(myInterval);
          } else {
            setTime([59, 59, hours - 1]);
          }
        } else {
          setTime([59, minutes - 1, hours]);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    loadData();
    readInfor();
    return () => {};
  }, []);

  const handleChange = (q, a) => {
    setAnswers({
      ...answers,
      [q]: a,
    });
  };
  let type = "mock";
  function SubmitAnswer() {
    setOpen(false);
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (hours === 0 && minutes === 0 && seconds === 0) {
    SubmitAnswer();
    window.history.back();
  }
  return (
    <div className="container mt-1">
      <div className="row">
        <div className="col-9 col__main">
          <div className="row row__top">
            <h3
              className="text-primary card-title mb-3"
              style={{ width: "fit-content" }}
            >
              {t("exam")}: {testInfor.testName}
            </h3>
            <div className="countdown__clock mb-3">
              <img
                style={{
                  width: "40px",
                  height: "40px",
                  marginRight: "10px",
                }}
                src="https://cdn-icons-png.flaticon.com/512/850/850960.png"
                alt="timer"
              />
              {hours === 0 && minutes === 0 && seconds === 0 ? (
                <h2>{"time-up"}</h2>
              ) : (
                <h2>
                  {" "}
                  {hours < 10 ? `0${hours}` : hours}:
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </h2>
              )}
            </div>
          </div>

          <div className="row row_question">
            <div className="col-1 col_btn_pagination">
              {hours === 0 && minutes === 0 && seconds === 0 ? null : (
                <button
                  className="btn btn-outline-none btn__pagination btn__pagination__prev"
                  onClick={() => {
                    if (currentPage !== 1) {
                      setCurrentPage(currentPage - 1);
                    } else {
                      setCurrentPage(posts.length);
                    }
                  }}
                >
                  <img
                    className="img__pagination"
                    src="https://cdn-icons-png.flaticon.com/512/151/151846.png"
                    alt="btn-prev"
                  />
                </button>
              )}
            </div>
            <div className="col-10">
              <Question
                posts={posts}
                currentPost={currentPosts}
                length={posts.length}
                loading={loading}
                currentPage={currentPage}
                open={open}
                handleClose={handleClose}
                handleChange={handleChange}
                answers={answers}
                submitAnswer={SubmitAnswer}
                type={type}
                i18n={i18n}
                t={t}
              />
            </div>

            <div className="col-1 col_btn_pagination">
              {hours === 0 && minutes === 0 && seconds === 0 ? null : (
                <button
                  className="btn btn-outline-none btn__pagination btn__pagination-__next"
                  onClick={() => {
                    if (currentPage !== posts.length) {
                      setCurrentPage(currentPage + 1);
                    } else {
                      setCurrentPage(1);
                    }
                  }}
                >
                  <img
                    className="img__pagination"
                    src="https://cdn-icons-png.flaticon.com/512/151/151858.png"
                    alt="btn-next"
                  />
                </button>
              )}
            </div>
          </div>
          <div
            className="row row_submit"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <button
              type="button"
              style={{
                width: "fit-content",
                marginRight: "10%",
              }}
              className="btn btn-success"
              onClick={handleClickOpen}
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
            >
              {t("submit-exam")}
            </button>

            {/* Modal */}
            {/* <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Notice
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <h6 style={{ color: "black" }}>Are you sure to submit?</h6>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-info"
                      onClick={handleClickOpen}
                    >
                      Nộp bài
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {hours === 0 && minutes === 0 && seconds === 0 ? null : (
          <div className="col-3">
            <div
              class="card card__pagination"
              style={
                isDark == "true"
                  ? { backgroundColor: "#5E5959" }
                  : { backgroundColor: "white" }
              }
            >
              <div class="card-body">
                <h5 class="card-title">{t("question")}</h5>
                <Pagination
                  isDark={isDark}
                  currentPage={currentPage}
                  postsPerPage={postsPerPage}
                  totalPosts={posts.length}
                  paginate={paginate}
                  course_id={course_id}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withTranslation()(MockExam);
