import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { withToastManager } from "react-toast-notifications";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from '@material-ui/core/Checkbox';
import cs from "../../const";
import moment from "moment-timezone";
import DialogWrapper from "../shared/DialogWrapper";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation, withTranslation } from 'react-i18next';

// import { DropzoneArea } from 'material-ui-dropzone';

const URL = cs.BaseURL + '/api/user/list';
const updateUserURL = cs.BaseURL + "/api/user/update";


const hubName = ["Đồng bằng Bắc Bộ", "Đông Bắc Bộ", "Tây Bắc Bộ", "Bắc Trung Bộ", "Nam Trung Bộ", "Tây Nguyên", "Đông Nam Bộ", "Đồng bằng Sông Cửu Long", "Hà Nội", "TP.HCM"];

const styles = theme => ({
    locked_button: {
        marginTop: 10,
        marginLeft: 0
    },
    dropZone: {
        paddingTop: 10,
        minHeight: 200
    },
    gridContainer: {
        paddingTop: 10
    },
    downloadContainer: {
        paddingTop: 10
    },

});

function formatValue(value) {
    // format display of time
    // if (value && value.startDate)
    //     value.startDateStr = moment(value.startDate)
    //         .tz('Asia/Ho_Chi_Minh')
    //         .format('YYYY-MM-DD');

    if (value && value.startDate)
        value.startDateStr = moment(value.startDate)
            .tz('Asia/Ho_Chi_Minh')
            .format('YYYY-MM-DDTHH:mm');

    // if (value && value.startDate)
    //     value.startDateStr = moment(value.startDate).toISOString();

    // if (value && value.endDate)
    //     value.endDateStr = moment(value.endDate)
    //         .tz('Asia/Ho_Chi_Minh')
    //         .format('YYYY-MM-DD');

    if (value && value.endDate)
        value.endDateStr = moment(value.endDate)
            .tz('Asia/Ho_Chi_Minh')
            .format('YYYY-MM-DDTHH:mm');

    // format short forms
    if (value && value.current_province) value.current_province = value.current_province.replace('Thành phố', 'TP').replace('Tỉnh', '');


    return value;
}

class SubjectDetail extends React.Component {

    state = {
        id: this.props.match.params.id,

        data: formatValue(this.props.location.state.data)
    };
    constructor(props) {

        super(props);
        console.log("SubjectDetail", this.state.data);
        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        if (!this.state.data) {
            this.props.history.push({
                pathname: '/my-courses',
                state: { reload: true }
            });
        }
        // this.loadData();
    }

    queryString() {
        const { id } = this.state;
        let queryString = `${URL}?id=${id}`;
        return queryString;
    }

    loadData() {
        // if (!isTelesaleOP()) {
        //     window.location.href="/";
        //     return;
        // }
        this.setState({ id: this.props.match.params.id, loading: true });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.data);
                if (data && data.data[0]) {
                    let tmp = formatValue(data.data[0]);
                    this.setState({
                        // package_name: tmp.package_name,
                        // package_type: tmp.package_type,
                        // amount: tmp.amount,
                        agent_id: tmp.agent_id,
                        hub: tmp.hub,
                        agent_phone: tmp.agent_phone,
                        agent_name: tmp.agent_name,
                        adr_state: tmp.adr_state,
                        adr_state_code: tmp.adr_state_code,
                        adr_district: tmp.adr_district,
                        adr_district_code: tmp.adr_district_code,
                        adr_ward: tmp.adr_ward,
                        adr_ward_code: tmp.adr_ward_code,
                    },
                        () => {
                            this.loadStates();
                            this.loadDistricts();
                            this.loadWards();
                            this.setState({
                                adr_state: tmp.adr_state,
                                adr_state_code: tmp.adr_state_code,
                                adr_district: tmp.adr_district,
                                adr_district_code: tmp.adr_district_code,
                                adr_ward: tmp.adr_ward,
                                adr_ward_code: tmp.adr_ward_code,
                            })
                        }
                    );

                    console.log("state", this.state)
                } else {
                    this.setState({
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        // sessionStorage.clear();
                        // localStorage.clear();
                        // window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleDialogAgree = () => {
        
    };

    handleChange = name => event => {
        if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {

                }
            );
    };

    handleDropZoneChange(files) {
        this.setState({
            file: files[0]
        });
    }

    render() {
        console.log(this.state.data);
        const { classes, t, i18n } = this.props;
        const { data: {
            averageTraineeFinalExamScore,
            broadcastAllCompany,
            broadcastToBranchCode,
            broadcastToDepartmentCode,
            courseId,
            courseName,
            domainId,
            domainName,
            endDate,
            endDateStr,
            id,
            location,
            mcCode,
            moduleDescription,
            moduleName,
            numberOfApprovedTrainees,
            numberOfCompletedTrainees,
            numberOfRegisteredTrainees,
            requireRegistration,
            scheduleInfo,
            startDate,
            startDateStr,
            trainerCode,
            trainerFullname,
            trainerTelephone,
            watchOnlyVideosToComplete
        } } = this.state;
        return (
            <div>
                <DialogWrapper width={'md'} title={t("course-details.title")} {...this.props}>
                    <DialogContent >
                        <label>{t("course-details.module.title")}</label>
                        <div>
                            {/* <TextField

                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="id"
                                required={true}
                                value={id}
                                // onChange={this.handleChange('agent_name')}
                                label="id"
                                type="name"
                                style={{ marginLeft: 15 }}
                            /> */}

                            <TextField

                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="moduleName"
                                required={true}
                                value={moduleName}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.module.moduleName")}
                                type="name"
                                style={{ marginLeft: 15 }}
                            />

                            <TextField

                                fullWidth
                                multiline
                                // autoFocus
                                margin="dense"
                                id="moduleDescription"
                                required={true}
                                value={moduleDescription}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.module.moduleDescription")}
                                type="name"
                                style={{ marginLeft: 15, paddingRight: 15 }}
                            />

                            <TextField
                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="broadcastToBranchCode"
                                required={true}
                                value={broadcastToBranchCode}
                                // onChange={this.handleChange('agent_name')}
                                label="broadcastToBranchCode"
                                type="name"
                                style={{ marginLeft: 15 }}
                            />

                            <TextField
                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="broadcastToDepartmentCode"
                                required={true}
                                value={broadcastToDepartmentCode}
                                // onChange={this.handleChange('agent_name')}
                                label="broadcastToDepartmentCode"
                                type="name"
                                style={{ marginLeft: 15 }}
                            />

                            <TextField
                                fullWidth
                                // autoFocus
                                margin="dense"
                                id="location"
                                required={true}
                                value={location}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.module.location")}
                                type="name"
                                style={{ marginLeft: 15, paddingRight:15 }}
                            />

                            <TextField
                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="mcCode"
                                required={true}
                                value={mcCode}
                                // onChange={this.handleChange('agent_name')}
                                label="mcCode"
                                type="name"
                                style={{ marginLeft: 15 }}
                            />

                            <TextField
                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="scheduleInfo"
                                required={true}
                                value={scheduleInfo}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.module.scheduleInfo")}
                                type="name"
                                style={{ marginLeft: 15 }}
                            />
                        </div>

                        <div>
                            <TextField

                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="startDateStr"
                                required={true}
                                value={startDateStr}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.module.startDateStr")}
                                type="datetime-local"
                                style={{ marginLeft: 15 }}
                            />

                            <TextField

                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="endDateStr"
                                required={true}
                                value={endDateStr}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.module.endDateStr")}
                                type="datetime-local"
                                style={{ marginLeft: 15 }}
                            />


                        </div>

                        {/* <hr/> */}
                        <div>
                            <FormControlLabel
                                style={{ marginLeft: 15 }}
                                control={
                                    <Checkbox
                                        checked={requireRegistration && requireRegistration != 0}
                                    // onChange={this.handleCancelReasonChange(
                                    //     item.value
                                    // )}
                                    // value={item.selected}
                                    />
                                }
                                label={t("course-details.module.requireRegistration")}
                            />

                            <FormControlLabel
                                style={{ marginLeft: 15 }}
                                control={
                                    <Checkbox
                                        checked={watchOnlyVideosToComplete && watchOnlyVideosToComplete != 0}
                                    // onChange={this.handleCancelReasonChange(
                                    //     item.value
                                    // )}
                                    // value={item.selected}
                                    />
                                }
                                label={t("course-details.module.watchOnlyVideosToComplete")}
                            />

                            <FormControlLabel
                                style={{ marginLeft: 15 }}
                                control={
                                    <Checkbox
                                        checked={broadcastAllCompany && broadcastAllCompany != 0}
                                    // onChange={this.handleCancelReasonChange(
                                    //     item.value
                                    // )}
                                    // value={item.selected}
                                    />
                                }
                                label={t("course-details.module.broadcastAllCompany")}
                            />
                        </div>

                        <hr />
                        <label>{t("course-details.trainer.title")}</label>
                        <div>
                            <TextField

                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="trainerFullname"
                                required={true}
                                value={trainerFullname}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.trainer.trainerFullname")}
                                type="name"
                                style={{ marginLeft: 15 }}
                            />

                            <TextField
                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="trainerCode"
                                required={true}
                                value={trainerCode}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.trainer.trainerCode")}
                                type="name"
                                style={{ marginLeft: 15 }}
                            />

                            <TextField

                                // fullWidth
                                margin="dense"
                                id="trainerTelephone"
                                required={true}
                                value={trainerTelephone}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.trainer.trainerTelephone")}
                                type="name"
                                style={{ marginLeft: 15 }}
                            />
                        </div>

                        <hr />
                        <label>{t("course-details.trainee.title")}</label>
                        <div>
                            <TextField

                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="numberOfApprovedTrainees"
                                required={true}
                                value={numberOfApprovedTrainees}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.trainee.numberOfApprovedTrainees")}
                                type="number"
                                style={{ marginLeft: 15 }}
                            />

                            <TextField

                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="numberOfCompletedTrainees"
                                required={true}
                                value={numberOfCompletedTrainees}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.trainee.numberOfCompletedTrainees")}
                                type="number"
                                style={{ marginLeft: 15 }}
                            />

                            <TextField

                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="numberOfRegisteredTrainees"
                                required={true}
                                value={numberOfRegisteredTrainees}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.trainee.numberOfRegisteredTrainees")}
                                type="number"
                                style={{ marginLeft: 15 }}
                            />

                            <TextField

                                // fullWidth
                                // autoFocus
                                margin="dense"
                                id="averageTraineeFinalExamScore"
                                required={true}
                                value={averageTraineeFinalExamScore}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.trainee.averageTraineeFinalExamScore")}
                                type="number"
                                style={{ marginLeft: 15 }}
                            />
                        </div>

                        <hr />
                        <label>{t("course-details.domain.title")}</label>
                        <div>
                            {/* <TextField

                                // fullWidth
                                margin="dense"
                                id="domainId"
                                required={true}
                                value={domainId}
                                // onChange={this.handleChange('agent_name')}
                                label="domainId"
                                type="name"
                                style={{ marginLeft: 15 }}
                            /> */}

                            <TextField

                                // fullWidth
                                margin="dense"
                                id="domainName"
                                required={true}
                                value={domainName}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.domain.domainName")}
                                type="name"
                                style={{ marginLeft: 15 }}
                            />
                        </div>

                        <hr />
                        <label>{t("course-details.course.title")}</label>

                        <div>
                            {/* <TextField
                                // fullWidth
                                margin="dense"
                                id="courseId"
                                required={true}
                                value={courseId}
                                // onChange={this.handleChange('agent_name')}
                                label="courseId"
                                type="name"
                                style={{ marginLeft: 15 }}
                            /> */}

                            <TextField
                                // fullWidth
                                margin="dense"
                                id="courseName"
                                required={true}
                                value={courseName}
                                // onChange={this.handleChange('agent_name')}
                                label={t("course-details.course.courseName")}
                                type="name"
                                style={{ marginLeft: 15 }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.props.history.goBack}
                            color="primary"
                        >
                            {t("commons.button.cancel")}
                        </Button>
                        <Button
                            onClick={this.handleDialogAgree}
                            color="primary"
                        >
                            {t("commons.button.ok")}
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

SubjectDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(withTranslation()(SubjectDetail)));
