import axios from "axios";
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation, withTranslation } from "react-i18next";
import cs from "../../const";
import "./AnswerList.css";
import { useParams } from "react-router-dom";
const URL = cs.BaseURL + "/api/trainer/trainee/exam-answer/list";
const URL_D = cs.BaseURL + "/api/trainer/question/detail?";
function AnswerList({ t, i18n }) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const isDark = localStorage.getItem("aidriven-general-theme");
  const endpoint = useParams();
  //Khởi tạo
  const [course_id, setCourseId] = useState();
  const [trainee, setTrainee] = useState();
  const [myChoice, setMyChoice] = useState();
  const [testInfor, setTestInfor] = useState({});

  // đọc chi tiết từng câu hỏi
  const [question, setQuestion] = useState({});

  //url
  const queryString = `${URL}?course_id=${endpoint.course_id}&test_order_number=${endpoint.testOrderNumber}&trainee_code=0934431178`;

  //mảng data câu trả lời
  const [data, setData] = useState([
    {
      courseName: "",
    },
  ]);

  //Trạng thái đúng sai
  let count = 0;
  const State = (a) => {
    if (a === 1) {
      count = count + 1;
      return (
        <span
          style={{ width: "60px", height: "30px", fontSize: "15px" }}
          className="badge bg-success "
        >
          {t("true")}
        </span>
      );
    } else {
      return (
        <span
          style={{ width: "60px", height: "30px", fontSize: "15px" }}
          className="badge bg-danger"
        >
          {t("false")}
        </span>
      );
    }
  };
  const AnswerChoice = (a, b, c, d) => {
    if (a == c && b == c)
      return (
        <span className="badge bg-success mb-2">
          {c}: {d}
        </span>
      );
    else if (a != c && b == c)
      return (
        <span className="badge bg-danger mb-2">
          {c}: {d}
        </span>
      );
    else if (a != c && b != c)
      return (
        <span className="badge bg-primary mb-2">
          {c}: {d}
        </span>
      );
    else if (a == c && b != c)
      return (
        <span className="badge bg-success mb-2">
          {c}: {d}
        </span>
      );
  };
  const readData = () => {
    axios({
      method: "GET",
      url: `${queryString}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: null,
    })
      .then((response) => {
        setCourseId(response.data.data[0].courseId);
        setTrainee(response.data.data[0].traineeFullname);
        setData(response.data.data);
        console.log("data", response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const readInfor = () => {
    axios({
      method: "GET",
      url: `${cs.BaseURL}/api/trainer/test-exam/detail?courseId=${endpoint.course_id}&testOrderNumber=${endpoint.testOrderNumber}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: null,
    })
      .then((response) => {
        if (response.data.error_desc == "Success")
          setTestInfor(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Hàm đọc chi tiết câu trả lời
  function AnswerDetail(questionId) {
    axios({
      method: "GET",
      url: `${URL_D}question_id=${questionId}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: null,
    }).then((response) => {
      if (response.data.data && response.data.error_desc == "Success") {
        setQuestion(response.data.data);
      }
    });
  }
  useEffect(() => {
    readData();
    readInfor();
  }, []);
  // for (let i = 1; i < data.length; i++) {
  //   AnswerDetail(data[i].questionId);
  // }

  return (
    <div className="card card-body shadow pb-5">
      <h3 className="page-title" style={{ textAlign: "center" }}>
        {t("course.course")}: {data[0].courseName}
      </h3>
      <h4
        className="card-title"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        {t("exam")}: {data[0].testName}
      </h4>
      {/* <h6 className="card-title" style={{ textAlign: "center" }}>
        <i>
          {t("testDate")}: {data[0].testDate}
        </i>
      </h6> */}

      <h5 className="card-title" style={{ textAlign: "left" }}>
        {t("trainee")}: {trainee}
      </h5>

      <table
        className="table table-bordered table-hover table-striped"
        style={
          isDark == "true"
            ? { backgroundColor: "#837C7C" }
            : { backgroundColor: "white" }
        }
      >
        <thead
          style={{
            backgroundColor: "#F69756",
            fontSize: "15px",
            color: "black",
          }}
        >
          <tr>
            <th className="col-index text-center" scope="col">
              {t("index")}
            </th>
            <th className="col-question text-center" scope="col">
              {t("question")}
            </th>
            <th className="col-your-answer text-center" scope="col">
              {t("your-answer")}
            </th>
            <th className="col-correct-answer text-center" scope="col">
              {t("correct-answer")}
            </th>
            <th className="col-state text-center" scope="col">
              {t("state")}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              <tr>
                <th
                  className="text-center"
                  style={{ width: "60px", backgroundColor: "#eee" }}
                >
                  {index + 1}
                </th>
                <td>{row.questionContent}</td>
                <td>{row.traineeAnswerChoice}</td>
                <td>{row.correctAnswerName}</td>
                <td className="d-flex justify-content-around">
                  {State(row.answerCorrectly)}
                  <img
                    onClick={() => {
                      AnswerDetail(row.questionId);
                      handleClickOpen();
                      setMyChoice(row.traineeAnswerChoice);
                    }}
                    style={{ width: "30px" }}
                    src="https://cdn-icons-png.flaticon.com/512/1265/1265775.png"
                    alt="detailIcon"
                  />
                </td>
              </tr>
            );
          })}

          <tr>
            <th>Score</th>
            <td colSpan="4" style={{ textAlign: "left" }}>
              <b>{parseFloat((count / data.length) * 10).toFixed(2)} / 10.00</b>
            </td>
          </tr>
        </tbody>
      </table>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontSize: "15px" }}>
          <u>
            <b>{t("question")}:</b>
          </u>{" "}
          {question.questionContent}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Are you want to start?
          </DialogContentText> */}
          <div className="question-choice d-block">
            <h5 className="choiceA">
              {AnswerChoice(
                question.correctAnswerName,
                myChoice,
                "A",
                question.choiceA
              )}
            </h5>
            <h5 className="choiceB">
              {AnswerChoice(
                question.correctAnswerName,
                myChoice,
                "B",
                question.choiceB
              )}
            </h5>
            <h5 className="choiceC">
              {AnswerChoice(
                question.correctAnswerName,
                myChoice,
                "C",
                question.choiceC
              )}
            </h5>
            <h5 className="choiceD d-inline">
              {AnswerChoice(
                question.correctAnswerName,
                myChoice,
                "D",
                question.choiceD
              )}
            </h5>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withTranslation()(AnswerList);
