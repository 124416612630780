import React, { useEffect, useState } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import { Link } from "react-router-dom";
import "./Courses.css";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation, withTranslation } from "react-i18next";
import axios from "axios";
import cs from "../../const";

const coursesRegistrationUrl = cs.BaseURL + "/api/trainee/course/register";
const getCoursesListUrl = cs.BaseURL + "/api/trainee/course/list";
const getModuleListUrl = cs.BaseURL + "/api/module/list";
const getDomainListUrl = cs.BaseURL + "/api/module/domain-list";
const getTrainerListUrl = cs.BaseURL + "/api/trainer/list";

const STATUS_DESC = [
  { code: -1, status: "all" },
  { code: 0, status: "unregistered" },
  { code: 1, status: "registered" },
]

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RegisterCourseList = ({ t, i18n }) => {
  const [statusFilter, setStatusFilter] = useState(STATUS_DESC.find(element => element.status === "all").code || "")
  //response message
  const [responseMessage, setResponseMessage] = useState({ type: "", content: "" });
  const [openMessage, setOpenMessage] = useState(false);

  const handleRegistrationClick = async (regisId) => {
    try {
      const response = await axios({
        method: "post",
        url: `${coursesRegistrationUrl}`,
        headers: {
          Authorization: localStorage.getItem("token")
        },
        data: {
          course_id: regisId,
        }
      })
      if (response.data.error_desc === "Success") {
        setResponseMessage({ type: "success", content: t("course.response-message.submit-success") });
        setOpenMessage(true);
        getCoursesData();
        //handleCloseDelModal();
      } else {
        setResponseMessage({ type: "error", content: response.data.error_desc });
        setOpenMessage(true);
      }
    } catch (error) {
      console.log(error)
    }
  }

  //load data
  const [domainList, setDomainList] = useState([]);
  const [trainerList, setTrainerList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [moduleList, setModuleList] = useState([]);

  const getCoursesData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getCoursesListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
      console.log("course", response.data);
      setCoursesList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getModuleData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getModuleListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
      // console.log("module", response.data);
      setModuleList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getModuleName = (id) => {
    let moduleName = "";
    moduleList.forEach(item => {
      if (item.id == id) {
        moduleName = item.moduleName;
        return false;
      }
    })
    return moduleName;
  }

  const getTrainerData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getTrainerListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
      console.log("trainer", response.data);
      setTrainerList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getTrainerName = (id) => {
    let trainerName = "";
    moduleList.forEach(item => {
      if (item.id == id) {
        trainerName = item.trainerFullname;
        return false;
      }
    })
    return trainerName;
  }

  const getTrainerId = (trainerCode) => {
    let trainerId = "";
    // console.log("trainercode", trainerCode);
    trainerList.forEach(item => {
      // console.log(item.trainerCode);
      if (item.trainerCode == trainerCode) {
        trainerId = item.id;
        return false;
      }
    })
    return trainerId;
  }

  const getDomainData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getDomainListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
      console.log("domain", response.data);
      setDomainList(response.data.data);
    } catch (error) {
      console.log(error)
    }
  }

  //control domainlist
  const getDomainEngName = (domainID) => {
    // console.log(domainID);
    let vieName = "";
    domainList.forEach(item => {
      // console.log(item)
      if (item.domainId == domainID) {
        vieName = item.domainEngName;
        return false;
      }
    })
    return vieName;
  }

  useEffect(() => {
    getDomainData();
    getTrainerData();
    getModuleData();
    getCoursesData();
  }, [statusFilter])

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMessage(false);
  };

  return (
    <ThemeContext.Consumer>
      {
        ({ isDark }) => {
          return <div className={isDark ? "card dark-background-1" : "card"}>
            <div className="card-body shadow">
              <div className="d-flex align-items-baseline">
                <div className="page-title mb-3">{t("course.register-course")}</div>
                <div className="ms-auto d-flex align-items-center">
                  <select
                    className="form-select form-select-sm ms-2"
                    aria-label="Default select example"
                    name="statusFilter"
                    value={statusFilter}
                    onChange={(event) => setStatusFilter(event.target.value)}
                    style={{ width: "165px" }}
                  >
                    {STATUS_DESC.map(element => <option value={element.code}>{t(`course.${element.status}`)}</option>)}
                  </select>
                </div>
              </div>
              {
                coursesList.map(item => {
                  if (statusFilter == STATUS_DESC.find(element => element.status === "all").code || statusFilter == item.isRegisted)
                    return (
                      <div className={isDark ? "card card-body mb-2 shadow-sm dark-background-2" : "card card-body mb-2 shadow-sm"}>
                        <h5 className="card-title">{item.courseName}</h5>
                        <div className="container-fluid p-0">
                          <div className="row row-cols-1 row-cols-sm-2">
                            <div>
                              {t("module.module")}: <Link to="#" className="stretched-link" style={{ position: "relative", textTransform: "capitalize" }}>{item.moduleName}</Link>
                            </div>
                            <div>
                              {t("domain.domain")}: <Link to="#" className="stretched-link" style={{ position: "relative" }}>{t(`domain.name.${item.domainName}`)}</Link>
                            </div>
                          </div>
                          <div className="row row-cols-1 row-cols-sm-2">
                            <div className="col">{t("expert")}: <Link to="#" className="stretched-link" style={{ position: "relative", textTransform: "capitalize" }}>{item.trainerFullname.toLowerCase()}</Link></div>
                            <div className="col">{t("phone")}: {item.trainerTelephone}</div>
                          </div>
                          <div className="row row-cols-1 row-cols-sm-2">
                            <div className="col">{t("from")}: {item.startDate.slice(8, 10) + "-" + item.startDate.slice(5, 7) + "-" + item.startDate.slice(0, 4)}</div>
                            <div className="col">{t("to")}: {item.endDate.slice(8, 10) + "-" + item.endDate.slice(5, 7) + "-" + item.endDate.slice(0, 4)}</div>
                          </div>
                          <div>
                            {t("location")}: {item.location || "Online"}
                          </div>
                          <div className="row row-cols-1 row-cols-sm-2">
                            <div className="col">
                              {t("course.numberOfRegisteredTrainees")}: {item.numberOfRegisteredTrainees}
                            </div>
                            <div className="col">
                              {t("course.numberOfApprovedTrainees")}: {item.numberOfApprovedTrainees}
                            </div>
                            <div className="col">
                              {t("course.numberOfCompletedTrainees")}: {item.numberOfCompletedTrainees}
                            </div>
                          </div>
                          <div className="text-align-justify">{t("description")}: {item.moduleDescription}</div>
                          <div className="d-flex justify-content-end align-items-baseline">
                            <button
                              className="btn stretched-link btn-success"
                              style={{ position: "relative" }}
                              onClick={() => handleRegistrationClick(item.courseId)}
                              disabled={item.isRegisted}
                            >
                              {/* {
                              isDark
                                ? <img src="https://img.icons8.com/material-rounded/30/ffffff/edit-user-male.png" />
                                : <img src="https://img.icons8.com/material-rounded/30/000000/edit-user-male.png" />
                            } */}
                              {item.isRegisted ? t("course.registered") : t("course.register")}
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                })
              }
            </div>

            {/* modal */}
            <Snackbar open={openMessage} autoHideDuration={1500} onClose={handleCloseMessage}>
              <Alert onClose={handleCloseMessage} severity={responseMessage.type}>
                {responseMessage.content}
              </Alert>
            </Snackbar>
          </div>
        }
      }
    </ThemeContext.Consumer>
  )
}

export default withTranslation()(RegisterCourseList);