import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import React from 'react';
import Icon from '@material-ui/core/Icon';
import cs from '../const.js';
import { isManagementManager, isManagementOfficer, isTrainee, isTrainer } from '../service.js';
// import { getRole } from 'service.js';

const manager_menu = [
    {
        icon: <Icon color="disable">local_library</Icon>,
        // title: "Quản lý đào tạo",
        title: 'nested_list.manager_menu.title',
        items: [
            {
                icon: <Icon color="disable">book</Icon>,
                // title: 'Quản lý chuyên gia',
                title: 'nested_list.manager_menu.expert_list',
                to: '/expert_list',
                items: []
            },
            {
                icon: <Icon color="disable">book</Icon>,
                // title: 'Quản lý môn học',
                title: 'nested_list.manager_menu.subjects-list',
                to: '/subjects-list',
                items: []
            },

            {
                icon: <Icon color="disable">book</Icon>,
                // title: 'Quản lý khóa học',
                title: 'nested_list.manager_menu.courses-list',
                to: '/courses-list',
                items: []

            },
            {
                icon: <Icon color="disable">book</Icon>,
                // title: 'Quản lý học viên',
                title: 'nested_list.manager_menu.approve_list',
                to: '/approve_list',
                items: []
            },
        ]
    },
];

const trainer_menu = [
    {
        icon: <Icon color="disable">assignment_ind</Icon>,
        // title: "Chuyên gia",
        title: 'nested_list.trainer_menu.title',
        items: [
            {
                icon: <Icon color="disable">book</Icon>,
                // title: 'Quản lý khóa học',
                title: 'nested_list.trainer_menu.my-courses',
                to: '/my-courses',
                items: []
            },
            {
                icon: <Icon color="disable">help</Icon>,
                // title: 'Ngân hàng câu hỏi',
                title: 'nested_list.trainer_menu.question-bank',
                to: '/question-bank',
                items: []
            },
        ]
    },
];

const trainee_menu = [
    {
        icon: <Icon color="disable">assignment</Icon>,
        // title: "Học viên",
        title: 'nested_list.trainee_menu.title',
        items: [
            {
                icon: <Icon color="disable">book</Icon>,
                // title: 'Đăng ký khóa học',
                title: 'nested_list.trainee_menu.register-course',
                to: '/register-course',
                items: []
            },
            {
                icon: <Icon color="disable">book</Icon>,
                // title: 'Khóa học của tôi',
                title: 'nested_list.trainee_menu.my-courselist',
                to: '/my-courselist',
                items: []
            },
        ]
    },
];

const general_menu = [
    {
        icon: <Icon color="disable">build</Icon>,
        // title: 'Cài đặt',
        title: 'nested_list.general_menu.settings',
        to: '/settings',
        items: []
    },
];

export const menu = (isManagementOfficer() || isManagementManager())
    ? manager_menu.concat(
        isTrainer()
            ? trainer_menu.concat(isTrainee() ? trainee_menu.concat(general_menu) : general_menu)
            : isTrainee() ? trainee_menu.concat(general_menu) : general_menu
    )
    : (
        isTrainer()
            ? trainer_menu.concat(isTrainee() ? trainee_menu.concat(general_menu) : general_menu)
            : isTrainee() ? trainee_menu.concat(general_menu) : general_menu
    );
