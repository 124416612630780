import React, { useEffect, useState } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import cs from "../../../const";
import "../SubjectList.css";
import { useTranslation, withTranslation } from "react-i18next";
import axios from "axios";

const getCourseDataUrl = cs.BaseURL + "/api/trainer/trainee/score/list?";
const getModuleDataUrl = cs.BaseURL + "/api/module/list";
const getDomainDataUrl = cs.BaseURL + "/api/module/domain-list";

const ModuleScores = ({ match, t, i18n }) => {
  const { moduleId } = match.params;
  const [scoreList, setScoreList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [domainList, setDomainList] = useState([]);

  const getScoresData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getCourseDataUrl}course_id=${moduleId}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      // console.log("scorelist", response.data);
      setScoreList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getModuleData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getModuleDataUrl}`,
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      // console.log("modulelist", response.data);
      setModuleList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getModule = (moduleId) => {
    let module = {};
    moduleList.forEach(item => {
      if (item.moduleId == moduleId) {
        module = item;
        // console.log("item", item);
        return false;
      }
    })
    return module;
  }

  const getDomainData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getDomainDataUrl}`,
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
      // console.log("data", response.data);
      setDomainList(response.data.data);
      // console.log(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getDomainEngName = (domainId) => {
    let vieName = "";
    domainList.forEach(item => {
      if (item.domainId === domainId) {
        vieName = item.domainEngName;
        return false;
      }
    })
    return vieName;
  }

  useEffect(() => {
    getScoresData();
    getModuleData();
    getDomainData();
  }, []);

  return (
    <ThemeContext.Consumer>
      {({ isDark }) => {
        return <div className={isDark ? "card dark-background-1" : "card"} style={{ minHeight: "85vh" }}>
          <div className="card-body shadow">
            <h4 className="my-2 text-uppercase">{t("student-points-list")}</h4>
            <div className="mb-2">
              <div>{t("module.module")}: {getModule(moduleId).moduleName}</div>
              <div>{t("domain.domain")}: {t(`domain.name.${getDomainEngName(getModule(moduleId).domainId)}`)}</div>
              {/* <div>Môn học: {scoreList[0] ? scoreList[0].}</div> */}
            </div>
            <div>
              <div className="table-responsive-lg">
                <table className={isDark ? "table table-sm table-striped table-bordered table-hover table-dark" : "table table-sm table-striped table-bordered table-hover"}>
                  <thead>
                    <tr className="align-top">
                      <th className="bg-primary text-white text-center index" scope="col">{t("index")}</th>
                      <th className="bg-primary text-white text-center fullname" scope="col">{t("fullname")}</th>
                      <th className="bg-primary text-white text-center birthday" scope="col">{t("birthday")}</th>
                      <th className="bg-primary text-white text-center gender" scope="col">{t("gender")}</th>
                      <th className="bg-primary text-white text-center phone" scope="col">{t("phone")}</th>
                      <th className="bg-primary text-white text-center course" scope="col">{t("course.course")}</th>
                      <th className="bg-primary text-white text-center trainer" scope="col">{t("expert")}</th>
                      {/* <th className="bg-primary text-white text-center address" scope="col">Địa chỉ</th> */}
                      {/* <th className="bg-primary text-white text-end" scope="col">Đầu kỳ</th>
                    <th className="bg-primary text-white text-end" scope="col">Giữa kỳ</th>
                    <th className="bg-primary text-white text-end" scope="col">Cuối kỳ</th> */}
                      <th className="bg-primary text-white text-center final-score" scope="col">{t("final-score")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scoreList.map((item, index) => <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-capitalize">{item.traineeFullname.toLowerCase()}</td>
                      <td className="text-end">25-02-2000</td>
                      <td className="text-center">{t("male")}</td>
                      <td className="text-end">{item.traineeTelephone}</td>
                      <td className="text-start">{item.courseName}</td>
                      <td className="text-start text-capitalize">{item.trainerFullname.toLowerCase()}</td>
                      {/* <td className="text-end">9.0</td>
                    <td className="text-end">0.9</td>
                    <td className="text-end">0.9</td> */}
                      <td className="text-center">{item.finalScore}</td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      }}
    </ThemeContext.Consumer>
  )
}

export default withTranslation()(ModuleScores);