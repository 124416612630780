import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, vi } from "./translations";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      ...en,
      // "Welcome to React": "Welcome to React and react-i18next"
    },
  },
  vi: {
    translation: {
      ...vi,
      // "Welcome to React": "Chào mừng bạn đến tới React và react-i18next"
    },
  },
};

i18n
  // .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("currentLanguage") || "vi", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    // backend: {
    //     // for all available options read the backend's repository readme file
    //     loadPath: '/locales/{{lng}}/{{ns}}.json'
    // }
  });

export default i18n;
