import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import cs from "../const";
import { getRole, getFunctionRoles } from "../service";
import { ToastProvider } from "react-toast-notifications";
import Settings from "./settings/Settings";
import SubjectList from "./Subjects/SubjectList";
import UserList from "./users/UserList";
import CourseList from "./Course/CourseList";
import ExpertList from "./Expert/ExpertList";
import CoursesList from "./CoursesList/CoursesList";
import EmployeeList from "./EmployeeList/EmployeeList";
import MemberList from "./MemberList/MemberList";
import AnswerList from "./AnswerList/AnswerList";
import QuestBankControl from "./QuestBank/QuestBankControl";

import Exam from "./Exam/Exam";
import VideoPlayer from "./shared/VideoPlayer/VideoPlayer";
import MySubjectList from "./MySubjects/MySubjectList";
import CourseMemberList from "./CourseMember/CourseMemberList";
import ModuleScores from "./Subjects/moduleScores/ModuleScores";
import CourseStatistics from "./CoursesList/CourseStatistics";
import MyCourses from "./MyCourses/MyCourses";
import TraineeList from "./MySubjects/TraineeList";
import Lesson from "./LessonList/Lesson";
import CreateLesson from "./LessonList/CreateLesson";
import LectureList from "./LectureList/LectureList";
import MaterialList from "./MaterialList/MaterialList";
import ExamList from "./ExamList/ExamList";
import CoursesView from "./CoursesView/CoursesView";
import MaterialView from "./CoursesView/MaterialView";
import MockExam from "./Exam/MockExam";
import RegisterCourseList from "./RegisterCourse/RegisterCourseList";
import NoLicense from "./shared/NoLicense";
import StartExam from "./CoursesView/StartExam";

import { useTranslation, withTranslation } from "react-i18next";
import MyTrainee from "./CourseMember/MyTrainee";

// import ModuleScores from "./Subjects/moduleScores";
const styles = (theme) => ({
  tableContainer: {
    height: 320,
  },
});

export class AppRoute extends Component {
  constructor(props) {
    super(props);
    console.log("AppRoute props", props);
  }
  render(props) {
    const { t, i18n } = this.props;

    console.log("role", getFunctionRoles());
    return (
      <ToastProvider autoDismissTimeout={5000} placement="bottom-center">
        <Route exact path="/" component={() => DashboardArea(t, i18n)} />
        {/* General Setting */}
        <Route exact path="/settings" component={() => SettingsArea(t, i18n)} />
        <Route exact path="/no-license" component={() => NoLicense} />
        {/* Role Training Manager */}
        {/* <Route path="/user" component={UserArea} /> */}
        <Route exact path="/subjects-list" component={SubjectList} />
        <Route exact path="/courses-list" component={CoursesList} />
        <Route exact path="/courses-list/:id" component={CourseMemberList} />
        {/* <Route exact path="/course_list" component={CourseList} /> */}
        <Route exact path="/expert_list" component={ExpertList} />
        <Route exact path="/employee_list" component={EmployeeList} />
        <Route exact path="/approve_list" component={MemberList} />
        <Route exact path="/courses_view/:courseId" component={CoursesView} />
        {/* Both Trainer and Training Manager */}
        {/* <Route exact path="/module-scores/:moduleId?" component={ModuleScores} /> */}
        {/* Role Trainer */}
        <Route path="/my-courses" component={() => MyCoursesArea(t, i18n)} />
        <Route exact path="/my_trainees/:id" component={MyTrainee} />
        <Route
          exact
          path="/answer_list/:course_id/:testOrderNumber"
          component={AnswerList}
        />
        <Route
          exact
          path="/course-statistics/:courseId?"
          component={CourseStatistics}
        />
        <Route
          exact
          path="/course_trainees/:course_id/"
          component={() => CourseTraineesArea(t, i18n)}
        />
        <Route exact path="/lesson/:id" component={() => LessonArea(t, i18n)} />
        {/* <Route exact path="/new-lesson" component={NewLessonArea} /> */}
        <Route
          path="/course_lectures/:course_id/"
          component={() => LectureListArea(t, i18n)}
        />
        <Route
          path="/course_materials/:course_id/:lectureOrderNumber"
          component={() => MaterialListArea(t, i18n)}
        />
        <Route
          path="/course_exams/:course_id"
          component={() => ExamListArea(t, i18n)}
        />
        <Route exact path="/question-bank" component={QuestBankControl} />
        <Route
          exact
          path="/mock_exam/:course_id/:testOrderNumber"
          component={MockExam}
        />

        {/* Role Trainee */}
        <Route exact path="/my-courselist" component={MyCourses} />
        <Route exact path="/register-course" component={RegisterCourseList} />

        <Route
          exact
          path="/courses_view/:course_id/:lectureOrderNumber/view/:materialId"
          component={MaterialView}
        />
        <Route
          exact
          path="/courses_view/:course_id/:testOrderNumber/exam/:test_code"
          component={Exam}
        />
        <Route
          exact
          path="/courses_view/:course_id/:testOrderNumber"
          component={StartExam}
        />

        {/* <Route exact path="*">
          <Redirect to="/" />
        </Route> */}
        {/* <Route path="/my-subjects" component={MySubjectsArea} /> */}
        {/* <Route path="/my-subjects" component={MySubjectsArea} /> */}
        {/* <Route exact path="/module-scores:moduleId?" component={ModuleScores} /> */}
      </ToastProvider>
    );
  }
}

export default withTranslation()(AppRoute);

const DashboardArea = (t, i18n) => (
  <div>
    <Typography component="div" className={styles.tableContainer}>
      {/* E-Training Home */}
      {t("app_route.dashboard")}
      {/* <VideoPlayer 
      src={"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"} 
      checkpoints={[5,10,15,20,25,30]}
      /> */}
    </Typography>
  </div>
);

const UserArea = () => (
  <div>
    <Typography variant="h5" gutterBottom component="h2">
      User
    </Typography>
    <div className={styles.tableContainer}>
      <UserList />
    </div>
  </div>
);

const MyCoursesArea = (t, i18n) => (
  <div className="card card-body">
    <div className="page-title mb-3">
      {/* My Subjects */}
      {t("app_route.my-course")}
    </div>
    <div className={styles.tableContainer}>
      <MySubjectList />
    </div>
  </div>
);

const CourseTraineesArea = (t, i18n) => (
  <div className="card card-body shadow">
    <div className="page-title mb-3">
      Trainees
    </div>
    <div className={styles.tableContainer}>
      <TraineeList />
    </div>
  </div>
);

const LectureListArea = (t, i18n) => (
  <div className="card card-body shadow">
    <div className="page-title mb-3">
      {/* Lectures */}
      {t("app_route.lectures")}
    </div>
    <div className={styles.tableContainer}>
      <LectureList />
      {/* <Lesson /> */}
    </div>
  </div>
);

const MaterialListArea = () => (
  <div className="card card-body shadow">
    <div className="page-title mb-3">
      Lectures materials
    </div>
    <div className={styles.tableContainer}>
      <MaterialList />
    </div>
  </div>
);

const ExamListArea = () => (
  <div className="card card-body shadow">
    <div className="page-title mb-3">
      Exams
    </div>
    <div className={styles.tableContainer}>
      <ExamList />
    </div>
  </div>
);

const LessonArea = () => (
  <div className="card card-body shadow">
    <div className="page-title mb-3">
      Lesson
    </div>
    <div className={styles.tableContainer}>
      <Lesson />
    </div>
  </div>
);

const NewLessonArea = () => (
  <div>
    <Typography variant="h5" gutterBottom component="h2">
      New Lesson
    </Typography>
    <div className={styles.tableContainer}>
      <CreateLesson />
    </div>
  </div>
);
const StartExamArea = () => (
  <div>
    <Typography variant="h5" gutterBottom component="h2">
      Start Exam
    </Typography>
    <div className={styles.tableContainer}>
      <StartExam />
      {/* <Lesson /> */}
    </div>
  </div>
);

const SettingsArea = (t, i18n) => (
  <div className="card card-body shadow pb-5">
    <div className="page-title mb-3">
      {/* Cài đặt hệ thống */}
      {t("app_route.settings")}
    </div>
    <div className={styles.tableContainer}>
      <Settings />
    </div>
  </div>
);
