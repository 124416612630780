import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation, withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: "70vw",
    maxHeight: "95vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #888',
    borderRadius: '4px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const QuestionModalForm = ({ reqType, modalForm, questionLvList, answerList, courseList, onChangeModal, handleConfirmAddClick, handleConfirmModClick, handleCloseModal, t, i18n }) => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <div className="container-fluid p-0">
        <h4 className="text-uppercase">
          {reqType === "add" && t("question-bank.add-question")}
          {reqType === "modify" && t("question-bank.modify-question")}
        </h4>
        {reqType === "add" && <div className="row mb-2">
          <label className="col-md-2" for="course-id">{t("course.course")}: </label>
          <div className="col-md-10">
            <select
              className="form-select form-select-sm"
              id="course-id"
              name="courseId"
              value={modalForm.courseId}
              onChange={onChangeModal}
            >
              <option value="">{t("course.course-list")}</option>
              {courseList.map(item =>
                <option value={item.courseId}>{item.courseName}</option>
              )}
            </select>
          </div>
        </div>}
        <div className="row mb-2">
          <label className="col-md-2" for="question">{t("question-bank.question")}: </label>
          <div className="col-md-10">
            <input
              className="form-control form-control-sm"
              type="text"
              id="question"
              name="question"
              value={modalForm.question}
              onChange={onChangeModal}
            />
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="lv-difficult">{t("question-bank.level-of-difficult")}: </label>
          <div className="col-md-10">
            <select
              className="form-select form-select-sm"
              id="lv-difficult"
              name="lvOfDifficult"
              value={modalForm.lvOfDifficult}
              onChange={onChangeModal}
            >
              <option value="">{t("question-bank.level-of-difficult")}</option>
              {questionLvList.map(item =>
                <option value={item.difficultyLevelId}>{t(`question-bank.${item.difficutlyLevelEngName}`)}</option>
              )}
            </select>
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="answer-1">{t("question-bank.answer")} A: </label>
          <div className="col-md-10">
            <input
              className="form-control form-control-sm"
              type="text"
              id="answer-1"
              name="answerChoice1"
              value={modalForm.answerChoice1}
              onChange={onChangeModal}
            />
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="answer-2">{t("question-bank.answer")} B: </label>
          <div className="col-md-10">
            <input
              className="form-control form-control-sm"
              type="text"
              id="answer-2"
              name="answerChoice2"
              value={modalForm.answerChoice2}
              onChange={onChangeModal}
            />
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="answer-3">{t("question-bank.answer")} C: </label>
          <div className="col-md-10">
            <input
              className="form-control form-control-sm"
              type="text"
              id="answer-3"
              name="answerChoice3"
              value={modalForm.answerChoice3}
              onChange={onChangeModal}
            />
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="answer-4">{t("question-bank.answer")} D: </label>
          <div className="col-md-10">
            <input
              className="form-control form-control-sm"
              type="text"
              id="answer-4"
              name="answerChoice4"
              value={modalForm.answerChoice4}
              onChange={onChangeModal}
            />
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="correct-answer">{t("question-bank.correct-answer")}: </label>
          <div className="col-md-10">
            <select
              className="form-select form-select-sm"
              id="correct-answer"
              name="correctAnswer"
              value={modalForm.correctAnswer}
              onChange={onChangeModal}
            >
              <option value="">{t("question-bank.correct-answer")}</option>
              {answerList.map(item =>
                <option value={item.answerName}>{item.answerName}</option>
              )}
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {reqType === "add" && <button
            className="btn btn-sm btn-primary me-1"
            style={{ width: "60px" }}
            onClick={() => {
              handleConfirmAddClick();
            }}
          >
            {t("add")}
          </button>}
          {reqType === "modify" && <button
            className="btn btn-sm btn-primary me-1"
            style={{ width: "60px" }}
            onClick={() => {
              handleConfirmModClick();
            }}
          >
            {t("modify")}
          </button>}
          <button
            type="reset"
            className="btn btn-sm btn-danger"
            style={{ width: "60px" }}
            onClick={handleCloseModal}
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(QuestionModalForm);