import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withToastManager } from 'react-toast-notifications';
import { useTranslation, withTranslation } from 'react-i18next';

import cs from '../../const.js';


const changePasswordURL = cs.BaseURL + '/common/auth/change_password';
const styles = (theme) => ({
    gender_button: {
        marginTop: 20,
        marginLeft: 40
    }
});

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        console.log("props", props);
        this.state = {
            current_password: '',
            new_password: '',
            confirm_password: ''
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.handleDialogDisagree = this.handleDialogDisagree.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    resetState() {
        this.setState({
            current_password: '',
            new_password: '',
            confirm_password: ''
        });
    }

    handleClose = () => {
        this.resetState();
        this.props.onClose();
        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.previous
        )
            this.props.history.push({
                pathname: this.props.history.location.state.previous,
                state: { reload: true }
            });
    };

    handleDialogAgree = () => {
        const { classes, t, i18n } = this.props;
        if (
            !this.state.new_password.match(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
            )
        ) {
            window.alert(
                'Mật khẩu phải bao gồm ít nhất 1 chữ cái viết hoa, 1 chữ cái viết thường, 1 số, 1 kí tự đặc biệt và độ dài từ 8 kí tự trở lên'
            );
            return;
        }

        if (this.state.new_password !== this.state.confirm_password) {
            window.alert('Mật khẩu chưa trùng khớp');
            return;
        }

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-user');
        if (user) username = JSON.parse(String(user)).name;
        let queryString = `${changePasswordURL}`;

        // fetch(queryString, {
        //     method: 'POST',
        //     body: JSON.stringify({
        //         old_password: this.state.current_password,
        //         new_password: this.state.new_password
        //     }),
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //         Authorization: localStorage.getItem("token")
        //     }
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         if (
        //             data &&
        //             (data.code === cs.erAuthenticationFailed.code ||
        //                 data.code === cs.erAccountIsLocked.code)
        //          ) {
        //             localStorage.clear();
        //             sessionStorage.clear();
        //             window.location.reload();
        //         }

        //         if (data && data.error && data.code !== cs.Succeed) {
        //             this.props.toastManager.add(JSON.stringify(data.error), {
        //                 appearance: 'error',
        //                 autoDismiss: true,
        //                 pauseOnHover: true
        //             });
        //         } else {
        //             this.props.toastManager.add('Đổi mật khẩu thành công !', {
        //                 appearance: 'success',
        //                 autoDismiss: true,
        //                 pauseOnHover: true
        //             });
        //             this.resetState();
        //         }
        //     })
        //     .catch(() => {
        //         this.resetState();
        //     });
        this.handleClose();
    };

    handleDialogDisagree = () => {
        this.handleClose();
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value
        });
    };

    render() {
        const { classes, t, i18n } = this.props;
        console.log(t, i18n);
        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-user');
        if (user) username = JSON.parse(String(user)).name;

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="change-pw">
                        {t("change_password.title")}
                    </DialogTitle>
                    <DialogContent>
                        <TextField margin="dense" id="user_name" value={username} fullWidth />
                        <TextField
                            margin="dense"
                            id="current_password"
                            value={this.state.current_password}
                            onChange={this.handleChange('current_password')}
                            label={t("change_password.current_password")}
                            type="password"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="new_password"
                            value={this.state.new_password}
                            onChange={this.handleChange('new_password')}
                            label={t("change_password.new_password")}
                            type="password"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="confirm_password"
                            value={this.state.confirm_password}
                            onChange={this.handleChange('confirm_password')}
                            label={t("change_password.confirm_password")}
                            type="password"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogDisagree} color="primary">
                            {t("commons.button.cancel")}
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            {t("commons.button.confirm")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ChangePassword.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(withTranslation()(ChangePassword)));
