import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { withToastManager } from "react-toast-notifications";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import cs from "../../const";
import moment from "moment-timezone";
import DialogWrapper from "../shared/DialogWrapper";
// import { DropzoneArea } from 'material-ui-dropzone';

const URL = cs.BaseURL + '/api/user/list';
const updateUserURL = cs.BaseURL + "/api/user/update";


const hubName = ["Đồng bằng Bắc Bộ", "Đông Bắc Bộ", "Tây Bắc Bộ", "Bắc Trung Bộ", "Nam Trung Bộ", "Tây Nguyên", "Đông Nam Bộ", "Đồng bằng Sông Cửu Long", "Hà Nội", "TP.HCM"];

const styles = theme => ({
    locked_button: {
        marginTop: 10,
        marginLeft: 0
    },
    dropZone: {
        paddingTop: 10,
        minHeight: 200
    },
    gridContainer: {
        paddingTop: 10
    },
    downloadContainer: {
        paddingTop: 10
    },
});

function formatValue(value) {
    // format display of time
    if (value && value.created_at)
        value.created_at = moment
            .unix(value.created_at)
            .tz('Asia/Ho_Chi_Minh')
            .format('YYYY-MM-DD');

    if (value && value.ended_at)
        value.ended_at = moment
            .unix(value.ended_at)
            .tz('Asia/Ho_Chi_Minh')
            .format('YYYY-MM-DD');
    // format short forms
    if (value && value.current_province) value.current_province = value.current_province.replace('Thành phố', 'TP').replace('Tỉnh', '');


    return value;
}

class EditUser extends React.Component {
    state = {
        id: this.props.match.params.id,
        // name: "",
        // code: "",
        // locked: false,
        // file: null,
        agent_id: '',
        hub: '',
        agent_phone: '',
        agent_name: '',
        adr_state: '',
        adr_state_code: '',
        adr_district: '',
        adr_district_code: '',
        adr_ward: '',
        adr_ward_code: '',
        states: [],
        districts: [],
        wards: [],
        agents: [],
        hubs: Array.from(new Array(10), (x, i) => i + 1)
    };
    constructor(props) {
        super(props);

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.loadData();
    }

    queryString() {
        const { id } = this.state;
        let queryString = `${URL}?id=${id}`;
        return queryString;
    }

    loadData() {
        // if (!isTelesaleOP()) {
        //     window.location.href="/";
        //     return;
        // }
        this.setState({ id: this.props.match.params.id, loading: true });
        const queryString = this.queryString();
        fetch(queryString, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.data);
                if (data && data.data[0]) {
                    let tmp = formatValue(data.data[0]);
                    this.setState({
                        // package_name: tmp.package_name,
                        // package_type: tmp.package_type,
                        // amount: tmp.amount,
                        agent_id: tmp.agent_id,
                        hub: tmp.hub,
                        agent_phone: tmp.agent_phone,
                        agent_name: tmp.agent_name,
                        adr_state: tmp.adr_state,
                        adr_state_code: tmp.adr_state_code,
                        adr_district: tmp.adr_district,
                        adr_district_code: tmp.adr_district_code,
                        adr_ward: tmp.adr_ward,
                        adr_ward_code: tmp.adr_ward_code,
                    },
                        () => {
                            this.loadStates();
                            this.loadDistricts();
                            this.loadWards();
                            this.setState({
                                adr_state: tmp.adr_state,
                                adr_state_code: tmp.adr_state_code,
                                adr_district: tmp.adr_district,
                                adr_district_code: tmp.adr_district_code,
                                adr_ward: tmp.adr_ward,
                                adr_ward_code: tmp.adr_ward_code,
                            })
                        }
                    );

                    console.log("state", this.state)
                } else {
                    this.setState({
                        loading: false
                    });
                    if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                        // sessionStorage.clear();
                        // localStorage.clear();
                        // window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleDialogAgree = () => {
        console.log(this.state)
        if (!this.state.agent_id) {
            window.alert('Please input information !');
            return;
        }

        let queryString = `${updateUserURL}`;
        let requestBody = {
            id: parseInt(this.state.id),
            agent_id: this.state.agent_id,
            hub: this.state.hub,
            adr_state_code: this.state.adr_state_code,
            adr_district_code: this.state.adr_district_code,
            adr_ward_code: this.state.adr_ward_code,

            adr_state: this.state.adr_state,
            adr_district: this.state.adr_district,
            adr_ward: this.state.adr_ward
        };

        console.log(requestBody);
        fetch(queryString, {
            method: "POST",

            body: JSON.stringify(requestBody),
            headers: {
                Authorization: localStorage.getItem("token"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: "error",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add("AgentLocation is updated !", {
                        appearance: "success",
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/user',
                    state: { reload: true }
                });
                //window.location.reload();
            })
            .catch(() => { });
    };

    handleChange = name => event => {
        if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    
                }
            );
    };

    handleDropZoneChange(files) {
        this.setState({
            file: files[0]
        });
    }

    render() {
        const { classes } = this.props;
        const { merchant } = this.state;
        return (
            <div>
                <DialogWrapper title={'Gán địa bàn - Cập nhật'} {...this.props}>
                    <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="merchantId">Agent (*)</InputLabel>
                            <Select
                                value={this.state.agent_id}
                                onChange={this.handleChange('agent_id')}
                                inputProps={{
                                    name: 'package_type',
                                    id: 'package_type'
                                }}
                            >
                                {this.state.agents.map(item => (
                                    <MenuItem value={item.id}>{item.code}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            disabled
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="agent_name"
                            required={true}
                            value={this.state.agent_name}
                            onChange={this.handleChange('agent_name')}
                            label="Tên"
                            type="name"
                        />

                        <TextField
                            disabled
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="agent_phone"
                            required={true}
                            value={this.state.agent_phone}
                            onChange={this.handleChange('agent_phone')}
                            label="SĐT "
                            type="name"
                        />

                        <FormControl fullWidth>
                            <InputLabel htmlFor="adr_ward_id">Hub</InputLabel>
                            <Select
                                value={this.state.hub}
                                onChange={this.handleChange('hub')}
                                inputProps={{
                                    name: 'adr_ward_code',
                                    id: 'adr_ward_id'
                                }}
                            >
                                {this.state.hubs.map(item => (
                                    <MenuItem value={item}>
                                        {hubName[item - 1] + " (" + item + ")"}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>



                        <FormControl fullWidth>
                            <InputLabel htmlFor="cus_adr_state_id">
                                Tỉnh/thành phố (*)
                                    </InputLabel>
                            <Select
                                value={this.state.adr_state_code}
                                onChange={this.handleChange('adr_state_code')}
                                inputProps={{
                                    name: 'adr_state_code',
                                    id: 'adr_state_id'
                                }}
                            >
                                {this.state.states.map(pro => (
                                    <MenuItem value={pro.state_code}>
                                        {pro.state_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="adr_district_id">
                                Quận / huyện (*)
                                    </InputLabel>
                            <Select
                                value={this.state.adr_district_code}
                                onChange={this.handleChange('adr_district_code')}
                                inputProps={{
                                    name: 'adr_district_code',
                                    id: 'adr_district_id'
                                }}
                            >
                                {this.state.districts.map(pro => (
                                    <MenuItem value={pro.district_code}>
                                        {pro.district_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="adr_ward_id">Xã/Phường</InputLabel>
                            <Select
                                value={this.state.adr_ward_code}
                                onChange={this.handleChange('adr_ward_code')}
                                inputProps={{
                                    name: 'adr_ward_code',
                                    id: 'adr_ward_id'
                                }}
                            >
                                {this.state.wards.map(pro => (
                                    <MenuItem value={pro.ward_code}>
                                        {pro.ward_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.props.history.goBack}
                            color="primary"
                        >
                            Hủy
                        </Button>
                        <Button
                            onClick={this.handleDialogAgree}
                            color="primary"
                        >
                            Cập nhật
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            </div>
        );
    }
}

EditUser.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(EditUser));
