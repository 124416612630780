import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../contexts/ThemeContext";
import "./Courses.css";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Snackbar";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation, withTranslation } from "react-i18next";
import axios from "axios";
import cs from "../../const";

const coursesCreateUrl = cs.BaseURL + "/api/course/create";
const coursesUpdateUrl = cs.BaseURL + "/api/course/update";
const coursesDeleteUrl = cs.BaseURL + "/api/course/delete?id=";
const getCoursesListUrl = cs.BaseURL + "/api/course/list";
const getModuleListUrl = cs.BaseURL + "/api/module/list";
const getDomainListUrl = cs.BaseURL + "/api/module/domain-list";
const getTrainerListUrl = cs.BaseURL + "/api/trainer/list";
const getBranchDirectorListUrl = cs.BaseURL + "/api/common/branch-director/list";
const getDepartmentHeadListUrl = cs.BaseURL + "/api/common/department-head/list";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70vw",
    maxHeight: "95vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #888",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CoursesList = ({ t, i18n }) => {
  //control modal
  const [modalForm, setModalForm] = useState({
    courseName: "",
    startDate: "",
    endDate: "",
    domainID: "",
    trainerName: "",
    trainerId: "",
    location: "",
    module: "",
    moduleId: "",
    schedule: "",
    requireRegistration: false,
    broadcastAllCompany: false,
    broadcastToBranchCode: 0,
    broadcastToDepartmentCode: 0,
  });

  const [open, setOpen] = useState(false);
  const [reqType, setReqType] = useState("");
  const handleOpenModal = (reqType) => {
    setReqType(reqType);
    setOpen(true);
  };
  const handleCloseModal = () => {
    setModalForm({
      courseName: "",
      startDate: "",
      endDate: "",
      domainID: "",
      trainerName: "",
      trainerId: "",
      location: "",
      module: "",
      moduleId: "",
      schedule: "",
      requireRegistration: false,
      broadcastAllCompany: false,
      broadcastToBranchCode: 0,
      broadcastToDepartmentCode: 0,
    });
    setOpen(false);
  };

  const classes = useStyles();
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDelModal = () => {
    setOpenDel(true);
  };
  const handleCloseDelModal = () => {
    setOpenDel(false);
  };

  const onChangeModal = (event) => {
    setModalForm({ ...modalForm, [event.target.name]: event.target.value });
  };

  const onChangeCheckBox = (event) => {
    setModalForm({ ...modalForm, [event.target.name]: event.target.checked });
  };

  //response message
  const [responseMessage, setResponseMessage] = useState({
    type: "",
    content: "",
  });
  const [openMessage, setOpenMessage] = useState(false);

  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  //handle data
  const handleAddClick = () => {
    handleOpenModal("add");
  };

  const handleConfirmAddClick = async () => {
    if (!modalForm.courseName
      || !modalForm.moduleId
      || !modalForm.trainerId
    ) {
      setResponseMessage({
        type: "warning",
        content: t("course.response-message.empty-fields"),
      });
      setOpenMessage(true);
      //console.log(responseMessage.content);
      return;
    }
    try {
      const response = await axios({
        method: "post",
        url: `${coursesCreateUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: {
          courseName: modalForm.courseName,
          moduleId: modalForm.moduleId,
          trainerId: modalForm.trainerId,
          scheduleInfo: modalForm.schedule,
          location: modalForm.location,
          requireRegistration: modalForm.requireRegistration ? 1 : 0,
          broadcastAllCompany: modalForm.broadcastAllCompany ? 1 : 0,
          branchId: modalForm.broadcastAllCompany
            ? 0
            : modalForm.broadcastToBranchCode,
          departmentId: modalForm.broadcastAllCompany
            ? 0
            : modalForm.broadcastToDepartmentCode,
          startDate: modalForm.startDate + " 00:00:00",
          endDate: modalForm.endDate + " 23:59:59",
        },
      });
      console.log(response.data);
      if (response.data.error_desc === "Success") {
        setResponseMessage({
          type: "success",
          content: t("course.response-message.add-success"),
        });
        setOpenMessage(true);
        getCoursesData();
        handleCloseModal();
      } else {
        setResponseMessage({
          type: "error",
          content: response.data.error_desc,
        });
        setOpenMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [modifyId, setModifyId] = useState("");
  const handleModClick = (item) => {
    console.log(item);
    setModalForm({
      courseName: item.courseName,
      startDate:
        item.startDate.slice(0, 4) +
        "-" +
        item.startDate.slice(5, 7) +
        "-" +
        item.startDate.slice(8, 10),
      endDate:
        item.endDate.slice(0, 4) +
        "-" +
        item.endDate.slice(5, 7) +
        "-" +
        item.endDate.slice(8, 10),
      domainID: "",
      trainerName: item.trainerFullname,
      trainerId: getTrainerId(item.trainerCode),
      location: item.location,
      module: item.moduleName,
      moduleId: item.moduleId,
      schedule: item.scheduleInfo,
      requireRegistration: false,
      broadcastAllCompany: false,
      broadcastToBranchCode: 0,
      broadcastToDepartmentCode: 0,
    });
    setModifyId(item.id);
    handleOpenModal("modify");
  };

  const handleConfirmModClick = async () => {
    // console.log("check", !modalForm.broadcastAllCompany && (!modalForm.broadcastToBranchCode || !modalForm.broadcastToDepartmentCode));
    if (!modalForm.courseName
      || !modalForm.moduleId
      || !modalForm.trainerId
      || (!modalForm.broadcastAllCompany && (!modalForm.broadcastToBranchCode || !modalForm.broadcastToDepartmentCode))
    ) {
      setResponseMessage({
        type: "warning",
        content: t("course.response-message.empty-fields"),
      });
      setOpenMessage(true);
      return;
    }
    try {
      const response = await axios({
        method: "post",
        url: `${coursesUpdateUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: {
          id: modifyId,
          courseName: modalForm.courseName,
          moduleId: modalForm.moduleId,
          trainerId: modalForm.trainerId,
          scheduleInfo: modalForm.schedule,
          location: modalForm.location,
          requireRegistration: modalForm.requireRegistration ? 1 : 0,
          broadcastAllCompany: modalForm.broadcastAllCompany ? 1 : 0,
          branchId: modalForm.broadcastAllCompany
            ? 0
            : modalForm.broadcastToBranchCode,
          departmentId: modalForm.broadcastAllCompany
            ? 0
            : modalForm.broadcastToDepartmentCode,
          startDate: modalForm.startDate + " 00:00:00",
          endDate: modalForm.endDate + " 23:59:59",
        },
      });
      console.log(response.data);
      if (response.data.error_desc === "Success") {
        setResponseMessage({
          type: "success",
          content: t("course.response-message.modify-success"),
        });
        setOpenMessage(true);
        getCoursesData();
        handleCloseModal();
      } else {
        setResponseMessage({
          type: "error",
          content: response.data.error_desc,
        });
        console.log(response.data.error_desc)
        setOpenMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onConfirmDataClick = () => {
    console.log(modalForm);
  };

  const [deleteId, setDeleteId] = useState("");
  const handleDelClick = (itemId) => {
    setDeleteId(itemId);
    handleOpenDelModal();
  };

  const handleConfirmDelClick = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${coursesDeleteUrl}${deleteId}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response.data.error_desc === "Success") {
        setResponseMessage({
          type: "success",
          content: t("course.response-message.delete-success"),
        });
        setOpenMessage(true);
        getCoursesData();
        handleCloseDelModal();
      } else {
        setResponseMessage({
          type: "error",
          content: response.data.error_desc,
        });
        setOpenMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //load data
  const [domainList, setDomainList] = useState([]);
  const [trainerList, setTrainerList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [branchDirectorList, setBranchDirectorList] = useState([]);
  const [departmentHeadList, setDepartmentHeadList] = useState([]);

  const getCoursesData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getCoursesListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("course", response.data);
      setCoursesList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getModuleData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getModuleListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log("module", response.data);
      setModuleList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getModuleName = (id) => {
    let moduleName = "";
    moduleList.forEach((item) => {
      if (item.id == id) {
        moduleName = item.moduleName;
        return false;
      }
    });
    return moduleName;
  };

  const getTrainerData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getTrainerListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log("trainer", response.data);
      setTrainerList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTrainerName = (id) => {
    let trainerName = "";
    moduleList.forEach((item) => {
      if (item.id == id) {
        trainerName = item.trainerFullname;
        return false;
      }
    });
    return trainerName;
  };

  const getTrainerId = (trainerCode) => {
    let trainerId = "";
    // console.log("trainercode", trainerCode);
    trainerList.forEach((item) => {
      // console.log(item.trainerCode);
      if (item.trainerCode == trainerCode) {
        trainerId = item.id;
        return false;
      }
    });
    return trainerId;
  };

  const getDomainData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getDomainListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log("domain", response.data);
      setDomainList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  //control domainlist
  const getDomainEngName = (domainID) => {
    // console.log(domainID);
    let vieName = "";
    domainList.forEach((item) => {
      // console.log(item)
      if (item.domainId == domainID) {
        vieName = item.domainEngName;
        return false;
      }
    });
    return vieName;
  };

  const getBranchDirectorList = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getBranchDirectorListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      // console.log("branch", response.data);
      setBranchDirectorList(response.data.data);
    } catch (error) {
      console.log(error)
    }
  }

  const getDepartmentHeadList = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getDepartmentHeadListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      // console.log("department", response.data);
      setDepartmentHeadList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDomainData();
    getTrainerData();
    getModuleData();
    getCoursesData();
    getBranchDirectorList();
    getDepartmentHeadList();
  }, []);

  return (
    <ThemeContext.Consumer>
      {({ isDark }) => {
        return (
          <div className={isDark ? "card dark-background-1" : "card"}>
            <div className="card-body shadow">
              <div className="d-flex align-items-baseline">
                <h4 className="page-title mb-4">{t("course.course-list")}</h4>
                <button className="btn btn-sm ms-auto" onClick={handleAddClick}>
                  {isDark ? (
                    <img src="https://img.icons8.com/ios-glyphs/30/ffffff/add--v1.png" />
                  ) : (
                    <img src="https://img.icons8.com/ios-glyphs/30/000000/add--v1.png" />
                  )}
                </button>
              </div>
              {coursesList.map((item) => {
                return (
                  <div
                    className={
                      isDark
                        ? "card card-body mb-2 shadow-sm dark-background-2"
                        : "card card-body mb-2 shadow-sm"
                    }
                  >
                    <h5 className="card-title">{item.courseName}</h5>
                    <div className="container-fluid p-0">
                      <div className="row row-cols-1 row-cols-sm-2">
                        <div className="col">
                          {t("from")}:{" "}
                          {item.startDate.slice(8, 10) +
                            "-" +
                            item.startDate.slice(5, 7) +
                            "-" +
                            item.startDate.slice(0, 4)}
                        </div>
                        <div className="col">
                          {t("to")}:{" "}
                          {item.endDate.slice(8, 10) +
                            "-" +
                            item.endDate.slice(5, 7) +
                            "-" +
                            item.endDate.slice(0, 4)}
                        </div>
                      </div>
                      <div className="row row-cols-1 row-cols-sm-2">
                        <div>
                          {t("module.module")}:{" "}
                          <Link
                            to="#"
                            className="stretched-link"
                            style={{
                              position: "relative",
                              textTransform: "capitalize",
                            }}
                          >
                            {item.moduleName}
                          </Link>
                        </div>
                        <div>
                          {t("domain.domain")}:{" "}
                          <Link
                            to="#"
                            className="stretched-link"
                            style={{ position: "relative" }}
                          >
                            {t(`domain.name.${getDomainEngName(item.domainId)}`)}
                          </Link>
                        </div>
                      </div>
                      <div className="row row-cols-1 row-cols-sm-2">
                        <div className="col">
                          {t("expert")}:{" "}
                          <Link
                            to="#"
                            className="stretched-link"
                            style={{
                              position: "relative",
                              textTransform: "capitalize",
                            }}
                          >
                            {item.trainerFullname &&
                              item.trainerFullname.toLowerCase()}
                          </Link>
                        </div>
                        <div className="col">
                          {t("phone")}: {item.trainerTelephone}
                        </div>
                      </div>
                      <div>{t("location")}: {item.location || "Online"}</div>
                      <div>
                        {t("course.numberOfRegisteredTrainees")}:{" "}
                        {item.numberOfRegisteredTrainees}
                      </div>
                      <div>
                        {t("course.numberOfApprovedTrainees")}:{" "}
                        {item.numberOfApprovedTrainees}
                      </div>
                      <div>
                        {t("course.numberOfCompletedTrainees")}:{" "}
                        {item.numberOfCompletedTrainees}
                      </div>
                      <div className="text-align-justify">
                        {t("description")}: {item.moduleDescription}
                      </div>
                      <div className="d-flex justify-content-end align-items-baseline">
                        <Link
                          className="btn stretched-link"
                          style={{
                            position: "relative",
                            textTransform: "capitalize",
                          }}
                          to={"/courses-list/" + item.courseId}
                        >
                          {isDark ? (
                            <img src="https://img.icons8.com/fluency-systems-regular/30/ffffff/list.png" />
                          ) : (
                            <img src="https://img.icons8.com/fluency-systems-regular/30/000000/list.png" />
                          )}
                        </Link>
                        <Link
                          className="btn stretched-link"
                          style={{
                            position: "relative",
                            textTransform: "capitalize",
                          }}
                          to={"/course-statistics/" + item.courseId}
                        >
                          {isDark ? (
                            <img src="https://img.icons8.com/windows/30/ffffff/Detail-view-of-part.png" />
                          ) : (
                            <img src="https://img.icons8.com/windows/30/000000/Detail-view-of-part.png" />
                          )}
                        </Link>

                        <button
                          className="btn btn-sm stretched-link"
                          style={{ position: "relative" }}
                          onClick={() => handleModClick(item)}
                        >
                          {isDark ? (
                            <img src="https://img.icons8.com/fluency-systems-regular/30/ffffff/edit--v2.png" />
                          ) : (
                            <img src="https://img.icons8.com/fluency-systems-regular/30/000000/edit--v2.png" />
                          )}
                        </button>
                        <button
                          className="btn btn-sm stretched-link"
                          style={{ position: "relative" }}
                          onClick={() => handleDelClick(item.id)}
                        >
                          {isDark ? (
                            <img src="https://img.icons8.com/material-outlined/30/ffffff/delete-trash.png" />
                          ) : (
                            <img src="https://img.icons8.com/material-outlined/30/000000/delete-trash.png" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* modal */}
            <Modal
              style={{ top: 0 }}
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <ModalForm
                reqType={reqType}
                modalForm={modalForm}
                setModalForm={setModalForm}
                domainList={domainList}
                trainerList={trainerList}
                moduleList={moduleList}
                branchDirectorList={branchDirectorList}
                departmentHeadList={departmentHeadList}
                handleCloseModal={handleCloseModal}
                onChangeModal={onChangeModal}
                onChangeCheckBox={onChangeCheckBox}
                onConfirmDataClick={onConfirmDataClick}
                handleConfirmAddClick={handleConfirmAddClick}
                handleConfirmModClick={handleConfirmModClick}
                t={t}
                i18n={i18n}
              />
            </Modal>

            <Modal
              style={{ top: 0 }}
              open={openDel}
              onClose={handleCloseDelModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.paper}>
                <div className="container-fluid p-0">
                  <p className="mt-3 mb-4">{t("course.response-message.Are you sure you want to delete")}</p>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary me-1"
                      style={{ width: "60px" }}
                      onClick={() => handleConfirmDelClick()}
                    >
                      {t("delete")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      style={{ width: "60px" }}
                      onClick={handleCloseDelModal}
                    >
                      {t("cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>

            <Snackbar
              open={openMessage}
              autoHideDuration={1500}
              onClose={handleCloseMessage}
            >
              <Alert
                onClose={handleCloseMessage}
                severity={responseMessage.type}
              >
                {responseMessage.content}
              </Alert>
            </Snackbar>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const ModalForm = ({
  reqType,
  modalForm,
  setModalForm,
  domainList,
  trainerList,
  moduleList,
  branchDirectorList,
  departmentHeadList,
  handleCloseModal,
  onChangeModal,
  onChangeCheckBox,
  onConfirmDataClick,
  handleConfirmAddClick,
  handleConfirmModClick,
  t,
  i18n
}) => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <div className="container-fluid p-0">
        <h4 className="text-uppercase">
          {reqType === "add" && t("course.add-course")}
          {reqType === "modify" && t("course.modify-course")}
        </h4>
        <div className="row mb-2">
          <label className="col-md-2" for="course-name">
            {t("course.course")}:{" "}
          </label>
          <div className="col-md-10">
            <input
              className="form-control form-control-sm"
              type="text"
              id="course-name"
              name="courseName"
              value={modalForm.courseName}
              onChange={onChangeModal}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6">
            <div className="row">
              <label className="col-md-4" for="start-date">
                {t("from")}:{" "}
              </label>
              <div className="col-md-8">
                <input
                  className="form-control form-control-sm"
                  type="date"
                  id="start-date"
                  name="startDate"
                  max={modalForm.endDate}
                  value={modalForm.startDate}
                  onChange={onChangeModal}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <label className="col-md-4" for="end-date">
                {t("to")}:{" "}
              </label>
              <div className="col-md-8">
                <input
                  className="form-control form-control-sm"
                  type="date"
                  id="end-date"
                  name="endDate"
                  min={modalForm.startDate}
                  value={modalForm.endDate}
                  onChange={onChangeModal}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="module-id">
            {t("module.module")}:{" "}
          </label>
          <div className="col-md-10">
            <select
              className="form-select form-select-sm"
              id="module-id"
              name="moduleId"
              value={modalForm.moduleId}
              onChange={(event) => onChangeModal(event)}
            >
              <option value="">{t("module.module-list")}</option>
              {moduleList.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.moduleName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="trainer-id">
            {t("expert")}:{" "}
          </label>
          <div className="col-md-10">
            <select
              className="form-select form-select-sm"
              id="trainer-id"
              name="trainerId"
              value={modalForm.trainerId}
              onChange={(event) => onChangeModal(event)}
            >
              <option value="">{t("experts-list")}</option>
              {trainerList.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.trainerFullname}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="location">
            {t("location")}:{" "}
          </label>
          <div className="col-md-10">
            <input
              className="form-control form-control-sm"
              type="text"
              id="location"
              name="location"
              value={modalForm.location}
              onChange={onChangeModal}
            />
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-6 col-sm-5 col-md-2" for="require-registration">
            {t("course.requireRegistration")}:
          </label>
          <div className="col col-md-10">
            <input
              className="form-check-input"
              type="checkbox"
              id="require-registration"
              name="requireRegistration"
              checked={modalForm.requireRegistration}
              //value={modalForm.requireRegistration}
              onChange={onChangeCheckBox}
            />
          </div>
        </div>
        <div className="row mb-2">
          <label
            className="col-6 col-sm-5 col-md-2"
            for="broadcast-all-company"
          >
            {t("course.broadcastAllCompany")}:
          </label>
          <div className="col col-md-10">
            <input
              className="form-check-input"
              type="checkbox"
              id="broadcast-all-company"
              name="broadcastAllCompany"
              checked={modalForm.broadcastAllCompany}
              onChange={(event) => {
                if (event.target.checked) {
                  setModalForm({
                    ...modalForm,
                    broadcastAllCompany: event.target.checked,
                    broadcastToBranchCode: 0,
                    broadcastToDepartmentCode: 0
                  });
                } else {
                  onChangeCheckBox(event);
                }
              }}
            />
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="broadcast-to-branch-code">
            {t("course.broadcastToBranchCode")}:{" "}
          </label>
          <div className="col-md-10">
            <select
              className="form-select form-select-sm"
              id="broadcast-to-branch-code"
              name="broadcastToBranchCode"
              value={modalForm.broadcastToBranchCode}
              onChange={(event) => onChangeModal(event)}
              disabled={modalForm.broadcastAllCompany}
            >
              <option value={0}>{t("branch-list")}</option>
              {branchDirectorList.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.fullname}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-md-2" for="broadcast-to-department-code">
            {t("course.broadcastToDepartmentCode")}:{" "}
          </label>
          <div className="col-md-10">
            <select
              className="form-select form-select-sm"
              id="broadcast-to-department-code"
              name="broadcastToDepartmentCode"
              value={modalForm.broadcastToDepartmentCode}
              onChange={(event) => onChangeModal(event)}
              disabled={modalForm.broadcastAllCompany}
            >
              <option value={0}>{t("department-list")}</option>
              {departmentHeadList.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.fullname}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-md-2" for="schedule">
            {t("schedule")}:{" "}
          </label>
          <div className="col-md-10">
            <textarea
              className="form-control form-control-sm"
              id="schedule"
              name="schedule"
              rows="3"
              value={modalForm.schedule}
              onChange={onChangeModal}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {reqType === "add" && (
            <button
              className="btn btn-sm btn-primary me-1"
              style={{ width: "60px" }}
              onClick={() => {
                // onConfirmDataClick();
                handleConfirmAddClick();
              }}
            >
              {t("add")}
            </button>
          )}
          {reqType === "modify" && (
            <button
              className="btn btn-sm btn-primary me-1"
              style={{ width: "60px" }}
              onClick={() => {
                handleConfirmModClick();
                onConfirmDataClick();
              }}
            >
              {t("modify")}
            </button>
          )}
          <button
            type="reset"
            className="btn btn-sm btn-danger"
            style={{ width: "60px" }}
            onClick={handleCloseModal}
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(CoursesList);
