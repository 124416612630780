import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from '../../const';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import moment from 'moment-timezone';

import React from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { blue, green } from '@material-ui/core/colors';

import Icon from '@material-ui/core/Icon';

import { ToastProvider } from 'react-toast-notifications';

import DataTable from '../shared/DataTable.jsx';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import VideoPlayer from '../shared/VideoPlayer/VideoPlayer';

const URL = cs.BaseURL + '/api/user/list';

function formatValue(value) {
    // format display of time
    if (value && value.buying_date)
        value.buying_date = moment(value.buying_date)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM/YYYY HH:mm');

    // format short forms
    if (value && value.current_province) value.current_province = value.current_province.replace('Thành phố', 'TP').replace('Tỉnh', '');

    if (value && value.user_role) {
        switch (value.user_role) {
            case cs.Role_Telesale_Merchant_User:
                value.user_role = "MERCHANT USER"
                break;
            case cs.Role_Telesale_Merchant_Manager:
                value.user_role = "MERCHANT MANAGER"
                break;
            case cs.Role_Telesale_Merchant_Leader:
                value.user_role = "MERCHANT LEADER";
                break;
            default:
                break;
        }
    }

    if (value && value.hub) {
        switch (value.hub) {
            case 1:
                value.hub = "Đồng bằng Bắc Bộ (1)";
                break;
            case 2:
                value.hub = "Đông Bắc Bộ (2)";
                break;
            case 3:
                value.hub = "Tây Bắc Bộ (3)";
                break;
            case 4:
                value.hub = "Bắc Trung Bộ (4)";
                break;
            case 5:
                value.hub = "Nam Trung Bộ (5)";
                break;
            case 6:
                value.hub = "Tây Nguyên (6)";
                break;
            case 7:
                value.hub = "Đông Nam Bộ (7)";
                break;
            case 8:
                value.hub = "Đồng bằng Sông Cửu Long (8)";
                break;
            case 9:
                value.hub = "Hà Nội (9)";
                break;
            case 10:
                value.hub = "TP.HCM (10)";
            default:
                break;
        }
    }

    return value;
}

const isHRMCManager = () => {
    var user = localStorage.getItem(cs.System_Code + '-user');
    if (!user) return false;
    var userrole = JSON.parse(String(user)).role;
    return (
        // userrole === cs.Role_Telesale_Hub_User ||
        userrole === cs.Role_HR_Mc_Manager
    );
};

const docs = [
    { uri: "https://www.physixfan.com/wp-content/files/GEBen.pdf" },
    { uri: "https://www.barnstableacademy.com/app/uploads/2021/05/Hamlet-Quiz-Acts-1-2.doc" },
    { uri: "https://spxkc.org/documents/Murph/AP%20English%20III/Hamlet%20HRW%20PPt.ppt" },
    // { uri: require("./Hofstadter-1979 Gödel Escher Bach.pdf") }, // Local File
];

class Lesson extends React.Component {
    constructor(props) {
        super(props);

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-user');
        if (user) username = JSON.parse(String(user)).name;

        // if (!isHRMCManager()) {
        //     window.location.href = "/";
        //     return;
        // }

        this.state = {

            // pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 15,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'lead_created_at_unix', direction: 'desc' }],

            search_agent_name: '',
            search_hub: '',
            search_agent_phone: '',
            search_adr_state: '',
            search_adr_district: '',
            search_adr_ward: '',

            states: [],
            districts: [],
            wards: [],
            hubs: Array.from(new Array(10), (x, i) => i + 1)
        };

        this.loadData();
    }



    queryString() {
        const {
            pageSize,
            currentPage,
            sorting,
        } = this.state;



        let queryString = `${URL}?pagesize=${pageSize}&page=${currentPage + 1}`;

        const columnSorting = sorting[0];
        // if (columnSorting) {
        //     queryString = `${queryString}&orderby=${columnSorting.columnName}`;

        //     if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
        //     else queryString = `${queryString}&asc=true`;
        // }
        return queryString;
    }

    loadData = conditions => {

        const queryString = this.queryString();


        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });

                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });

                    // prevent unauthorized access
                    if (data && (data.code === cs.erAuthenticationFailed.code || data.code === cs.erAccountIsLocked.code)) {

                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {

                this.setState({ loading: false });
            });
    };



    render() {
        const { classes } = this.props;

        // get value from state
        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        return (
            <div>
                <DocViewer
                    documents={docs}
                    pluginRenderers={DocViewerRenderers}

                    // theme={{
                    //     primary: "#5296d8",
                    //     secondary: "#ffffff",
                    //     tertiary: "#5296d899",
                    //     text_primary: "#ffffff",
                    //     text_secondary: "#5296d8",
                    //     text_tertiary: "#00000099",
                    //     disableThemeScrollbar: false,
                    //     height:"1000px"
                    // }}
                    style={{ minHeight: "800px" }}
                />

                <VideoPlayer
                    src={"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"}
                    checkpoints={[5, 10, 15, 20, 25, 30]}
                />
            </div>
        );
    }
}

Lesson.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing(2),
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        },
        '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'rgb(217, 226, 244)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: 200,
        minWidth: 120
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        backgroundColor: green
    }
});




export default withRouter(withStyles(styles)(Lesson));
