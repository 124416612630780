import React from "react";
import { useState } from "react";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import axios from "axios";
import cs from "../../const";
import "../Expert/Expert.css";
function Employee({
  trainerFullname,
  trainerTelephone,
  expertiseDomain,
  title,
  id,
}) {
  const isDark = localStorage.getItem("aidriven-general-theme");
  const [CreateExpert, setCreateExpert] = useState([]);
  const [EditExpert, setEditExpert] = useState([]);
  const onEdit = (e) => {
    setEditExpert({
      ...EditExpert,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  function editExpert() {
    axios({
      method: "post",
      url: `${cs.BaseURL}/api/trainer/update`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        id: EditExpert.id,
        title: EditExpert.title,
        expertise_domain: EditExpert.expertise_domain,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    window.location.reload();
  }

  function deleteExpert(Id) {
    console.log("Id", Id);
    if (window.confirm("Are you sure you wish to delete this item?") === true) {
      axios({
        method: "get",
        url: `${cs.BaseURL}/api/trainer/delete?id=${Id}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log("got Data del Achieve");
        })
        .catch((err) => {
          console.log("miss Data del Achieve");
        });
      window.location.reload();
    }
  }
  return (
    <div className="card-main-expert">
      <div
        className="card-expert"
        style={
          isDark == "true"
            ? { backgroundColor: "#5E5959" }
            : { backgroundColor: "white" }
        }
      >
        <div className="card-body">
          <div className="card-content">
            <div className="row">
              <div className="col-2 column-start d-none d-md-block">
                <img
                  className="expert-avatar"
                  src="https://images.unsplash.com/photo-1552058544-f2b08422138a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                  alt="expert-avatar"
                />
                <div className="column-start-item"> ID: {id}</div>
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Header className="popover-header" as="h3">
                        Description
                      </Popover.Header>
                      <Popover.Body>{title}</Popover.Body>
                    </Popover>
                  }
                >
                  <Button variant="outline-none" id="popovers-icon">
                    <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="col-5 col-md-4 column-content">
                <div className="column-item">
                  <b>Full Name: </b>
                  {trainerFullname}
                </div>
                <div className="column-item">
                  <b>Expert At: </b>
                  {expertiseDomain}
                </div>
              </div>
              <div className="col-5 col-md-4 column-content">
                <div className="column-item">
                  <b>Phone: </b>
                  {trainerTelephone}
                </div>
                <div className="column-item">
                  <b>Email: </b>Vuthanh.uet@gmail.com
                </div>
              </div>
              <div className="col-2 col-md-2 column-edit">
                <button
                  className="btn btn-outline-none icon"
                  onClick={() => deleteExpert(id)}
                >
                  <img
                    className="button-icon"
                    src="https://cdn-icons-png.flaticon.com/512/812/812853.png"
                    alt="delete-icon"
                  />
                </button>
                <button
                  className="btn btn-outline-none icon"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <img
                    className="button-icon"
                    src="https://cdn-icons-png.flaticon.com/512/1160/1160758.png"
                    alt="edit-icon"
                  />
                </button>

                {/* Modal */}

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          EDIT FORM
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="form-field">
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder=" "
                            name="id"
                            onSubmit={(e) => handleSubmit(e)}
                            onChange={(e) => onEdit(e)}
                          />
                          <label for="name" className="form-label">
                            ID
                          </label>
                        </div>
                        <div className="form-field">
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder=" "
                            name="title"
                            onSubmit={(e) => handleSubmit(e)}
                            onChange={(e) => onEdit(e)}
                          />
                          <label for="name" className="form-label">
                            Title
                          </label>
                        </div>
                        <div className="form-field">
                          <input
                            type="text"
                            className="form-control form-input"
                            placeholder=" "
                            name="expertise_domain"
                            onSubmit={(e) => handleSubmit(e)}
                            onChange={(e) => onEdit(e)}
                          />
                          <label for="name" className="form-label">
                            Expertise Domain
                          </label>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => editExpert()}
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Header className="popover-header" as="h3">
                        Description
                      </Popover.Header>
                      <Popover.Body>{title}</Popover.Body>
                    </Popover>
                  }
                >
                  <Button
                    className="d-block d-md-none"
                    variant="outline-none"
                    id="popovers-icon"
                  >
                    <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "20px" }}>
        <img
          className="card-thumnail d-block d-md-none"
          src="https://images.unsplash.com/photo-1552058544-f2b08422138a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
          alt="expert-avatar"
        />
      </div>
    </div>
  );
}

export default Employee;
