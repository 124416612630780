import { Button, CircularProgress, Grid, Icon, List, ListItem, ListItemIcon, ListItemText, Switch, TextField, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';
import cs from '../../const';

class CreateLesson extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullname: '',
            file: null,
            errors: [],
            isProcessing: false,
            isFileValid: false,
            isFileImportProcessed: false,
            isFileImported: false,
        }

        this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
    }

    handleDropZoneChange(files) {
        console.log('add file');
        if (files && files instanceof Array && files.length > 0) {
            console.log(files);
            this.setState({
                file: files[0],
                isProcessing: true,
                isFileValid: false,
                isFileImportProcessed: false,
                isFileImported: false,
                errors: []
            });
            if (files[0].type === 'application/vnd.ms-excel' ||
                files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                console.log(files[0].type);
                this.setState({
                    isProcessing: false,
                    isFileValid: true,
                    errors: []
                });
            } else {
                console.log(files[0].type);
                this.setState({
                    isProcessing: false,
                    isFileValid: false,
                    errors: []
                });
            }
            // var file = files[0];

            // let queryString = `${verifyAgentURL}`;

            // const formData = new FormData();
            // formData.append('excel-file', file);

            // fetch(queryString, {
            //     method: 'POST',
            //     body: formData,
            //     headers: { Authorization: localStorage.getItem(cs.System_Code + '-hr-token') }
            // })
            //     .then(response => response.json())
            //     .then(data => {
            //         if (
            //             data &&
            //             (data.code == cs.erAuthenticationFailed.code ||
            //                 data.code == cs.erAccountIsLocked.code)
            //         ) {
            //             sessionStorage.clear();
            //             window.location.reload();
            //         }

            //         var data = data.data;
            //         var isFileValid = false;
            //         // alert(JSON.stringify(data));
            //         if (
            //             data &&
            //             (!data.errors ||
            //                 (data.errors instanceof Array && data.errors.length <= 0)) &&
            //             data.requested_qty > 0 &&
            //             data.verified_qty > 0 &&
            //             data.requested_qty == data.verified_qty
            //         )
            //             isFileValid = true;

            //         this.setState({
            //             isProcessing: false,
            //             isFileValid: isFileValid,
            //             errors: formatErrors(data.errors)
            //         });
            //     })
            //     .catch(() => {
            //         this.resetState();
            //     });
        } else this.resetState();
    }

    resetState() {
        this.setState({
            file: null,
            errors: [],
            isProcessing: false,
            isFileValid: false,
            isFileImportProcessed: false,
            isFileImported: false
        });
    }

    handleChange = name => event => {
        if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {

                }
            );
    };

    handleDialogAgree = () => {
        console.log(this.state);
        // this.setState({
        //     isProcessing: true,
        //     isFileImportProcessed: false,
        //     isFileImported: false,
        //     errors: []
        // });

        // let queryString = `${ImportLeaderTelesaleURL}`;

        // const formData = new FormData();
        // formData.append('excel-file', this.state.file);
        // formData.append('mc_code', this.state.merchant_code);

        // fetch(queryString, {
        //     method: 'POST',
        //     body: formData,
        //     headers: { Authorization: localStorage.getItem(cs.System_Code + '-admin-token') }
        // })
        //     .then(response => response.json())
        //     .then(data => {
        //         if (
        //             data &&
        //             (data.code == cs.erAuthenticationFailed.code ||
        //                 data.code == cs.erAccountIsLocked.code)
        //          ) {
        //             localStorage.clear();
        //             sessionStorage.clear();
        //             window.location.reload();
        //         }

        //         var data = data.data;
        //         console.log(data);
        //         var isFileImported = false;
        //         if (data && data.requested_qty > 0 && data.failed_qty <= 0) isFileImported = true;

        //         this.setState({
        //             isProcessing: false,
        //             isFileImported: isFileImported,
        //             errors: formatErrors(data.errors)
        //         });
        //         if (isFileImported) {
        //             this.props.toastManager.add('Tạo Leader Telesales thành công !', {
        //                 appearance: 'success',
        //                 autoDismiss: true,
        //                 pauseOnHover: true
        //             });

        //             this.handleClose();
        //         }
        //     })
        //     .catch(() => {
        //         this.resetState();
        //     });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Grid
                    container
                    direction="row"
                    justify="fixed-start"
                    alignItems="stretch"
                    spacing={20}
                    className={classes.downloadContainer}
                    gutterBottom
                >
                    <Grid item xs={7}>
                        <Typography variant="body1">Tải file mẫu :</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <a
                            download="import-agents-template.zip"
                            target="_blank"
                            href={'/'}
                            style={{ fontSize: 13, marginTop: 2 }}
                        >
                            File mẫu
                        </a>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    spacing={4}
                >
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="contact_email"
                            required={true}
                            value={this.state.contact_email}
                            onChange={this.handleChange('contact_email')}
                            label="contact_email (*)"
                            type="code"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            id="contact_person_DOB"
                            label="Ngày sinh (*)"
                            fullWidth
                            value={this.state.contact_person_DOB}
                            //value={"2017-05-24"}
                            onChange={this.handleChange('contact_person_DOB')}
                            type="date"
                            InputProps={{
                                step: "1"
                            }}
                            InputLabelProps={{
                                shrink: true,

                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item>
                    <div>
                        <List>
                            {this.state.errors.map((er, i) => {
                                return (
                                    <ListItem
                                        style={{ paddingTop: 5, paddingBottom: 5 }}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 0, marginRight: 0 }}
                                        >
                                            <Icon
                                                color="error"
                                                style={{
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                    fontSize: 26
                                                }}
                                            >
                                                error_outline
                                            </Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{
                                                primary: { color: 'primary' },
                                                secondary: { color: 'primary' }
                                            }}
                                            secondary={er.error}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                </Grid>

                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="stretch"
                    spacing={1}
                    className={classes.gridContainer}
                >
                    {this.state.isProcessing && (
                        <div style={{ height: 150 }}>
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '45%',
                                    top: '70px'
                                }}
                            />
                        </div>
                    )}
                    {!this.state.isProcessing && (
                        <Grid item gutterBottom>
                            <DropzoneArea
                                // acceptedFiles={[
                                //     'application/vnd.ms-excel',
                                //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                // ]}
                                // acceptedFiles={"image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"}
                                acceptedFiles={[
                                    'image/*', 
                                    'application/pdf',
                                    '.doc','.docx',
                                    ".xls",".xlsx",
                                    ".csv",".tsv",
                                    ".ppt",
                                    ".pptx",".pages",
                                    ".odt",".rtf"
                                ]}
                                dropzoneText={
                                    this.state.file && this.state.file.name
                                        ? this.state.file.name
                                        : 'Upload file exel theo mẫu'
                                }
                                maxFileSize={50000000}
                                filesLimit={1}
                                showAlerts={false}

                                onChange={this.handleDropZoneChange}
                                dropZoneClass={classes.dropZone}

                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                useChipsForPreview
                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                // previewChipProps={{ classes: { root: classes.previewChip } }}
                                previewText="Selected files"
                               
                            />
                        </Grid>
                    )}


                    <Grid item gutterBottom className={classes.statusItem}>
                        {this.state.file &&
                            !this.state.isProcessing &&
                            this.state.isFileValid && (
                                <Typography variant="body1">File hợp lệ !</Typography>
                            )}{' '}
                        {this.state.file &&
                            !this.state.isProcessing &&
                            !this.state.isFileValid && (
                                <Typography variant="body1">File không hợp lệ !</Typography>
                            )}
                        {this.state.file &&
                            !this.state.isProcessing &&
                            this.state.isFileImportProcessed &&
                            this.state.isFileImported && (
                                <Typography variant="body1">
                                    File import thành công !
                                </Typography>
                            )}
                        {this.state.file &&
                            !this.state.isProcessing &&
                            this.state.isFileImportProcessed &&
                            !this.state.isFileImported && (
                                <Typography variant="body1">
                                    File import thất bại !
                                </Typography>
                            )}
                    </Grid>

                </Grid>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" onClick={this.handleDialogAgree}>
                        CREATE
                    </Button>
                </div>

            </div>
        )
    }
}

CreateLesson.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    gridContainer: {
        paddingTop: 10
    },
    downloadContainer: {
        paddingTop: 10
    },
    dropZone: {
        paddingTop: 10,
        minHeight: 200
    },
    erItem: {
        secondary: 'pink'
    },
    statusItem: {
        marginTop: 5
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
})

export default withRouter(withStyles(styles)(CreateLesson));