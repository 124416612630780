import React from 'react';
import PropTypes, { element } from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const';
import moment from 'moment-timezone';
import DialogWrapper from '../shared/DialogWrapper';
import { Chip, Input } from '@material-ui/core';
import axios from 'axios';
// import { DropzoneArea } from 'material-ui-dropzone';

const customizeExamURL = cs.BaseURL + '/api/trainer/test-exam/questions/create';
const questionURL = cs.BaseURL + "/api/trainer/question/list";
const questionLvUrl = cs.BaseURL + "/api/question-level/list";
const examURL = cs.BaseURL + '/api/trainer/test-exam/detail';
const courseURL = cs.BaseURL + '/api/trainer/courses/list';
const detailQuestionUrl = cs.BaseURL + '/api/trainer/test-exam/questions/list?';
const changeQuestionExam = cs.BaseURL + '/api/trainer/test-exam/questions/update';

const hubName = ["Đồng bằng Bắc Bộ", "Đông Bắc Bộ", "Tây Bắc Bộ", "Bắc Trung Bộ", "Nam Trung Bộ", "Tây Nguyên", "Đông Nam Bộ", "Đồng bằng Sông Cửu Long", "Hà Nội", "TP.HCM"];

const styles = theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 5,
  },
});

class Customization extends React.Component {
  constructor(props) {
    super(props);
    let data = this.props.history.location.state ? this.props.history.location.state.data : null;
    this.state = {
      courseList: [],
      levels: [1, 2, 3, 4, 5], // rat de, de, tb, kho, rat kho
      courseId: this.props.match.params.course_id,
      testOrderNumber: this.props.match.params.testOrderNumber,
      testName: '',
      testDate: '',
      percentageOfFinalMark: 0,
      numberOfVeryEasyQuestions: 5,
      numberOfEasyQuestions: 5,
      numberOfAverageQuestions: 5,
      numberOfHardQuestions: 5,
      numberOfVeryHardQuestions: 5,
      totalNumberOfQuestions: 0,
      totalTime: 0,
      examType: '',

      veryEasy: [],
      easy: [],
      average: [],
      hard: [],
      veryHard: [],

      selectedVeryEasy: [],
      selectedEasy: [],
      selectedAverage: [],
      selectedHard: [],
      selectedVeryHard: [],

      detailVeryEasy: [],
      detailEasy: [],
      detailAverage: [],
      detailHard: [],
      detailVeryHard: [],

      changeSelectedQuestion: [],
    };
    // if (!isTelesaleOP()) {
    //     window.location.href="/";
    //     return;
    // }
    // console.log("DATA", this.props.history.location.state);
    this.loadQuestion = this.loadQuestion.bind(this);
    this.loadQuestionLevel = this.loadQuestionLevel.bind(this);
    this.handleDialogAgree = this.handleDialogAgree.bind(this);
    this.getArrayIdQuestion = this.getArrayIdQuestion.bind(this);
    this.handleChangeExam = this.handleChangeExam.bind(this);
    this.handleChangeSelectedQuestion = this.handleChangeSelectedQuestion.bind(this);
    // this.loadCourses();
    this.loadData();
    this.loadDetailExam();
  }

  loadCourses() {
    // console.log(this.state);
    let queryString = `${courseURL}?page=0&size=0`;

    fetch(queryString, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: localStorage.getItem("token")
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.data) {
          // data.data.unshift({ courseName: '--SELECT COURSE--', courseId: null });
          this.setState({
            courseList: data.data
          });
        } else {
          this.setState({
            courseList: []
          });
          if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
            sessionStorage.clear();
            localStorage.clear();
            window.location.reload();
          }
        }
      })
      .catch(() => {
        this.setState({
          types: []
        });
      });
  }

  componentDidMount() {
    this.loadQuestionLevel();
  }

  loadData() {
    // console.log(this.state);
    let queryString = `${examURL}?courseId=${this.state.courseId}&testOrderNumber=${this.state.testOrderNumber}`;

    fetch(queryString, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: localStorage.getItem("token")
      }
    })
      .then(response => response.json())
      .then(data => {

        if (data && data.data) {
          // data.data.unshift({ courseName: '--SELECT COURSE--', courseId: null });
          this.setState({
            courseId: data.data.courseId,
            testOrderNumber: data.data.testOrderNumber,
            testName: data.data.testName,
            testDate: data.data.testDate,
            percentageOfFinalMark: data.data.percentageOfFinalMark,
            numberOfVeryEasyQuestions: data.data.numberOfVeryEasyQuestions,
            numberOfEasyQuestions: data.data.numberOfEasyQuestions,
            numberOfAverageQuestions: data.data.numberOfAverageQuestions,
            numberOfHardQuestions: data.data.numberOfHardQuestions,
            numberOfVeryHardQuestions: data.data.numberOfVeryHardQuestions,
            totalNumberOfQuestions: data.data.totalNumberOfQuestions,
            totalTime: data.data.totalTime,
            examType: data.data.examType,
          });
        } else {
          // this.setState({
          //     courseList: []
          // });
          // if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
          //     sessionStorage.clear();
          //     localStorage.clear();
          //     window.location.reload();
          // }
        }
      })
      .catch(() => {
        this.setState({
          types: []
        });
      });
  }

  getArrayIdQuestion(arrQuestion) {
    let arrIdQuestion = [];
    arrQuestion.forEach(item => arrIdQuestion.push(item.questionId));
    return arrIdQuestion;
  }

  loadDetailExam() {
    let queryString = `${detailQuestionUrl}course_id=${this.state.courseId}&test_order_number=${this.state.testOrderNumber}`
    fetch(queryString, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: localStorage.getItem("token")
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log("detail", data.data);
        if (data && data.data) {
          this.setState({
            selectedVeryEasy: this.getArrayIdQuestion(data.data.veryEasyQuestionList),
            selectedEasy: this.getArrayIdQuestion(data.data.easyQuestionList),
            selectedAverage: this.getArrayIdQuestion(data.data.averageQuestionList),
            selectedHard: this.getArrayIdQuestion(data.data.hardQuestionList),
            selectedVeryHard: this.getArrayIdQuestion(data.data.veryHardQuestionList),
            detailVeryEasy: data.data.veryEasyQuestionList,
            detailEasy: data.data.easyQuestionList,
            detailAverage: data.data.averageQuestionList,
            detailHard: data.data.hardQuestionList,
            detailVeryHard: data.data.veryHardQuestionList,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }

  loadQuestionLevel() {
    // console.log(this.state);
    let queryString = `${questionLvUrl}`;

    fetch(queryString, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: localStorage.getItem("token")
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.data) {
          // console.log(data.data);
          // data.data.unshift({ courseName: '--SELECT COURSE--', courseId: null });
          // this.setState({
          //     levels: data.data
          // });
          let levels = data.data;
          levels.forEach(element => {
            this.loadQuestion(element.difficultyLevelId);
          });

        } else {
          // this.setState({
          //     levels: []
          // });
          // if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
          //     sessionStorage.clear();
          //     localStorage.clear();
          //     window.location.reload();
          // }
        }
      })
      .catch(() => {
        this.setState({
          examTypeList: []
        });
      });
  }

  loadQuestion(level) {
    // console.log(this.state);
    let queryString = `${questionURL}?courseId=${this.state.courseId}&questionDifficultyLevelId=${level}&page=0&size=0`;

    fetch(queryString, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: localStorage.getItem("token")
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.data) {
          let name = '';
          switch (level) {
            case 1:
              name = 'veryEasy';
              break;
            case 2:
              name = 'easy';
              break;
            case 3:
              name = 'average';
              break;
            case 4:
              name = 'hard';
              break;
            case 5:
              name = 'veryHard';
              break;
            default:
              break;
          }
          // data.data.unshift({ courseName: '--SELECT COURSE--', courseId: null });
          this.setState({
            [name]: data.data
          });
        } else {
          // this.setState({
          //     examTypeList: []
          // });
          // if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
          //     sessionStorage.clear();
          //     localStorage.clear();
          //     window.location.reload();
          // }
        }
      })
      .catch(() => {
        this.setState({
          examTypeList: []
        });
      });
  }

  handleDialogAgree = () => {
    console.log(this.state);
    //tmpData = data.data.filter((el) => el.is_deleted_by_merchant === 0);
    if (!this.state.courseId ||
      this.state.testOrderNumber == null
    ) {
      window.alert('Please input information !');
      return;
    }

    const {
      courseId,
      testOrderNumber,
      testName,
      testDate,
      percentageOfFinalMark,
      numberOfVeryEasyQuestions,
      numberOfEasyQuestions,
      numberOfAverageQuestions,
      numberOfHardQuestions,
      numberOfVeryHardQuestions,
      totalNumberOfQuestions,
      totalTime,
      examType,
      selectedVeryEasy,
      selectedEasy,
      selectedAverage,
      selectedHard,
      selectedVeryHard
    } = this.state;

    if (selectedVeryEasy.length != numberOfVeryEasyQuestions) {
      window.alert(`Please select ${numberOfVeryEasyQuestions} very easy ${numberOfVeryEasyQuestions > 1 ? 'questions' : 'question'}!`);
      return;
    }

    if (selectedEasy.length != numberOfEasyQuestions) {
      window.alert(`Please select ${numberOfEasyQuestions} very easy ${numberOfEasyQuestions > 1 ? 'questions' : 'question'}!`);
      return;
    }

    if (selectedAverage.length != numberOfAverageQuestions) {
      window.alert(`Please select ${numberOfAverageQuestions} average ${numberOfAverageQuestions > 1 ? 'questions' : 'question'}!`);
      return;
    }

    if (selectedHard.length != numberOfHardQuestions) {
      window.alert(`Please select ${numberOfHardQuestions} hard ${numberOfHardQuestions > 1 ? 'questions' : 'question'}!`);
      return;
    }

    if (selectedVeryHard.length != numberOfVeryHardQuestions) {
      window.alert(`Please select ${numberOfVeryHardQuestions} very hard ${numberOfVeryHardQuestions > 1 ? 'questions' : 'question'}!`);
      return;
    }

    let queryString = `${customizeExamURL}`;

    let requestBody = {
      courseId: parseInt(courseId),
      testOrderNumber: parseInt(testOrderNumber),
      veryEasyQuestionId: this.state.selectedVeryEasy,
      easyQuestionId: this.state.selectedEasy,
      averageQuestionId: this.state.selectedAverage,
      hardQuestionId: this.state.selectedHard,
      veryHardQuestionId: this.state.selectedVeryHard
    };

    // console.log(requestBody)
    fetch(queryString, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("token")
      }
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        // if (data && (data.code == cs.erAuthenticationFailed.code || data.code == cs.erAccountIsLocked.code)) {
        //     localStorage.clear();
        //     sessionStorage.clear();
        //     window.location.reload();
        // }
        console.log(data.error_code != 0, data.error_desc != "Success");
        if (data && data.error_code != 0 && data.error_desc != "Success") {
          this.props.toastManager.add(JSON.stringify(data.error_desc), {
            appearance: 'error',
            autoDismiss: true,
            pauseOnHover: true
          });
        } else {
          this.props.toastManager.add('Exam is created !', {
            appearance: 'success',
            autoDismiss: true,
            pauseOnHover: true
          });
          this.props.history.push({
            pathname: `/course_exams/${this.state.courseId}`,
            state: { reload: true }
          });
          //                    window.location.href="/package-info;

        }
      })
      .catch(() => { });
  };

  handleChangeSelectedQuestion = () => {
    let changeSelectedQuestion = [];
    this.state.detailVeryEasy.forEach((element, index) => {
      changeSelectedQuestion.push({ testExamQuestionId: element.testExamQuestionId, questionId: this.state.selectedVeryEasy[index] });
    });
    this.state.detailEasy.forEach((element, index) => {
      changeSelectedQuestion.push({ testExamQuestionId: element.testExamQuestionId, questionId: this.state.selectedVeryEasy[index] });
    })
    this.state.detailAverage.forEach((element, index) => {
      changeSelectedQuestion.push({ testExamQuestionId: element.testExamQuestionId, questionId: this.state.selectedVeryEasy[index] });
    })
    this.state.detailHard.forEach((element, index) => {
      changeSelectedQuestion.push({ testExamQuestionId: element.testExamQuestionId, questionId: this.state.selectedVeryEasy[index] });
    })
    this.state.detailVeryHard.forEach((element, index) => {
      changeSelectedQuestion.push({ testExamQuestionId: element.testExamQuestionId, questionId: this.state.selectedVeryEasy[index] });
    })
    return changeSelectedQuestion;
  }

  handleChangeExam = async () => {
    console.log("detailEz", this.state.detailEasy);
    if (this.state.detailEasy.length === 0) {
      this.handleDialogAgree();
      return;
    }

    if (!this.state.courseId ||
      this.state.testOrderNumber == null
    ) {
      window.alert('Please input information !');
      return;
    }

    const {
      courseId,
      testOrderNumber,
      testName,
      testDate,
      percentageOfFinalMark,
      numberOfVeryEasyQuestions,
      numberOfEasyQuestions,
      numberOfAverageQuestions,
      numberOfHardQuestions,
      numberOfVeryHardQuestions,
      totalNumberOfQuestions,
      totalTime,
      examType,
      selectedVeryEasy,
      selectedEasy,
      selectedAverage,
      selectedHard,
      selectedVeryHard
    } = this.state;

    if (selectedVeryEasy.length != numberOfVeryEasyQuestions) {
      window.alert(`Please select ${numberOfVeryEasyQuestions} very easy ${numberOfVeryEasyQuestions > 1 ? 'questions' : 'question'}!`);
      return;
    }

    if (selectedEasy.length != numberOfEasyQuestions) {
      window.alert(`Please select ${numberOfEasyQuestions} very easy ${numberOfEasyQuestions > 1 ? 'questions' : 'question'}!`);
      return;
    }

    if (selectedAverage.length != numberOfAverageQuestions) {
      window.alert(`Please select ${numberOfAverageQuestions} average ${numberOfAverageQuestions > 1 ? 'questions' : 'question'}!`);
      return;
    }

    if (selectedHard.length != numberOfHardQuestions) {
      window.alert(`Please select ${numberOfHardQuestions} hard ${numberOfHardQuestions > 1 ? 'questions' : 'question'}!`);
      return;
    }

    if (selectedVeryHard.length != numberOfVeryHardQuestions) {
      window.alert(`Please select ${numberOfVeryHardQuestions} very hard ${numberOfVeryHardQuestions > 1 ? 'questions' : 'question'}!`);
      return;
    }
    // console.log(this.handleChangeSelectedQuestion());
    try {
      const response = await axios({
        method: "post",
        url: `${changeQuestionExam}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: {
          testExamQuestionList: this.handleChangeSelectedQuestion(),
        }
      });
      if (response.data.error_code != 0 && response.data.error_desc != "Success") {
        this.props.toastManager.add(JSON.stringify(response.data.error_desc), {
          appearance: 'error',
          autoDismiss: true,
          pauseOnHover: true
        });
      } else {
        this.props.toastManager.add('Exam is modified!', {
          appearance: 'success',
          autoDismiss: true,
          pauseOnHover: true
        });
        this.props.history.push({
          pathname: `/course_exams/${this.state.courseId}`,
          state: { reload: true }
        });
        //                    window.location.href="/package-info;
      }

    } catch (error) {
      console.log(error);
    }
  }

  handleChange = name => event => {
    if (name == 'cus_gender') this.setState({ cus_gender: event.target.checked });
    else
      this.setState(
        {
          [name]: event.target.value
        },
        () => {
          // console.log("HERE");

        }
      );
  };

  render() {
    const { classes } = this.props;
    // console.log(this.state);
    return (
      <div>
        <DialogWrapper title={'Exam - Cấu hình'} {...this.props}>
          <DialogContent>
            {/* <FormControl fullWidth>
                            <InputLabel htmlFor="merchantId">Khóa học (*)</InputLabel>
                            <Select
                                value={this.state.courseId}
                                onChange={this.handleChange('courseId')}
                                inputProps={{
                                    name: 'package_type',
                                    id: 'package_type'
                                }}
                            >
                                {this.state.courseList.map(item => (
                                    <MenuItem value={item.courseId}>{item.courseId}-{item.courseName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}



            <TextField
              disabled
              fullWidth
              autoFocus
              margin="dense"
              id="courseId"
              required={true}
              value={this.state.courseId}
              onChange={this.handleChange('courseId')}
              label="courseId"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 128 } }}
            />

            <TextField
              disabled
              fullWidth
              autoFocus
              margin="dense"
              id="testOrderNumber"
              required={true}
              value={this.state.testOrderNumber}
              onChange={this.handleChange('testOrderNumber')}
              label="testOrderNumber"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 128 } }}
            />

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="merchantId">Very easy ({this.state.selectedVeryEasy.length}/{this.state.numberOfVeryEasyQuestions})</InputLabel>
                  <Select
                    value={this.state.selectedVeryEasy}
                    onChange={this.handleChange('selectedVeryEasy')}
                    multiple
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} style={{ margin: 2 }} />
                        ))}
                      </div>
                    )}

                  >
                    {/* <MenuItem value={null} disabled>--SELECT--</MenuItem> */}
                    {this.state.veryEasy.map(item => (
                      <MenuItem value={item.questionId}>{item.questionId}-{item.questionContent}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="merchantId">Easy ({this.state.selectedEasy.length}/{this.state.numberOfEasyQuestions})</InputLabel>
                  <Select
                    value={this.state.selectedEasy}
                    onChange={this.handleChange('selectedEasy')}
                    multiple
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} style={{ margin: 2 }} />
                        ))}
                      </div>
                    )}

                  >
                    {/* <MenuItem value={null} disabled>--SELECT--</MenuItem> */}
                    {this.state.easy.map(item => (
                      <MenuItem value={item.questionId}>{item.questionId}-{item.questionContent}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="merchantId">Average ({this.state.selectedAverage.length}/{this.state.numberOfAverageQuestions})</InputLabel>
                  <Select
                    value={this.state.selectedAverage}
                    onChange={this.handleChange('selectedAverage')}
                    multiple
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} style={{ margin: 2 }} />
                        ))}
                      </div>
                    )}

                  >
                    {/* <MenuItem value={null} disabled>--SELECT--</MenuItem> */}
                    {this.state.average.map(item => (
                      <MenuItem value={item.questionId}>{item.questionId}-{item.questionContent}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="merchantId">Hard ({this.state.selectedHard.length}/{this.state.numberOfHardQuestions})</InputLabel>
                  <Select
                    value={this.state.selectedHard}
                    onChange={this.handleChange('selectedHard')}
                    multiple
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} style={{ margin: 2 }} />
                        ))}
                      </div>
                    )}

                  >
                    {/* <MenuItem value={null} disabled>--SELECT--</MenuItem> */}
                    {this.state.hard.map(item => (
                      <MenuItem value={item.questionId}>{item.questionId}-{item.questionContent}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="merchantId">Very Hard ({this.state.selectedVeryHard.length}/{this.state.numberOfVeryHardQuestions})</InputLabel>
                  <Select
                    value={this.state.selectedVeryHard}
                    onChange={this.handleChange('selectedVeryHard')}
                    multiple
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} style={{ margin: 2 }} />
                        ))}
                      </div>
                    )}

                  >
                    {/* <MenuItem value={null} disabled>--SELECT--</MenuItem> */}
                    {this.state.veryHard.map(item => (
                      <MenuItem value={item.questionId}>{item.questionId}-{item.questionContent}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.props.history.goBack} color="primary">
                            Hủy
                        </Button> */}
            <Button onClick={() => {
              this.props.history.push({
                pathname: `/course_exams/${this.state.courseId}`,
                state: { reload: true }
              });
            }} color="primary">
              Hủy
            </Button>
            <Button onClick={this.handleChangeExam} color="primary">
              Cấu hình
            </Button>
          </DialogActions>
        </DialogWrapper>
      </div>
    );
  }
}

Customization.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(Customization));
