import React, { useState, useEffect } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import { useTranslation, withTranslation } from "react-i18next";
import axios from "axios";
import cs from "../../const";

const getDomainDataUrl = cs.BaseURL + "/api/module/domain-list";
const getCourseDataUrl = cs.BaseURL + "/api/trainer/course/detail?"

const CourseOverview = ({ match, t, i18n }) => {
  const { courseId } = match.params;
  const [courseOverviewData, setCourseOverviewData] = useState({});
  const [domainList, setDomainList] = useState([]);
  const getCourseData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getCourseDataUrl}course_id=${courseId}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      })
      // console.log("coursedata", response.data)
      setCourseOverviewData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getDomainData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getDomainDataUrl}`,
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
      // console.log("data", response.data);
      setDomainList(response.data.data);
      // console.log(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getDomainEngName = (domainId) => {
    let vieName = "";
    domainList.forEach(item => {
      if (item.domainId === domainId) {
        vieName = item.domainEngName;
        return false;
      }
    })
    return vieName;
  }

  useEffect(() => {
    getCourseData();
    getDomainData();
  }, [])

  return <ThemeContext.Consumer>
    {({ isDark }) => <div className="card-body" style={{ minHeight: "50vh" }}>
      <div className="page-title mb-3">{courseOverviewData.courseName}</div>
      <div>{t("expert")}: <span className="text-capitalize">{courseOverviewData.trainerFullname && courseOverviewData.trainerFullname.toLowerCase()}</span></div>
      <div>{t("module.module")}: {courseOverviewData.moduleName}</div>
      <div>{t("domain.domain")}: {t(`domain.name.${getDomainEngName(courseOverviewData.domainId)}`)}</div>
      <div>{t("location")}: {courseOverviewData.location}</div>
      <div className="row">
        <div className="col-sm-6 col-lg-4 col-xl-3">
          {t("from")}: {courseOverviewData.startDate && courseOverviewData.startDate.slice(8, 10) + "-" + courseOverviewData.startDate.slice(5, 7) + "-" + courseOverviewData.startDate.slice(0, 4)}
        </div>
        <div className="col-sm-6 col-lg-4 col-xl-3">
          {t("to")}: {courseOverviewData.endDate && courseOverviewData.endDate.slice(8, 10) + "-" + courseOverviewData.endDate.slice(5, 7) + "-" + courseOverviewData.endDate.slice(0, 4)}
        </div>
      </div>
      <div>{t("course.numberOfRegisteredTrainees")}: {courseOverviewData.numberOfRegisteredTrainees}</div>
      <div>{t("course.numberOfApprovedTrainees")}: {courseOverviewData.numberOfApprovedTrainees}</div>
      <div>{t("course.numberOfCompletedTrainees")}: {courseOverviewData.numberOfCompletedTrainees}</div>
      <div>{t("average-score")}: {courseOverviewData.averageTraineeFinalExamScore}</div>
    </div>}
  </ThemeContext.Consumer>
}

export default withTranslation()(CourseOverview);