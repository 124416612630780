import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Pagin from "../shared/Pagin";
// import Navbar from "../shares/Navbar";
import Expert from "./Expert";
import { useTranslation, withTranslation } from "react-i18next";

import cs from "../../const";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const loadTrainerDataUrl = cs.BaseURL + "/api/trainer/list";
const loadEmployeeDataUrl = cs.BaseURL + "/api/employee/list";
const createTrainerUrl = cs.BaseURL + "/api/trainer/create";
const modifyTrainerUrl = cs.BaseURL + "/api/trainer/update";
const deleteTrainerUrl = cs.BaseURL + "/api/trainer/delete?";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ExpertList({ t, i18n }) {
  //response message
  const [responseMessage, setResponseMessage] = useState({
    type: "",
    content: "",
  });
  const [openMessage, setOpenMessage] = useState(false);
  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setResponseMessage({ type: "", content: "" });
    setOpenMessage(false);
  };
  // List
  const [EmployeeId, setEmployeeId] = useState([]);
  const [Data, setData] = useState([]);

  //paginations
  const [postsPerPage, setpostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = Data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // console.log(currentPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //model
  const [modalForm, setModalForm] = useState({ id: "", title: "", domain: "" });
  const handleCloseModal = () => {
    setModalForm({ id: "", title: "", domain: "" });
    // setOpen(false);
  };
  const onChangeModal = (event) => {
    setModalForm({ ...modalForm, [event.target.name]: event.target.value });
  };

  // hàm click button add expert
  const handleAddClick = async () => {
    if (!modalForm.id || !modalForm.title || !modalForm.domain) {
      setResponseMessage({
        type: "warning",
        content: t("expert-list.response-message.empty-field"),
      });
      setOpenMessage(true);
      return;
    }
    // if (!modalForm.title) {
    //   setResponseMessage({
    //     type: "warning",
    //     content: "Vui lòng nhập tiêu đề!",
    //   });
    //   setOpenMessage(true);
    //   return;
    // }
    // if (!modalForm.domain) {
    //   setResponseMessage({
    //     type: "warning",
    //     content: "Vui lòng nhập chuyên môn!",
    //   });
    //   setOpenMessage(true);
    //   return;
    // }
    try {
      const response = await axios({
        method: "post",
        url: `${createTrainerUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: {
          id: modalForm.id,
          title: modalForm.title,
          expertise_domain: modalForm.domain,
        },
      });
      if (response.data.error_desc === "Success") {
        setResponseMessage({
          type: "success",
          content: t("expert-list.response-message.add-success"),
        });
        setOpenMessage(true);
        loadData();
        handleCloseModal();
      } else {
        setResponseMessage({
          type: "error",
          content: response.data.error_desc,
        });
        setOpenMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // hàm sửa thông tin chuyên gia
  const handleModClick = (data) => {
    setModalForm({
      id: data.id,
      title: data.title,
      domain: data.expertiseDomain,
    });
  };
  // hàm khi click button edit
  const handleConfirmModClick = async () => {
    if (!modalForm.title || !modalForm.domain) {
      setResponseMessage({
        type: "warning",
        content: t("expert-list.response-message.empty-field"),
      });
      setOpenMessage(true);
      return;
    }
    // if (!modalForm.domain) {
    //   setResponseMessage({
    //     type: "warning",
    //     content: "Vui lòng nhập chuyên môn!",
    //   });
    //   setOpenMessage(true);
    //   return;
    // }
    try {
      const response = await axios({
        method: "post",
        url: `${modifyTrainerUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: {
          id: modalForm.id,
          title: modalForm.title,
          expertise_domain: modalForm.domain,
        },
      });
      if (response.data.error_desc === "Success") {
        setResponseMessage({
          type: "success",
          content: t("expert-list.response-message.edit-success"),
        });
        setOpenMessage(true);
        loadData();
        handleCloseModal();
      } else {
        setResponseMessage({
          type: "error",
          content: response.data.error_desc,
        });
        setOpenMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleDelClick(Id) {
    if (
      window.confirm(t("expert-list.response-message.delete-confirm")) === true
    ) {
      axios({
        method: "get",
        url: `${deleteTrainerUrl}id=${Id}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
        .then((res) => {
          if (res.data.error_desc === "Success") {
            setResponseMessage({
              type: "success",
              content: t("expert-list.response-message.delete-success"),
            });
            setOpenMessage(true);
            loadData();
          } else {
            setResponseMessage({ type: "error", content: res.data.error_desc });
            setOpenMessage(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // Read Function
  const loadData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${loadTrainerDataUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log(response.data);
      setData(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const loadEmployee = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${loadEmployeeDataUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log(response.data);
      setEmployeeId(response.data.data);
    } catch (err) {
      console.log("err");
    }
  };

  useEffect(() => {
    loadData();
    loadEmployee();
  }, []);

  return (
    <div className="card card-body shadow-sm">
      <div className="page-title">{t("EXPERT LIST")}</div>
      <div className="container">
        <div>
          <button
            className="btn btn-outline-none component-top-button icon"
            data-bs-toggle="modal"
            data-bs-target="#createModal"
            onClick={handleCloseModal}
          >
            <img
              className="button-icon"
              src="https://cdn-icons-png.flaticon.com/512/3032/3032276.png"
              alt="add-icon"
            />
          </button>
          <div
            className="modal fade"
            id="createModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            onClose={handleCloseModal}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {t("add-expert")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleCloseModal}
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="form-field">
                    <input
                      type="text"
                      className="form-control form-input"
                      placeholder=" "
                      list="browsers"
                      name="id"
                      value={modalForm.id}
                      onChange={(e) => onChangeModal(e)}
                    />
                    <label for="name" className="form-label">
                      {t("expert-list.id-expert")}
                    </label>
                    <datalist id="browsers">
                      {EmployeeId.map((employee) => (
                        <option value={employee.id}>
                          {employee.fullname} - {employee.id}
                        </option>
                      ))}
                    </datalist>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      className="form-control form-input"
                      placeholder=" "
                      name="title"
                      value={modalForm.title}
                      onChange={(e) => onChangeModal(e)}
                    />
                    <label for="name" className="form-label">
                      {t("expert-list.description-expert")}
                    </label>
                  </div>
                  <div className="form-field">
                    <input
                      type="text"
                      className="form-control form-input"
                      placeholder=" "
                      name="domain"
                      value={modalForm.domain}
                      onChange={(e) => onChangeModal(e)}
                    />
                    <label for="name" className="form-label">
                      {t("expert-list.expertise-domain")}
                    </label>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleCloseModal}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClose="true"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleAddClick();
                    }}
                  >
                    {t("add")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Snackbar
            open={openMessage}
            autoHideDuration={1500}
            onClose={handleCloseMessage}
          >
            <Alert onClose={handleCloseMessage} severity={responseMessage.type}>
              {responseMessage.content}
            </Alert>
          </Snackbar>

          {currentPosts.map((item) => (
            <Expert
              trainerFullname={item.trainerFullname}
              trainerTelephone={item.trainerTelephone}
              expertiseDomain={item.expertiseDomain}
              title={item.title}
              id={item.id}
              data={item}
              modalForm={modalForm}
              onChangeModal={onChangeModal}
              handleCloseModal={handleCloseModal}
              handleModClick={handleModClick}
              handleConfirmModClick={handleConfirmModClick}
              handleDelClick={handleDelClick}
              t={t}
              i18n={i18n}
            />
          ))}
        </div>
        <div className="d-flex justify-content-center">
          <Pagin
            ItemsPerPage={postsPerPage}
            paginate={paginate}
            totalItems={Data.length}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ExpertList);
