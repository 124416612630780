import React, { useEffect, useState } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import "./QuestBank.css";
import { makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Snackbar";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import cs from "../../const";
import QuestionModalForm from "./QuestionModalForm";
import Pagin from "../shared/Pagin";
import { useTranslation, withTranslation } from "react-i18next";

const createQuestionUrl = cs.BaseURL + "/api/trainer/question/create";
const updateQuestionUrl = cs.BaseURL + "/api/trainer/question/update";
const deleteQuestionUrl = cs.BaseURL + "/api/trainer/question/delete?id=";
const getQuestionDataUrl = cs.BaseURL + "/api/trainer/question/list";
const getAnswerDataUrl = cs.BaseURL + "/api/answer-name/list";
const getQuestionLvDataUrl = cs.BaseURL + "/api/question-level/list";
const getCourseDataUrl = cs.BaseURL + "/api/trainer/courses/list";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70vw",
    maxHeight: "95vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #888",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const QuestBankControl = ({ t, i18n }) => {
  const classes = useStyles();

  //response message
  const [responseMessage, setResponseMessage] = useState({
    type: "",
    content: "",
  });
  const [openMessage, setOpenMessage] = useState(false);
  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setResponseMessage({ type: "", content: "" });
    setOpenMessage(false);
  };

  //modalForm control
  const [modalForm, setModalForm] = useState({
    question: "",
    courseId: "",
    answerChoice1: "",
    answerChoice2: "",
    answerChoice3: "",
    answerChoice4: "",
    correctAnswer: "",
    lvOfDifficult: "",
  });
  const [open, setOpen] = useState(false);
  const [reqType, setReqType] = useState("");
  const [questionId, setQuestionId] = useState();
  const [openDel, setOpenDel] = useState(false);
  const handleOpenModal = (reqType) => {
    setReqType(reqType);
    setOpen(true);
  };
  const handleCloseModal = () => {
    setModalForm({
      question: "",
      courseId: "",
      answerChoice1: "",
      answerChoice2: "",
      answerChoice3: "",
      answerChoice4: "",
      correctAnswer: "",
      lvOfDifficult: "",
    });
    setOpen(false);
  };
  const onChangeModal = (event) => {
    setModalForm({ ...modalForm, [event.target.name]: event.target.value });
  };

  const handleOpenDelModal = () => {
    setOpenDel(true);
  };
  const handleCloseDelModal = () => {
    setOpenDel(false);
  };

  const handleConfirmData = () => {
    console.log(modalForm);
  };

  const handleAddClick = () => {
    handleOpenModal("add");
  };

  const handleConfirmAddClick = async () => {
    if (
      !modalForm.courseId ||
      !modalForm.question ||
      !modalForm.lvOfDifficult ||
      !modalForm.answerChoice1 ||
      !modalForm.answerChoice2 ||
      !modalForm.answerChoice3 ||
      !modalForm.answerChoice4 ||
      !modalForm.correctAnswer
    ) {
      setResponseMessage({
        type: "warning",
        content: t("question-bank.response-message.empty-fields"),
      });
      setOpenMessage(true);
      return;
    }
    handleConfirmData();
    try {
      const response = await axios({
        method: "post",
        url: `${createQuestionUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: {
          courseId: modalForm.courseId,
          questionContent: modalForm.question,
          choiceA: modalForm.answerChoice1,
          choiceB: modalForm.answerChoice2,
          choiceC: modalForm.answerChoice3,
          choiceD: modalForm.answerChoice4,
          correctAnswer: modalForm.correctAnswer,
          difficultyLevel: modalForm.lvOfDifficult,
        },
      });
      if (response.data.error_desc === "Success") {
        setResponseMessage({
          type: "success",
          content: t("question-bank.response-message.add-success"),
        });
        setOpenMessage(true);
        getQuestionData();
        handleCloseModal();
      } else {
        setResponseMessage({
          type: "error",
          content: response.data.error_desc,
        });
        setOpenMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleModClick = (item) => {
    console.log(item);
    setModalForm({
      question: item.questionContent,
      courseId: "",
      answerChoice1: item.choiceA,
      answerChoice2: item.choiceB,
      answerChoice3: item.choiceC,
      answerChoice4: item.choiceD,
      correctAnswer: item.correctAnswerName,
      lvOfDifficult: item.difficultyLevelId,
    });
    setQuestionId(item.id);
    handleOpenModal("modify");
  };

  const handleConfirmModClick = async () => {
    if (
      !modalForm.courseId ||
      !modalForm.question ||
      !modalForm.lvOfDifficult ||
      !modalForm.answerChoice1 ||
      !modalForm.answerChoice2 ||
      !modalForm.answerChoice3 ||
      !modalForm.answerChoice4 ||
      !modalForm.correctAnswer
    ) {
      setResponseMessage({
        type: "warning",
        content: t("question-bank.response-message.empty-fields"),
      });
      setOpenMessage(true);
      return;
    }
    // handleConfirmData();
    try {
      const response = await axios({
        method: "post",
        url: `${updateQuestionUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: {
          id: questionId,
          questionContent: modalForm.question,
          choiceA: modalForm.answerChoice1,
          choiceB: modalForm.answerChoice2,
          choiceC: modalForm.answerChoice3,
          choiceD: modalForm.answerChoice4,
          correctAnswer: modalForm.correctAnswer,
          difficultyLevel: modalForm.lvOfDifficult,
        },
      });
      if (response.data.error_desc === "Success") {
        setResponseMessage({
          type: "success",
          content: t("question-bank.response-message.modify-success"),
        });
        setOpenMessage(true);
        getQuestionData();
        handleCloseModal();
      } else {
        setResponseMessage({
          type: "error",
          content: response.data.error_desc,
        });
        setOpenMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelClick = (item) => {
    console.log(item);
    setQuestionId(item.id);
    console.log(questionId);
    handleOpenDelModal();
  };

  const handleConfirmDelClick = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${deleteQuestionUrl}${questionId}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response.data.error_desc === "Success") {
        setResponseMessage({
          type: "success",
          content: t("question-bank.response-message.delete-success"),
        });
        setOpenMessage(true);
        getQuestionData();
        handleCloseDelModal();
      } else {
        setResponseMessage({
          type: "error",
          content: response.data.error_desc,
        });
        console.log(response.data.error_desc);
        setOpenMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //load data
  const [questionList, setQuestionList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [questionLvList, setQuestionLvList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [displayQuestionCtrl, setDisplayQuestionCtrl] = useState({
    courseId: 0,
    size: 10,
    questionDifficultyLevelId: 0,
  });
  const onChangeDisplayQuestionCtrl = (event) => {
    setDisplayQuestionCtrl({
      ...displayQuestionCtrl,
      [event.target.name]: event.target.value,
    });
  };
  //pagination
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    console.log(currentPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getQuestionData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getQuestionDataUrl}?courseId=${displayQuestionCtrl.courseId}&questionDifficultyLevelId=${displayQuestionCtrl.questionDifficultyLevelId}&page=${currentPage}&size=${displayQuestionCtrl.size}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("questData", response.data);
      setQuestionList(response.data.data);
      setTotalCount(response.data.total_count);
    } catch (error) {
      console.log(error);
    }
  };

  const getAnswerData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getAnswerDataUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log("answData", response.data);
      setAnswerList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getQuestionLvData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getQuestionLvDataUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("questLv", response.data);
      setQuestionLvList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getQuestionLvEngName = (lvId) => {
    let name = "";
    questionLvList.forEach((item) => {
      if (item.difficultyLevelId == lvId) {
        name = item.difficutlyLevelEngName;
        return;
      }
    });
    return name;
  };

  const getCourseData = async () => {
    try {
      let totalCount = "";
      const getCourseTotalCount = await axios({
        method: "get",
        url: `${getCourseDataUrl}?page=1&size=10`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      totalCount = getCourseTotalCount.data.total_count;
      // console.log("totalCount", totalCount)
      const response = await axios({
        method: "get",
        url: `${getCourseDataUrl}?page=1&size=${totalCount}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("courseData", response.data);
      setCourseList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuestionData();
    getAnswerData();
    getQuestionLvData();
    getCourseData();
  }, [displayQuestionCtrl, currentPage]);
  return (
    <ThemeContext.Consumer>
      {({ isDark }) => {
        return (
          <div className={isDark ? "card dark-background-1" : "card"}>
            <div className="card-body shadow">
              <div className="d-flex my-2 align-items-baseline">
                <div className="page-title mb-3">
                  {t("question-bank.question-bank")}
                </div>
                <select
                  className="ms-auto"
                  name="courseId"
                  value={displayQuestionCtrl.courseId}
                  onChange={onChangeDisplayQuestionCtrl}
                  style={{ width: "130px" }}
                >
                  <option value="0">{t("course.courses")}</option>
                  {courseList.map((item) => (
                    <option value={item.courseId}>
                      {item.courseId + " - " + item.courseName}
                    </option>
                  ))}
                </select>
                <select
                  className="ms-1"
                  name="questionDifficultyLevelId"
                  value={displayQuestionCtrl.questionDifficultyLevelId}
                  onChange={onChangeDisplayQuestionCtrl}
                  style={{ width: "130px" }}
                >
                  <option value="0">
                    {t("question-bank.level-of-difficult")}
                  </option>
                  {questionLvList.map((item) => (
                    <option value={item.difficultyLevelId}>
                      {t(`question-bank.${item.difficutlyLevelEngName}`)}
                    </option>
                  ))}
                </select>
              </div>
              <table
                className={
                  isDark
                    ? "table table-sm table-striped table-hover table-dark"
                    : "table table-sm table-striped table-hover"
                }
              >
                <thead
                  style={{
                    backgroundColor: "#F69756",
                    fontSize: "15px",
                    color: "black",
                  }}
                >
                  <tr>
                    <th className="bg-primary text-white px-2" scope="col">
                      {t("index")}
                    </th>
                    <th className="bg-primary text-white col-4" scope="col">
                      {t("question-bank.question")}
                    </th>
                    <th className="bg-primary text-white col-6" scope="col">
                      {t("question-bank.answer")}
                    </th>
                    <th className="bg-primary text-white col-1" scope="col">
                      {t("question-bank.correct-answer")}
                    </th>
                    <th className="bg-primary text-white col-1" scope="col">
                      {t("question-bank.level-of-difficult")}
                    </th>
                    <th className="bg-primary text-white" scope="col">
                      <button className="btn btn-sm" onClick={handleAddClick}>
                        <img src="https://img.icons8.com/material-outlined/20/ffffff/add-property.png" />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {questionList.map((item) => (
                    <tr key={item}>
                      <td className="text-center">
                        {questionList.indexOf(item) + 1}
                      </td>
                      <td>{item.questionContent}</td>
                      <td>
                        <div>A. {item.choiceA}</div>
                        <div>B. {item.choiceB}</div>
                        <div>C. {item.choiceC}</div>
                        <div>D. {item.choiceD}</div>
                      </td>
                      <td>{item.correctAnswerName}</td>
                      <td>{getQuestionLvEngName(item.difficultyLevelId)}</td>
                      <td>
                        <div>
                          <button
                            className="btn btn-sm"
                            onClick={() => handleModClick(item)}
                          >
                            {isDark ? (
                              <img src="https://img.icons8.com/fluency-systems-filled/20/ffffff/edit-property.png" />
                            ) : (
                              <img src="https://img.icons8.com/fluency-systems-filled/20/000000/edit-property.png" />
                            )}
                          </button>
                          <button
                            className="btn btn-sm"
                            onClick={() => handleDelClick(item)}
                          >
                            {isDark ? (
                              <img src="https://img.icons8.com/material-outlined/20/ffffff/delete-property.png" />
                            ) : (
                              <img src="https://img.icons8.com/material-outlined/20/000000/delete-property.png" />
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-center">
                <Pagin
                  ItemsPerPage={displayQuestionCtrl.size}
                  paginate={paginate}
                  totalItems={totalCount}
                  currentPage={currentPage}
                />
              </div>
            </div>

            <Modal
              style={{ top: 0 }}
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <QuestionModalForm
                reqType={reqType}
                modalForm={modalForm}
                questionLvList={questionLvList}
                answerList={answerList}
                courseList={courseList}
                onChangeModal={onChangeModal}
                handleConfirmAddClick={handleConfirmAddClick}
                handleConfirmModClick={handleConfirmModClick}
                handleCloseModal={handleCloseModal}
              />
            </Modal>

            <Modal
              style={{ top: 0 }}
              open={openDel}
              onClose={handleCloseDelModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.paper}>
                <div className="container-fluid p-0">
                  <p className="mt-3 mb-4">
                    {t(
                      "question-bank.response-message.Are you sure you want to delete"
                    )}
                  </p>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary me-1"
                      style={{ width: "60px" }}
                      onClick={() => handleConfirmDelClick()}
                    >
                      {t("delete")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      style={{ width: "60px" }}
                      onClick={handleCloseDelModal}
                    >
                      {t("cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>

            <Snackbar
              open={openMessage}
              autoHideDuration={1500}
              onClose={handleCloseMessage}
            >
              <Alert
                onClose={handleCloseMessage}
                severity={responseMessage.type}
              >
                {responseMessage.content}
              </Alert>
            </Snackbar>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default withTranslation()(QuestBankControl);
