import React, { useState } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import CourseDetail from "./CourseDetail";
import CourseOverview from "./CourseOverview";
import { useTranslation, withTranslation } from "react-i18next";
import "./Courses.css";

const CourseStatistics = (props) => {
  const [tab, setTab] = useState("overview");
  const { t, i18n } = props;
  return <ThemeContext.Consumer>
    {({ isDark }) =>
      <div className={isDark ? "card dark-background-1" : "card"}>
        <div className="card-header">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className={tab === "overview" ? "nav-link active" : "nav-link"}
                onClick={() => setTab("overview")}
              >
                {t("overview")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={tab === "detail" ? "nav-link active" : "nav-link"}
                onClick={() => setTab("detail")}
              >
                {t("students-information")}
              </a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          {tab === "overview" && <CourseOverview {...props} />}
          {tab === "detail" && <CourseDetail {...props} />}
        </div>
      </div>
    }
  </ThemeContext.Consumer>
}

export default withTranslation()(CourseStatistics);