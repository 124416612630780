import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from '../../const';

import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import moment from 'moment-timezone';

import React from 'react';
import { Link, withRouter, Route } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Grid, Table, VirtualTable, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { blue, green } from '@material-ui/core/colors';

import Icon from '@material-ui/core/Icon';

import { ToastProvider } from 'react-toast-notifications';
// import CreateUser from './CreateUser.js';
// import EditUser from './EditUser.js';
// import DeleteUser from './DeleteUser.js';
import DataTable from '../shared/DataTable.jsx';
import CreateLesson from '../LessonList/CreateLesson';
import CreateLecture from './CreateLecture';
import EditLecture from './EditLecture';
import DeleteLecture from './DeleteLecture';

const URL = cs.BaseURL + '/api/trainer/courses/lecture/list';

function formatValue(value) {
    // format display of time
    if (value && value.lectureDateStr) {
        value.lectureDateStr = moment(value.lectureDateStr)
            .tz('Asia/Ho_Chi_Minh')
            .format('DD/MM/YYYY');
    }


    // format short forms
    if (value && value.current_province) value.current_province = value.current_province.replace('Thành phố', 'TP').replace('Tỉnh', '');

    if (value && value.user_role) {
        switch (value.user_role) {
            case cs.Role_Telesale_Merchant_User:
                value.user_role = "MERCHANT USER"
                break;
            case cs.Role_Telesale_Merchant_Manager:
                value.user_role = "MERCHANT MANAGER"
                break;
            case cs.Role_Telesale_Merchant_Leader:
                value.user_role = "MERCHANT LEADER";
                break;
            default:
                break;
        }
    }

    if (value && value.hub) {
        switch (value.hub) {
            case 1:
                value.hub = "Đồng bằng Bắc Bộ (1)";
                break;
            case 2:
                value.hub = "Đông Bắc Bộ (2)";
                break;
            case 3:
                value.hub = "Tây Bắc Bộ (3)";
                break;
            case 4:
                value.hub = "Bắc Trung Bộ (4)";
                break;
            case 5:
                value.hub = "Nam Trung Bộ (5)";
                break;
            case 6:
                value.hub = "Tây Nguyên (6)";
                break;
            case 7:
                value.hub = "Đông Nam Bộ (7)";
                break;
            case 8:
                value.hub = "Đồng bằng Sông Cửu Long (8)";
                break;
            case 9:
                value.hub = "Hà Nội (9)";
                break;
            case 10:
                value.hub = "TP.HCM (10)";
            default:
                break;
        }
    }

    return value;
}

const isHRMCManager = () => {
    var user = localStorage.getItem(cs.System_Code + '-user');
    if (!user) return false;
    var userrole = JSON.parse(String(user)).role;
    return (
        // userrole === cs.Role_Telesale_Hub_User ||
        userrole === cs.Role_HR_Mc_Manager
    );
};

class LectureList extends React.Component {
    constructor(props) {
        super(props);

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-user');
        if (user) username = JSON.parse(String(user)).name;

        // if (!isHRMCManager()) {
        //     window.location.href = "/";
        //     return;
        // }

        this.state = {
            course_id: this.props.match.params.course_id,
            columns: [
                { name: "lectureOrderNumber", title: '#' },
                { name: 'lectureUid', title: 'lectureUid' },
                { name: 'lectureTitle', title: 'lectureTitle' },
                { name: 'courseName', title: 'Khóa học' },
                { name: 'moduleName', title: 'Module' },
                { name: 'lectureDateStr', title: 'lectureDate' },
                { name: 'trainerFullname', title: 'Trainer' },
                { name: 'action', title: 'Thao tác' }
            ],
            rows: [],
            // rows:[
            //     {"lectureOrderNumber":127,"courseId":6}
            // ],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'lectureOrderNumber', align: 'center', width: 80 },
                { columnName: 'lectureUid', align: 'center', width: 140 },
                { columnName: 'lectureTitle', align: 'center', width: 140 },
                { columnName: 'courseName', align: 'center', width: 160 },
                { columnName: 'moduleName', align: 'center', width: 180 },
                { columnName: 'lectureDateStr', align: 'center', width: 140 },
                { columnName: 'trainerFullname', align: 'center', width: 200 },
                { columnName: 'action', align: 'center', width: 260 }

            ],
            // pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 15,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'lead_created_at_unix', direction: 'desc' }],

            search_agent_name: '',
            search_hub: '',
            search_agent_phone: '',
            search_adr_state: '',
            search_adr_district: '',
            search_adr_ward: '',

            states: [],
            districts: [],
            wards: [],
            hubs: Array.from(new Array(10), (x, i) => i + 1)
        };
        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);

        this.loadData();
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    queryString() {
        const {
            pageSize,
            currentPage,
            sorting,
            course_id
        } = this.state;



        let queryString = `${URL}?pagesize=${pageSize}&page=${currentPage + 1}&courseId=${course_id}`;

        const columnSorting = sorting[0];
        // if (columnSorting) {
        //     queryString = `${queryString}&orderby=${columnSorting.columnName}`;

        //     if (columnSorting.direction === 'desc') queryString = `${queryString}&asc=false`;
        //     else queryString = `${queryString}&asc=true`;
        // }
        return queryString;
    }

    loadData = conditions => {

        const queryString = this.queryString();


        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }

                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });

                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });

                    // prevent unauthorized access
                    if (data && (data.code === cs.erAuthenticationFailed.code || data.code === cs.erAccountIsLocked.code)) {

                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {

                this.setState({ loading: false });
            });
    };

    handleChange = name => event => {
        this.setState(
            {
                [name]: event.target.value
            },
            () => {
                this.setState({ currentPage: 0 });
                this.loadData();
            }
        );
    };

    render() {
        const { classes } = this.props;
        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.reload
        ) {
            this.props.history.location.state.reload = false;
            this.loadData();
        }

        // get value from state
        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting,
            course_id
        } = this.state;

        return (
            <div>
                <Typography>Course ID: {course_id}</Typography>
                {/* <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Tìm kiếm ...</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <form className={classes.search_container} noValidate autoComplete="off">

                            <TextField
                                id="Telesale_agent"
                                label="Họ & Tên"
                                type="search"
                                className={classes.search_field}
                                value={this.state.search_agent_name}
                                onChange={this.handleChange('search_agent_name')}
                                margin="normal"
                            />

                            <TextField
                                id="product_code"
                                label="Phone"
                                type="search"
                                className={classes.search_field}
                                value={this.state.search_agent_phone}
                                onChange={this.handleChange('search_agent_phone')}
                                margin="normal"
                            />

                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="adr_ward_id">Hub</InputLabel>
                                <Select
                                    value={this.state.search_hub}
                                    onChange={this.handleChange('search_hub')}
                                    inputProps={{
                                        name: 'adr_ward_code',
                                        id: 'adr_ward_id'
                                    }}
                                >
                                    <MenuItem value="">

                                    </MenuItem>
                                    {this.state.hubs.map(item => (
                                        <MenuItem value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="cus_adr_state_id">
                                    Tỉnh/thành phố (*)
                                </InputLabel>
                                <Select
                                    value={this.state.search_adr_state}
                                    onChange={this.handleChange('search_adr_state')}
                                    inputProps={{
                                        name: 'adr_state_code',
                                        id: 'adr_state_id'
                                    }}
                                >
                                    {this.state.states.map(pro => (
                                        <MenuItem value={pro.state_code}>
                                            {pro.state_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="adr_district_id">
                                    Quận / huyện (*)
                                </InputLabel>
                                <Select
                                    value={this.state.search_adr_district}
                                    onChange={this.handleChange('search_adr_district')}
                                    inputProps={{
                                        name: 'adr_district_code',
                                        id: 'adr_district_id'
                                    }}
                                >
                                    {this.state.districts.map(pro => (
                                        <MenuItem value={pro.district_code}>
                                            {pro.district_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl className={classes.search_field}>
                                <InputLabel htmlFor="adr_ward_id">Xã/Phường</InputLabel>
                                <Select
                                    value={this.state.search_adr_ward}
                                    onChange={this.handleChange('search_adr_ward')}
                                    inputProps={{
                                        name: 'adr_ward_code',
                                        id: 'adr_ward_id'
                                    }}
                                >
                                    {this.state.wards.map(pro => (
                                        <MenuItem value={pro.ward_code}>
                                            {pro.ward_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </form>
                    </ExpansionPanelDetails>
                </ExpansionPanel> */}

                {' '}
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Refresh"
                    onClick={this.loadData}
                >
                    <Icon>refresh</Icon>
                    <span style={{ marginLeft: 10 }}>Làm mới</span>
                </Button>
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Create New User"
                    component={Link}
                    to={`/course_lectures/${this.state.course_id}/create`}
                >
                    {/* <Icon>person_add</Icon> */}
                    <span style={{ marginLeft: 10 }}> Thêm bài giảng mới </span>
                </Button>

                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Create New User"
                    component={Link}
                    to={`/my-courses`}
                >
                    <Icon>arrow_back</Icon>
                    <span style={{ marginLeft: 10 }}>Return to Course list</span>
                </Button>

                <DataTable
                    rows={rows}
                    columns={columns}
                    columnWidths={tableColumnExtensions}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    loading={loading}
                    sorting={sorting}
                    totalCount={totalCount}
                    changeCurrentPage={this.changeCurrentPage}
                    changePageSize={this.changePageSize}
                    changeSorting={this.changeSorting}
                    CellComponent={CellComponent}
                    autoLoadData={this.loadData}
                />
                <Route exact path="/course_lectures/:course_id/create" component={CreateLecture} />
                <Route exact path="/course_lectures/:course_id/edit/:lectureOrderNumber" component={EditLecture} />
                <Route exact path="/course_lectures/:course_id/delete/:lectureOrderNumber" component={DeleteLecture} />
                {/* <Route exact path="/user/create" component={CreateLesson} />
                <Route exact path="/course_lectures/:course_id/edit/:id" component={EditUser} />
                <Route exact path="/user/delete/:id" component={DeleteUser} /> */}
            </div>
        );
    }
}

LectureList.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing(2),
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        },
        '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'rgb(217, 226, 244)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: 200,
        minWidth: 120
    },
    search_select_menu: {
        width: 200
    },
    grid_header: {
        backgroundColor: green
    }
});

class ActionCell extends React.Component {
    render() {
        return (
            <Table.Cell style={{
                padding: 1,
                justifyContent: 'center',
                fontSize: '12px'
            }}>
                <span>
                    {/* <Button
                        color="primary"
                        component={Link}
                        to={{ pathname: '/detail/' + this.props.row.order_code, state: { previous: '/waiting' } }}
                    >
                        Chi tiết
                    </Button> */}


                    <Button
                        color="primary"
                        component={Link}
                        to={{
                            pathname: `/course_materials/${this.props.row.courseId}/${this.props.row.lectureOrderNumber}`,
                            state: { previous: `/course_lectures/${this.props.row.courseId}` }
                        }}
                    >
                        Materials
                    </Button>

                    <Button
                        color="primary"
                        style={{
                            margin: 0,
                            padding: 0
                        }}
                        component={Link}
                        // to={`/course_lectures/${this.props.row.courseId}/edit/${this.props.row.lectureOrderNumber}`}
                        to={{
                            pathname: `/course_lectures/${this.props.row.courseId}/edit/${this.props.row.lectureOrderNumber}`,
                            state: { previous: `/course_lectures/${this.props.row.courseId}` }
                        }}
                    >
                        <Icon>edit</Icon>
                    </Button>



                    <Button
                        color="secondary"
                        style={{
                            margin: 0,
                            padding: 0
                        }}
                        component={Link}
                        // to={`/course_lectures/${this.props.row.courseId}/delete/${this.props.row.lectureOrderNumber}`}
                        to={{
                            pathname: `/course_lectures/${this.props.row.courseId}/delete/${this.props.row.lectureOrderNumber}`,
                            state: { previous: `/course_lectures/${this.props.row.courseId}` }
                        }}
                    >
                        <Icon>remove_circle</Icon>
                    </Button>

                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = props => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }

    return (
        <Table.Cell
            {...props}
            style={{
                padding: 2,
                color: '#81557a',
                fontSize: '12px'
            }}
        />
    );
};

export default withRouter(withStyles(styles)(LectureList));
