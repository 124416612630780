import React, { useState, useEffect } from "react";
import axios from "axios";
import cs from "../../const";
// import Navbar from "../shares/Navbar";
import Course from "./Course";
import CoursesList from "../CoursesList/CoursesList";
function CourseList() {
  const getCoursesListUrl = cs.BaseURL + "/api/course/list";
  const [coursesList, setCoursesList] = useState([]);
  const getCoursesData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${getCoursesListUrl}`,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("course", response.data);
      setCoursesList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCoursesData();
  }, []);
  return (
    <div>
      <div className=" mb-3 component-top-title">
        <h3>COURSE LIST</h3>{" "}
      </div>
      <div class="container">
        <div class="row">
          {coursesList.map((data) => (
            <Course data={data} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CourseList;
